import React, { useState,useRef,useEffect } from 'react';
import { Form, Input, Button,Select,Row,Col,DatePicker ,Switch,Spin} from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { message } from 'antd';
import moment from 'moment';

const { Option } = Select;


const App = () => {
  const [data, setData] = useState([
    
  ]);

  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);
  const [loading, setLoading] = useState(true);

  const searchInput = useRef(null);

  useEffect(() => {
    // Fetch data from Supabase on component mount
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      const { data: { user } } = await supabase.auth.getUser();

    const userId = user.id;
      // Fetch data from "cheque_payment" table where status is "pending"
      const { data: paymentData, error: paymentError } = await supabase
        .from('cheque_payment')
        .select('*')
        .eq('select_status', 'pending').eq('user_id',userId);

      // Fetch data from "cheque_receipt" table where status is "pending"
      const { data: receiptData, error: receiptError } = await supabase
        .from('cheque_receipt')
        .select('*')
        .eq('select_status', 'pending').eq('user_id',userId);

      if (paymentError || receiptError) {
        // Handle errors
        console.error(paymentError || receiptError);
        message.error('Error fetching data from Supabase');
        return;
      }

      // Combine data from both tables
      const combinedData = [...paymentData, ...receiptData];

      // Update the state with the filtered data
      setData(combinedData);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }

    setLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (pendingFilters) => {
    pendingFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      pendingFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              pendingFilters && handleReset(pendingFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...editedRecord } : record
    );
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    setData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>PDCS: </label>
          <Input
            value={editedRecord?.pdcs}
            onChange={(e) => handleEditInputChange('pdcs', e.target.value)}
          />
        </div>
        <div>
          <label>Voucher: </label>
          <Input
            value={editedRecord?.voucher}
            onChange={(e) => handleEditInputChange('voucher', e.target.value)}
          />
        </div>
        <div>
          <label>Type: </label>
          <Input
            value={editedRecord?.type}
            onChange={(e) => handleEditInputChange('type', e.target.value)}
          />
        </div>
        <div>
          <label>Cheque: </label>
          <Input
            value={editedRecord?.cheque}
            onChange={(e) => handleEditInputChange('cheque', e.target.value)}
          />
        </div>
        <div>
          <label>Date: </label>
          <Input
            value={editedRecord?.date}
            onChange={(e) => handleEditInputChange('date', e.target.value)}
          />
        </div>
        <div>
          <label>cheque: </label>
          <Input
            value={editedRecord?.cheque_}
            onChange={(e) => handleEditInputChange('cheque_', e.target.value)}
          />
        </div>
        <div>
          <label>Amount: </label>
          <Input
            value={editedRecord?.amount}
            onChange={(e) => handleEditInputChange('amount', e.target.value)}
          />
        </div>
        <div>
          <label>PDC Account: </label>
          <Input
            value={editedRecord?.pdcaccount}
            onChange={(e) => handleEditInputChange('pdcaccount', e.target.value)}
          />
        </div>
        <div>
          <label>Bank Account: </label>
          <Input
            value={editedRecord?.bankaccount}
            onChange={(e) => handleEditInputChange('bankaccount', e.target.value)}
          />
        </div>
        <div>
          <label>Supply: </label>
          <Input
            value={editedRecord?.supply}
            onChange={(e) => handleEditInputChange('supply', e.target.value)}
          />
        </div>
        <div>
          <label>Stats: </label>
          <Input
            value={editedRecord?.stats}
            onChange={(e) => handleEditInputChange('stats', e.target.value)}
          />
        </div>
        
      </div>
    );
  };

  const columns = [
    {
    title: 'Acount Code',
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      ...getColumnSearchProps('id'),
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '20%',
      },
      {
        title: 'PDC receiveable account',
        dataIndex: 'pdcreceiveableaccount',
        key: 'pdcreceiveableaccount',
        width: '20%',
      },
      {
        title: 'Account',
        dataIndex: 'Account',
        key: 'Account',
        width: '20%',
      },
      {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        width: '20%',
      },
      {
        title: 'Cheque No',
        dataIndex: 'chequenum',
        key: 'chequenum',
        width: '20%',
      },
      {
        title: 'Maturity Date',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        width: '20%',
      },
    
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: '30%',
      ...getColumnSearchProps('amount'),
    },

    {
        title: 'Naration',
        dataIndex: 'naration',
        key: 'naration',
        width: '30%',
        ...getColumnSearchProps('naration'),
      },
      {
        title: 'Master 1',
        dataIndex: 'master1',
        key: 'master1',
        width: '20%',
      },
      {
        title: 'Master 2',
        dataIndex: 'master2',
        key: 'master2',
        width: '20%',
      },
      {
        title: 'Select tatus',
        dataIndex: 'select_status',
        key: 'select_status',
        width: '20%',
      },
      

 
   
  
  ];
  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  const handleFilter = () => {
    // Filter records based on the date range
    const filteredData = data.filter((record) => {
      const recordDate = moment(record['date'], 'DD-MM-YY'); // Convert the date string to a moment object
      return (
        (!startDate || recordDate.isSameOrAfter(startDate, 'day')) &&
        (!endDate || recordDate.isSameOrBefore(endDate, 'day'))
      );
    });
  
    // Update the state with filtered data
    setData(filteredData);
  };
  
  

  return (
    <Spin spinning={loading}>
    <>

{/* <div style={{ backgroundColor: 'yellow', padding: '10px', textAlign: 'center' }}>
      <strong>Under Construction:</strong> This page is currently under construction.
    </div> */}
    
    <Form form={form}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Form.Item
  name="deliveryDate"
  style={{ width: '30%' }}
  rules={[
    {
      required: true,
      message: 'Please fill the field',
    },
  ]}
>
  <DatePicker
    placeholder="From Date"
    onChange={(date) => setStartDate(date)}
  />
</Form.Item>
<Form.Item
  name="returnDate"
  style={{ width: '30%'}}
  rules={[
    {
      required: true,
      message: 'Please fill the field',
    },
  ]}
>
  <DatePicker
    placeholder="To Date"
    onChange={(date) => setEndDate(date)}
  />
</Form.Item>
<Form.Item>
        <Button type="primary" onClick={handleFilter} style={{marginRight:'600px'}}>
        Filter
      </Button>
      </Form.Item>
      </div>
        </Form>
       
        <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <br></br>
        <br></br>
    <div style={{ overflowX: 'auto' }}>
      <Table id='table-to-export' columns={columns} dataSource={data} />
      <Modal
        title={`Delete Record - ${recordToDelete ? recordToDelete.pdcs : ''}`}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={`Edit Record - ${recordToEdit ? recordToEdit.pdcs : ''}`}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
        {renderEditForm()}
      </Modal>
      <Modal
        title={`View Record - ${recordToView ? recordToView.pdcs : ''}`}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >
        {/* ... (existing code) */}
      </Modal>
    </div>
    </>
    </Spin>
  );
};

export default App;
