// import React, { useState } from 'react';
// import { Form, Input, Button, message } from 'antd';
// import { useHistory } from 'react-router-dom';
// import supabase from "../helper/supabaseClient.js";

// const ResetPassword = () => {
//   const [loading, setLoading] = useState(false);
//   const history = useHistory();

//   const onFinish = async ({ email, password }) => {
//     setLoading(true);
//     try {
//       const { error } = await supabase.auth.updateUser({ email:email, password:password });
//       if (error) throw error;
//       message.success('Password reset successful. You are now being redirected to the login page...');
//       history.push('/login');
//     } catch (error) {
//       console.error('Reset password error:', error.message || error);
//       message.error('Error resetting password.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Form onFinish={onFinish}>
//       <Form.Item
//         name="email"
//         rules={[
//           {
//             type: 'email',
//             message: 'The input is not a valid email address',
//           },
//           {
//             required: true,
//             message: 'Please input your email!',
//           },
//         ]}
//       >
//         <Input placeholder="Email" />
//       </Form.Item>
//       <Form.Item
//         label="New Password"
//         name="password"
//         rules={[
//           { required: true, message: 'Please input your new password!' },
//           { min: 6, message: 'Password must be at least 6 characters long!' },
//         ]}
//       >
//         <Input.Password />
//       </Form.Item>

//       <Form.Item>
//         <Button type="primary" htmlType="submit" loading={loading}>
//           Reset Password
//         </Button>
//       </Form.Item>
//     </Form>
//   );
// };

// export default ResetPassword;

import { useState , useEffect } from 'react';
import supabase from "../helper/supabaseClient.js";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  message
} from "antd";
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import TopNavbar from '../components/Home/components/Nav/TopNavbar.jsx';
// import signinbg from "../components/Home/assets/images/sign_In.png";
import zone from "../assets/images/zone.png";

import styled from 'styled-components';

const StyledFormContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: auto;
  margin-top:4rem;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #333;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #45a049;
  }
`;

const StyledError = styled.p`
  color: #ff0000;
  margin-top: 10px;
`;

const ResetPassword = () => {
  
  const { Header } = Layout;
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetError, setResetError] = useState(null);
  
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);
  
  const handleResetPassword = async () => {
    try {
      // Check if email and new password are provided
      if (!email || !newPassword) {
        setResetError('Please enter both email and new password.');
        return;
      }

      // Send password reset email
      const { data , error } = await supabase.auth.updateUser({ email:email, password:newPassword });

      if (resetError) {
        setResetError(resetError.message);
      } else {
        // alert('Password reset email sent successfully');
        message.success("Password reset email sent successfully");
        
    const signInLink = document.createElement("a");
    signInLink.href = "/sign-in";
    signInLink.click();
        
        // You may want to provide a confirmation message or redirect the user
      }
    } catch (error) {
      console.error('Reset password error:', error.message);
      setResetError('Reset password error: ' + error.message);
    }
  };



  const template = [
    <svg
      data-v-4ebdc598=""
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        data-v-4ebdc598=""
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill="#111827"
        className="fill-muted"
      ></path>
      <path
        data-v-4ebdc598=""
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill="#111827"
        className="fill-muted"
      ></path>
      <path
        data-v-4ebdc598=""
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill="#111827"
        className="fill-muted"
      ></path>
    </svg>,
  ];
  const profile = [
    <svg
      data-v-4ebdc598=""
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        data-v-4ebdc598=""
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill="#111827"
        className="fill-muted"
      ></path>
    </svg>,
  ];
  const signup = [
    <svg
      data-v-4ebdc598=""
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        data-v-4ebdc598=""
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill="#111827"
        className="fill-muted"
      ></path>
    </svg>,
  ];

  return (
    <>
     <Layout className="layout-default layout-signin">
   <Header>
            <div className="header-col header-brand dsn">
            <img src={zone} alt="" width={75} />
            </div>
            <div className="header-col header-nav">
              <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
                
              <Menu.Item key="2">
                  <Link to="/">
                    {profile}
                    <span>Home</span>
                  </Link>
                </Menu.Item>
                
                <Menu.Item key="1">
                  <Link to="/sign-in">
                    {template}
                    <span> Sign In</span>
                  </Link>
                </Menu.Item>
                
                <Menu.Item key="9">
                  <Link to="/sign-up">
                    {signup}
                    <span> Sign Up</span>
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="4">
                  <Link to="/sign-in">
                    {signin}
                    <span> Sign In</span>
                  </Link>
                </Menu.Item> */}
              </Menu>
            </div>
            <div className="header-col header-btn dsn">
              <Button type="primary">Quick Overview</Button>
            </div>
          </Header>
          <StyledFormContainer>
      <StyledLabel>Email:</StyledLabel>
      <StyledInput
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <StyledLabel>New Password:</StyledLabel>
      <StyledInput
        type="password"
        placeholder="Enter new password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />

      <StyledButton onClick={handleResetPassword}>Reset Password</StyledButton>

      {/* Display reset error if any */}
      {resetError && <StyledError>{resetError}</StyledError>}
    </StyledFormContainer>

     </Layout>
    </>
  );
};

export default ResetPassword;
