import React, { useState,useRef, useEffect } from 'react';
import { Form, Input, Spin,Button,Select,Row,Col,DatePicker ,Switch} from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { message } from 'antd';
import card from "../assets/images/company.png";
import card1 from "../assets/images/signature.png";

const { Option } = Select;

const App = () => {
  const [newRowData, setNewRowData] = useState (null);
  const [data, setData] = useState([
    {
      key: '1',
      accountcode: '1',
      accountname: 'fffy',
      amount: 'sadii',
      naration: 'pach pach',
     
    },
    {
      key: '2',
      accountcode: '1',
      accountname: 'fffy',
      amount: 'sadii',
      naration: 'pach pach',
    },
    {
      key: '3',
      accountcode: '1',
      accountname: 'fffy',
      amount: 'sadii',
      naration: 'pach pach',
    },
    {
      key: '4',
      accountcode: '1',
      accountname: 'fffy',
      amount: 'sadii',
      naration: 'pach pach',
    },
  ]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [check , setcheck] = useState(false);


  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const { data: { user } } = await supabase.auth.getUser();

    const userId = user.id;
      const { data: fetchCashPaymentData, error: cashPaymentError } = await supabase
        .from('cash_payment')
        .select('*').eq('user_id', userId);
  
      const { data: fetchMasterData, error: masterError } = await supabase
        .from('master')
        .select('*');

      const { data: fetchMasterData2, error: masterError2 } = await supabase
        .from('master')
        .select('*');
  
      const { data: fetchMasterData3, error: masterError3 } = await supabase
        .from('master')
        .select('*');
  
      const { data: fetchCurrencyData, error: currencyError } = await supabase
        .from('Currency')
        .select('*').eq('user', userId);

        setLoading(false);

     
  
      if (cashPaymentError || masterError || currencyError) {
        console.error('Error fetching data from Supabase:', cashPaymentError, masterError, currencyError);
      } else {
        setData(fetchCashPaymentData || []);
        setMasterOptions(fetchMasterData || []);
        setMasterOptions2(fetchMasterData2 || []); // Set data for Master 2
        setMasterOptions3(fetchMasterData3 || []); 
        setCurrencyOptions(fetchCurrencyData || []);
      }
    };
  
    fetchData();
  }, [check,setcheck]);
  

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();

      const userId = user.id;
      const { date, Account,amount,naration, remarks, master1, master2, master3, currency, ...rest } = values;
  
      // Find the corresponding master and currency objects
      const selectedMaster = masterOptions.find((option) => option.name === master1);
      const selectedMaster2= masterOptions2.find((option) => option.name === master2);
      const selectedMaster3 = masterOptions3.find((option) => option.name === master3);
      const selectedCurrency = currencyOptions.find((option) => option.name === currency);

      const newRow = {
        date: date.format('YYYY-MM-DD'),
        Account,
        amount,
        naration,
        remarks,
        master1:values.master1,
        master2: values.master2,
        master3: values.master3,
        currency:values.currency,
        user_id: userId,
      };
      setNewRowData(newRow);
      console.log('New Row:', newRow);
      console.log('Form Values:', values);
    console.log('New Row:', newRow);
  
      // Store data in Supabase
      const { data: createdData, error } = await supabase
        .from('cash_payment')
        .insert([newRow],{ skipRole: true });
  
      // Add to Supabase table
      // const { data: insertedData, error } = await supabase
      //   .from('cash_payment')
      //   .upsert([
      //     {
      //       date: date.format("YYYY-MM-DD"),
      //       Account,
      //       remarks,
      //       master1: selectedMaster.id,
      //       master2: selectedMaster2.id,
      //       master3: selectedMaster3.id,
      //       currency: selectedCurrency.id,
      //       ...rest,
      //     },
      //   ], { returning: 'representation' });

      //   console.log('Inserting data:', {
      //     date: date.format("YYYY-MM-DD"),
      //     Account,
      //     remarks,
      //     master1: selectedMaster.id,
      //     master2: selectedMaster2.id,
      //     master3: selectedMaster3.id,
      //     currency: selectedCurrency.id,
      //     ...rest,
      //   });
  
  //     if (error) {
  //       console.error('Error inserting data into Supabase:', error);
  //     } else {
  //       console.log('Data inserted into Supabase:', insertedData);
  
  //       // Update local state with the inserted data
  //       const newData = {
  //         key: data.length + 1,
  //         date: date.format("YYYY-MM-DD"),
  //         Account,
  //         remarks,
  //         master1: selectedMaster.name,
  //         master2: selectedMaster2.name,
  //         master3: selectedMaster3.name,        
  //         currency: selectedCurrency.name,
  //         ...rest,
  //       };
  //       setData([...data, newData]);
  //       message.success('Record added successfully');
  //     }
  //   } finally {
  //     setLoading(false);
  //     form.resetFields();
  //   }
  // };
  console.log('Created Data:', createdData);
  console.log('Error:', error);
    
        if (error) {
          console.error('Error storing data in Supabase:', error.message);
        } else {
          console.log('Data stored in Supabase:', createdData);
          setcheck(!check);
          // Optionally, you can show a success message or perform additional actions.
        }

        const insertedId = createdData[0].id;
    
        // Update local state with the new row
        setData((prevData) => [...prevData, newRow]);
    
        // Reset form fields

       
          }catch (error) {
        console.error('Error in onFinish:', error.message);
        // Handle error, show a message, etc.
      }
      finally {
        setLoading(false);
        form.resetFields();
      }
    };
  

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const [masterOptions, setMasterOptions] = useState([]);
const [currencyOptions, setCurrencyOptions] = useState([]);
const [masterOptions2, setMasterOptions2] = useState([]);
const [masterOptions3, setMasterOptions3] = useState([]);


  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const generatePdf = (values, newRow) => {
    console.log('New Row Data:', newRow);
    const goodsReceivedContent = generatePDFContent(values , newRow);

   
   
    const pdfOptions = {
      margin: 10,
      filename: `Cash__Payment${values.id}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    html2pdf().from(goodsReceivedContent).set(pdfOptions).save();
  };
  const generatePDFContent = (values, newRow) => {
    const companyDetails = {
      name: "Ozone",
      address: "Company Address, City",
      email: "info@company.com",
      phone: "+1 234 567 890",
      logoUrl: "",
    };
    let   totalGross = 0;
    data.forEach(item => {
      
      totalGross += Number(item.gross);
      
    });
    // Format dates using moment.js
   
    let tableData = Array.isArray(newRow) ? newRow : [newRow];
    // Construct table rows string
  
  
    console.log('Table Data:', tableData);
    let tableRows = tableData.map((rowData, index) => {
      console.log('Data row data :', rowData);
      
      return `<tr>
                <td>${index + 1}</td>
                <td>${values.quantity || ''}</td>
                <td>${values.unit || ''}</td>
                <td>${values.unitPrice || ''}</td>
                <td>${values.gross || ''}</td>
                <td>${values.dicount || ''}</td>
                <td>${values.taxable || ''}</td>
                <td>${values.vatType || ''}</td>
                <td>${values.vat || ''}</td>
                <td>${values.roundvat || ''}</td>
              </tr>`;
            
    }).join('');
  
    return `
    <!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Cash Payment Voucher</title>
  <style>
    body {
      font-family: 'Arial', sans-serif;
      margin: 0;
      padding: 0;
      font-size: 12px;
      background: white;
    }
    .container {
      max-width: 800px;
      margin: auto;
      padding: 20px;
      border: 1px solid #000;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .header img {
      max-width: 200px; /* Increased size */
      margin-top: 10px;
    }
    .header h1 {
      margin: 0;
    }
    .date {
      font-size: 14px;
    }
    .voucher-details {
      text-align: left;
      margin-bottom: 20px;
      padding: 0 20px;
    }
    .voucher-details p {
      border-bottom: 1px dotted #000;
      padding-bottom: 5px;
      margin-bottom: 20px;
    }
    .amount-container {
      border: 2px solid #000;
      width: 120px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      margin-bottom: 20px;
      margin-right: 20px;
      display: inline-block;
    }
    .amount {
      font-size: 18px;
    }
    .signature {
      display: inline-block;
      width: 30%;
      margin-right: 5%;
      text-align: center;
      vertical-align: top;
    }
    .signature img {
      width: 120px; /* Increased size */
      height: auto;
    }
    .signature p {
      border-top: 1px solid #000;
      padding-top: 5px;
      margin-top: 50px;
    }
    .footer {
      display: flex;
      justify-content: center; /* Adjusted for alignment */
      align-items: center;
      padding-top: 20px;
    }
    .footer img {
      max-width: 120px; /* Adjusted size */
      height: auto;
      display: block;
    }
    .footer:before, .footer:after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #000;
      margin: 0 20px;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="header">
      <img src="${card}" alt="Company Logo">
      <h1>Cash Payment Voucher</h1>
      <div class="date">Date: ${values.date || 'N/A'}</div>
    </div>
    <div class="voucher-details">
      <p><span class="amount-container"><span class="amount">Amount: &nbsp; ${values.amount}</span></span></p>
      <p>Paid to Mr./Ms.:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span> SAAD </span></p>
      <p>Account Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>${values.Account || 'N/A'}</span></p>
      <p>Description:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>${values.remarks || 'N/A'}</span></p>
    </div>
    <div class="footer">
    <div class="signature  ">
     
      <p>Authorised Signature</p>
    </div>
    <div class="signature " >
     
      <p>Manager Signature</p>
    </div>
    <div class="signature">
      <p>Accountant Signature</p>
    </div>
    <div class="signature ">
    <img src= ${card1} alt="Signature Logo">
  </div>
  </div>
</div>
</body>
</html>
    
    
    `;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Search ${dataIndex}'}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...editedRecord } : record
    );
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    setData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Code: </label>
          <Input
            value={editedRecord?.code}
            onChange={(e) => handleEditInputChange('code', e.target.value)}
          />
        </div>
        <div>
          <label>Name: </label>
          <Input
            value={editedRecord?.name}
            onChange={(e) => handleEditInputChange('name', e.target.value)}
          />
        </div>
        <div>
          <label>Name in Arabic: </label>
          <Input
            value={editedRecord?.name_ara}
            onChange={(e) => handleEditInputChange('name_ara', e.target.value)}
          />
        </div>
        <div>
          <label>Description: </label>
          <Input
            value={editedRecord?.descrip}
            onChange={(e) => handleEditInputChange('descrip', e.target.value)}
          />
        </div>
        
      
        {/* ... Add more fields as needed */}
      </div>
    );
  };

  const columns = [
    {
    title: 'Acount Code',
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      ...getColumnSearchProps('id'),
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '20%',
      },
      {
        title: 'Account',
        dataIndex: 'Account',
        key: 'Account',
        width: '20%',
      },
      {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        width: '20%',
      },
    
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: '30%',
      ...getColumnSearchProps('amount'),
    },

    {
        title: 'Naration',
        dataIndex: 'naration',
        key: 'naration',
        width: '30%',
        ...getColumnSearchProps('naration'),
      },
      {
        title: 'Master 1',
        dataIndex: 'master1',
        key: 'master1',
        width: '20%',
      },
      {
        title: 'Master 2',
        dataIndex: 'master2',
        key: 'master2',
        width: '20%',
      },
      {
        title: 'Master 3',
        dataIndex: 'master3',
        key: 'master3',
        width: '20%',
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        width: '20%',
      },
      {
        title: 'Print Invoice',
        key: 'printInvoice',
         render: (text, record) => (
     <Button onClick={() => {
     console.log('New Row Data in Print Invoice:', newRowData); // Debug
      generatePdf(record, newRowData);
          }}>Print Payment</Button>
),
      },
 
   
  
  ];
  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  return (
    <Spin spinning={loading}>

    <>
    <Form form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <div style={{ width: 600 }}>
              <Form.Item name="date"  rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <DatePicker placeholder="Date" style={{ width: '30%' }} />
              </Form.Item>
              <div style={{ display: 'flex' }}>
              <Form.Item name="Account" style={{ width: '30%' }} rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <Input placeholder="Debit Account"   />
              </Form.Item>
              <Form.Item name="amount" style={{ width: '30%',marginLeft:'10px' }} rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <Input placeholder="Amount"   />
              </Form.Item>
              </div>
              <Form.Item name="remarks" rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <Input placeholder="Remarks" style={{ width: '80%' }} />
              </Form.Item>
              <div>
              <Form.Item name="naration" style={{ width: '45%' }} rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <Input placeholder="Naration"   />
              </Form.Item>
              </div>

            </div>
          </Col>
          <Col span={12}>
            <Form.Item name="master1" rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
              <Select placeholder="Master 1" style={{ width: '40%',marginLeft:'100px' }}>
              {masterOptions.map((option) => (
                <Option key={option.id} value={option.name}>
                  {option.name}
                </Option>
              ))}
              </Select>
            </Form.Item>
            <Form.Item name="master2" rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
              <Select placeholder="Master 2" style={{ width: '40%',marginLeft:'100px' }}>
              {masterOptions2.map((option) => (
                <Option key={option.id} value={option.name}>
                  {option.name}
                </Option>
              ))}
              </Select>
            </Form.Item>
            <Form.Item name="master3" rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
              <Select placeholder="Master 3" style={{ width: '40%',marginLeft:'100px' }}>
              {masterOptions3.map((option) => (
                <Option key={option.id} value={option.name}>
                  {option.name}
                </Option>
              ))}
              </Select>
            </Form.Item>
            <Form.Item name="currency" rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
              <Select placeholder="Currency" style={{ width: '40%',marginLeft:'100px', }}>
              {currencyOptions.map((option) => (
                <Option key={option.id} value={option.name}>
                  {option.name}
                </Option>
              ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Space>
          <Button type="primary" htmlType="submit" style={{ background: 'white', color: 'blue' ,marginLeft:'400px'}}>
          <CheckOutlined /> Submit
          </Button>
        </Space>
      </Form>
      
      <br></br>
      <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <br></br>
                <br></br>

    <div style={{ overflowX: 'auto' }}>
      <Table id='table-to-export' columns={columns} dataSource={data} />
      <Modal
        title={'Delete Record - ${recordToDelete ? recordToDelete.name : }'}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={'Edit Record - ${recordToEdit ? recordToEdit.name : }'}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
        {renderEditForm()}
      </Modal>
      <Modal
        title={'View Record - ${recordToView ? recordToView.name : }'}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >
        {/* ... (existing code) */}
      </Modal>
    </div>
    </>
    </Spin>
  );
};

export default App;