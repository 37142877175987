import React, { useRef, useState } from 'react';
import { Button, Space, Table, Modal, Input } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const App = () => {
  const [data, setData] = useState([
    {
      key: '1',
      code: '113025',
      name: 'saad',
      descrip: 'sadii',
      unit: '001',
      vat: 'Email',
      quantity: '7',
      unitprice: '8',
      gross : '4',
      dicount:'7',
      taxable:'9',
      roundvat:'8',
     
    },
    {
      key: '2',
      code: '113025',
      name: 'saad',
      descrip: 'sadii',
      unit: '001',
      vat: 'Email',
      quantity: '7',
      unitprice: '8',
      gross : '4',
      dicount:'7',
      taxable:'9',
      roundvat:'8',
    },
    {
      key: '3',
      code: '113025',
      name: 'saad',
      descrip: 'sadii',
      unit: '001',
      vat: 'Email',
      quantity: '7',
      unitprice: '8',
      gross : '4',
      dicount:'7',
      taxable:'9',
      roundvat:'8',
    },
    {
      key: '4',
      code: '113025',
      name: 'saad',
      descrip: 'sadii',
      unit: '001',
      vat: 'Email',
      quantity: '7',
      unitprice: '8',
      gross : '4',
      dicount:'7',
      taxable:'9',
      roundvat:'8',
    },
  ]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);
  

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };


  const handleDeleteConfirm = () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...editedRecord } : record
    );
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    setData(updatedData);
  };


  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const calculateTotals = () => {
    let totalQuantity = 0, totalUnitPrice = 0, totalGross = 0, totalDiscount = 0, totalTaxable = 0, totalRoundVat = 0;
    data.forEach(item => {
      totalQuantity += Number(item.quantity);
      totalUnitPrice += Number(item.unitprice);
      totalGross += Number(item.gross);
      totalDiscount += Number(item.dicount);
      totalTaxable += Number(item.taxable);
      totalRoundVat += Number(item.roundvat);
    });
    return {
        key: 'total',
        code: 'Total',
        name: '',
        descrip: '',
        unit: '',
        vat: '',
        quantity: totalQuantity,
        unitprice: totalUnitPrice,
        gross: totalGross,
        dicount: totalDiscount,
        taxable: totalTaxable,
        roundvat: totalRoundVat,
      };
    };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Code: </label>
          <Input
            value={editedRecord?.code}
            onChange={(e) => handleEditInputChange('code', e.target.value)}
          />
        </div>
        <div>
          <label>Name: </label>
          <Input
            value={editedRecord?.name}
            onChange={(e) => handleEditInputChange('name', e.target.value)}
          />
        </div>
        <div>
          <label>Mobile No: </label>
          <Input
            value={editedRecord?.mobile}
            onChange={(e) => handleEditInputChange('mobile', e.target.value)}
          />
        </div>
        <div>
          <label>Email: </label>
          <Input
            value={editedRecord?.email}
            onChange={(e) => handleEditInputChange('email', e.target.value)}
          />
        </div>
        <div>
          <label>TRN NO: </label>
          <Input
            value={editedRecord?.trn}
            onChange={(e) => handleEditInputChange('trn', e.target.value)}
          />
        </div>
        <div>
          <label>Address: </label>
          <Input
            value={editedRecord?.address}
            onChange={(e) => handleEditInputChange('address', e.target.value)}
          />
        </div>
        <div>
          <label>City: </label>
          <Input
            value={editedRecord?.city}
            onChange={(e) => handleEditInputChange('city', e.target.value)}
          />
        </div>
        <div>
          <label>Account No: </label>
          <Input
            value={editedRecord?.acco}
            onChange={(e) => handleEditInputChange('acco', e.target.value)}
          />
        </div>
        {/* ... Add more fields as needed */}
      </div>
    );
  };
  
  const dataSourceWithTotal = [...data, calculateTotals()];

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: '30%',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Description',
      dataIndex: 'descrip',
      key: 'descrip',
      width: '30%',
      ...getColumnSearchProps('descrip'),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: '30%',
      ...getColumnSearchProps('unit'),
    },
    {
      title: 'Vat',
      dataIndex: 'vat',
      key: 'vat',
      width: '30%',
      ...getColumnSearchProps('vat'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '30%',
      ...getColumnSearchProps('qunatity'),
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitprice',
      key: 'unitprice',
      width: '30%',
      ...getColumnSearchProps('unitprice'),
    },
    {
        title: 'Gross',
        dataIndex: 'gross',
        key: 'gross',
        width: '30%',
        ...getColumnSearchProps('gross'),
      },
      {
        title: 'Discount',
        dataIndex: 'dicount',
        key: 'dicount',
        width: '30%',
        ...getColumnSearchProps('dicount'),
      },
      {
        title: 'Taxable',
        dataIndex: 'taxable',
        key: 'taxable',
        width: '30%',
        ...getColumnSearchProps('taxable'),
      },
        {
            title: 'Round Vat',
            dataIndex: 'roundvat',
            key: 'roundvat',
            width: '30%',
            ...getColumnSearchProps('roundvat'),
          },
      
   
  
  ];

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table columns={columns} dataSource={dataSourceWithTotal} />
      {/* ... [existing modals] */}
    </div>
  );


};

export default App;
