import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, Space, Table, Modal, DatePicker, Select, Spin } from 'antd';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import html2pdf from 'html2pdf.js';
import { message } from 'antd';
import moment from 'moment';

const { Option } = Select;

// ... (previous imports)

// ... (previous imports)

// ... (previous imports)

// ... (previous imports)

const App = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // Replace the following placeholder data with your actual data
    const sampleData = [
      { id: 1, pdcreceiveableaccount: 'Account 1', debitBalance: 100, creditBalance: 50 },
      { id: 2, pdcreceiveableaccount: 'Account 2', debitBalance: 200, creditBalance: 150 },
      // Add more data as needed
    ];

    setData(sampleData);
    setFilteredData(sampleData);
    setLoading(false);
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (pendingFilters) => {
    pendingFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    // ... (unchanged code)
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = () => {
    const updatedData = filteredData.filter((record) => record.id !== recordToDelete.id);
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setFilteredData(updatedData);
  };

  const handleEditConfirm = () => {
    const updatedData = filteredData.map((record) =>
      record.id === recordToEdit.id ? { ...editedRecord } : record
    );
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    setFilteredData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Account Code: </label>
          <Input
            value={editedRecord?.id}
            onChange={(e) => handleEditInputChange('id', e.target.value)}
          />
        </div>
        <div>
          <label>Account Name: </label>
          <Input
            value={editedRecord?.pdcreceiveableaccount}
            onChange={(e) => handleEditInputChange('pdcreceiveableaccount', e.target.value)}
          />
        </div>
        <div>
          <label>Balance Debit: </label>
          <Input
            value={editedRecord?.debitBalance}
            onChange={(e) => handleEditInputChange('debitBalance', e.target.value)}
          />
        </div>
        <div>
          <label>Balance Credit: </label>
          <Input
            value={editedRecord?.creditBalance}
            onChange={(e) => handleEditInputChange('creditBalance', e.target.value)}
          />
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: 'Account Code',
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Account Name',
      dataIndex: 'pdcreceiveableaccount',
      key: 'pdcreceiveableaccount',
      width: '30%',
      ...getColumnSearchProps('pdcreceiveableaccount'),
    },
    {
      title: 'Balance Debit',
      dataIndex: 'debitBalance',
      key: 'debitBalance',
      width: '20%',
      ...getColumnSearchProps('debitBalance'),
    },
    {
      title: 'Balance Credit',
      dataIndex: 'creditBalance',
      key: 'creditBalance',
      width: '20%',
      ...getColumnSearchProps('creditBalance'),
    },
  ];

  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    html2pdf(table);
  };

  const handleFilter = () => {
    // Filter records based on the date range
    const filteredData = data.filter((record) => {
      const recordDate = moment(record['date'], 'DD-MM-YY'); // Convert the date string to a moment object
      return (
        (!startDate || recordDate.isSameOrAfter(startDate, 'day')) &&
        (!endDate || recordDate.isSameOrBefore(endDate, 'day'))
      );
    });

    // Update the state with filtered data
    setFilteredData(filteredData);
  };

  return (
    <Spin spinning={loading}>
      <>
        <Form form={form}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            <Form.Item name="costCenter" style={{ width: '25%' }}>
              <Select placeholder="Cost Center">
                <Option value="1">Cost Center 1</Option>
                <Option value="2">Cost Center 2</Option>
                {/* Add more options as needed */}
              </Select>
            </Form.Item>
            <Form.Item name="deliveryDate" style={{ width: '35%', marginLeft: '20px' }}>
              <DatePicker placeholder="From Date" onChange={(date) => setStartDate(date)} />
            </Form.Item>
            <Form.Item name="returnDate" style={{ width: '25%', marginLeft: '0px' }}>
              <DatePicker placeholder="To Date" onChange={(date) => setEndDate(date)} />
            </Form.Item>
            <Form.Item style={{ width: '35%' }}>
              <Button type="primary" onClick={handleFilter}>
                Filter
              </Button>
            </Form.Item>
          </div>
        </Form>

        <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <br></br>
        <br></br>
        <div style={{ overflowX: 'auto' }}>
          <Table id='table-to-export' columns={columns} dataSource={filteredData} />
          <Modal
            title={`Delete Record - ${recordToDelete ? recordToDelete.pdcs : ''}`}
            visible={deleteModalVisible}
            onOk={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          >
            <p>Are you sure you want to delete this record?</p>
          </Modal>
          <Modal
            title={`Edit Record - ${recordToEdit ? recordToEdit.pdcs : ''}`}
            visible={editModalVisible}
            onOk={handleEditConfirm}
            onCancel={handleEditCancel}
          >
            {renderEditForm()}
          </Modal>
          <Modal
            title={`View Record - ${recordToView ? recordToView.pdcs : ''}`}
            visible={viewModalVisible}
            onOk={handleViewConfirm}
            onCancel={handleViewCancel}
          >
            {/* ... (existing code) */}
          </Modal>
        </div>
      </>
    </Spin>
  );
};

export default App;
