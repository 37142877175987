import React, { useState,useEffect } from 'react';
import { Tree, Modal, Input,Button, notification, Select, Popover, Badge, Tooltip , Spin } from 'antd';
import { DownOutlined, PlusCircleOutlined, MinusCircleOutlined,EditOutlined } from '@ant-design/icons';
import { FolderOpenTwoTone, PlusCircleTwoTone, MinusCircleTwoTone, BranchesTwoTone } from '@ant-design/icons';
import { CodeTwoTone } from '@ant-design/icons';
import { MinusOutlined, PlusOutlined, BranchesOutlined } from '@ant-design/icons';
import DottedBarSVG from '../assets/images/dotted-bar.svg';
import DottedBariSV from '../assets/images/dotted-bar2.svg';
import supabase from "../helper/supabaseClient.js";

import "antd/dist/antd.css";
// import '../assets/styles.css';

const { Option } = Select;
const { confirm } = Modal;

const defaultTreeData = [{ title: "Asset", key: "0", type: "entry" }];
const products = [];

const countSubNodes = (nodes) => nodes.reduce((acc, node) => acc + (node.children ? countSubNodes(node.children) : 0), nodes.length);
const updateNode = (tree, key, newNodeData) => {
  return tree.map((node) => {
    if (node.key === key) {
      return { ...node, ...newNodeData };
    } else if (node.children) {
      return { ...node, children: updateNode(node.children, key, newNodeData) };
    }
    return node;
  });
};

const App = () => {
  const [check , setcheck] = useState(false);
  const [treeData, setTreeData] = useState(defaultTreeData);
  const [visible, setVisible] = useState(false);
  const [nodeToAdd, setNodeToAdd] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [products, setProducts] = useState([]);
    const convertFlatToHierarchical = (flatData, rootKey = '0') => {
    const map = new Map();
    const tree = [];
  
    flatData.forEach((node) => {
      map.set(node.key, { ...node, children: [] });
    });
  
    flatData.forEach((node) => {
      const currentNode = map.get(node.key);
      const parentNode = map.get(node.parent_key);
  
      if (parentNode) {
        // Check the type of the current node
        if (node.type === 'product') {
          // If it's a product, find the closest ancestor of type 'subsubgroup'
          let ancestorNode = parentNode;
          while (ancestorNode && ancestorNode.type !== 'subsubgroup') {
            ancestorNode = map.get(ancestorNode.parent_key);
          }
  
          // If an ancestor of type 'subsubgroup' is found, nest the product under it
          if (ancestorNode) {
            ancestorNode.children.push(currentNode);
          } else {
            // Check the type of the parent node
            if (parentNode.type === 'group') {
              // If parent is a group, nest it directly under the group
              parentNode.children.push(currentNode);
            } else {
              // If parent is a subgroup, nest it directly under the subgroup
              const grandparentNode = map.get(parentNode.parent_key);
              grandparentNode.children.push(currentNode);
            }
          }
        } else {
          // If it's not a product, nest it under the parent
          parentNode.children.push(currentNode);
        }
      } else {
        tree.push(currentNode);
      }
    });
  
    return tree;
  };
  
  

  const fetchProductOptions = async (entryType, parentNodeKey) => {
    const { data: { user } } = await supabase.auth.getUser();
    const userId = user.id;
  
    try {
      let optionsQuery = supabase.from('creditors');
      console.log(entryType);
      const findHighestLevelParent = async (key) => {
        let currentKey = key;
        let parentKey;
      
        do {
          const { data, error } = await supabase
            .from('debitors')
            .select('parent_key, type')
            .eq('key', currentKey);
      
          console.log('Data:', data); // Add this line for debugging
      
          if (error) {
            console.error('Error fetching parent key:', error.message);
            return null;
          }
      
          if (!data) {
            console.error('Data is undefined or null. Unexpected response structure.');
            return null;
          }
      
          parentKey = data[0]?.parent_key; // Check if data is an array and use the first item
      
          console.log('Current key:', currentKey);
          console.log('Parent key:', parentKey);
      
          if (parentKey && parentKey !== '0') {
            currentKey = parentKey;
          }
        } while (parentKey && parentKey !== '0');
      
        return currentKey;
      };
      
      
      console.log('parentNodeKey',parentNodeKey);
      
      const highestParentKey = await findHighestLevelParent(parentNodeKey);
console.log('highestParentKey',highestParentKey);
    if (!highestParentKey) {
      console.error('Error finding highest level parent.');
      return;
    }
    const { data: { title: highestParentTitle }, error: parentError } = await supabase
    .from('debitors')
    .select('title')
    .eq('key', highestParentKey)
    .single();

  if (parentError) {
    console.error('Error fetching highest parent title:', parentError.message);
    return;
  }

  // Update `entryType` to the highest parent's title
   entryType = highestParentTitle;
  
      // Define a mapping of entry types to their corresponding parent keys
      const entryTypeToParentKeyMapping = {
        'Assets': '0',
        'Liabilities': 'node-1704774063379',
        'Cost and Expenses': 'node-1704774046636',
        'Revenue': 'node-1704774054452',
        'Vat Group': 'node-1704774054452',
        // Add more mappings as needed
      };
  
      // Use the mapping to fetch options based on the parent key
      optionsQuery = optionsQuery
        .select('title')
        .eq('parent_key', entryTypeToParentKeyMapping[entryType]);
  
      const { data: creditorData, error: creditorError } = await optionsQuery;
  
      if (creditorError) {
        console.error('Error fetching data from creditors table:', creditorError.message);
      } else {
        const creditorOptions = creditorData.map(creditor => ({ type: 'creditor', value: creditor.title, label: ` ${creditor.title}` }));
        setProducts(creditorOptions);
      }
    } catch (error) {
      console.error('Error in fetchProductOptions:', error.message);
    }
  };
  
  const onSelect = async (selectedKeys, info) => {
    try {
      setSubmitLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;
  
      const { data, error } = await supabase.from('debitors').select('*').in('user_id', ['e239ab34-4152-4eed-bb99-db75f4a1b3de', userId]);
      if (error) {
        console.error('Error fetching data from Supabase:', error.message);
        return;
      }
  
      console.log('Fetched data from Supabase:', data);
  
      const hierarchicalTreeData = convertFlatToHierarchical(data);
  
      console.log('Converted hierarchical data:', hierarchicalTreeData);
  
      // Check the type of the entry (assuming the entry type is stored in the data)
      const selectedNodeKey = info.selectedNodes[0]?.key; // Get the key of the selected node
      const selectedNode = data.find((node) => node.key === selectedNodeKey);
      const entryType = selectedNode ? selectedNode.title : data[0].title; // Fallback to the first node's title if not found
  
      // Fetch product options based on entry type
      await fetchProductOptions(entryType, selectedNodeKey); // Pass the selected node's key
  
      setTreeData(hierarchicalTreeData);
    } catch (error) {
      console.error('Error in onSelect:', error.message);
    } finally {
      setSubmitLoading(false);
    }
  };
  

  useEffect(() => {
    const fetchFlatData = async () => {
      try {
        setSubmitLoading(true);
        const { data: { user } } = await supabase.auth.getUser();
        const userId = user.id;

        const { data, error } = await supabase.from('debitors').select('*').in('user_id', ['e239ab34-4152-4eed-bb99-db75f4a1b3de', userId]);
        if (error) {
          console.error('Error fetching data from Supabase:', error.message);
          return;
        }

        console.log('Fetched data from Supabase:', data);

        const hierarchicalTreeData = convertFlatToHierarchical(data);

        console.log('Converted hierarchical data:', hierarchicalTreeData);

        setTreeData(hierarchicalTreeData);
      } catch (error) {
        console.error('Error in fetchFlatData:', error.message);
      } finally {
        setSubmitLoading(false);
      }
    };

    // Call the function to fetch and set data
    fetchFlatData();
  }, [check, setcheck]);
  
  


  const addNodeToSupabase = async (nodeData) => {
    try {
      console.log('Adding node to Supabase:', nodeData);
      setcheck(!check);
      const { data, error } = await supabase.from('debitors').upsert([nodeData]).eq('user_id', (await supabase.auth.getUser()).data.user.id);
      console.log('Supabase response:', data, error);
      if (error) {
        console.error('Error adding node to Supabase:', error.message);
        return null;
      }
      return data[0];
    } catch (error) {
      console.error('Error in addNodeToSupabase:', error.message);
      throw error;
    }
  };
  const determineNodeType = async (parentKey) => {
    try {
      // Fetch the parent node from Supabase based on parentKey
      const { data: parentNode, error } = await supabase
        .from('debitors')
        .select('type')
        .eq('key', parentKey)
        .single();
  
      if (error) {
        console.error('Error fetching parent node from Supabase:', error.message);
        // Handle the error or return a default type
        return 'subgroup';
      }
  
      // Check the type of the parent node and determine the new node type
      if (parentNode.type === 'group') {
        // If the parent is a group, set the type to 'subgroup'
        return 'subgroup';
      } else if (parentNode.type === 'subgroup') {
        // If the parent is a subgroup, set the type to 'subsubgroup' (modify as needed)
        return 'subsubgroup';
      }
  
      // Default to 'subgroup' if no specific condition is met
      return 'subgroup';
    } catch (error) {
      console.error('Error in determineNodeType:', error.message);
      // Handle the error or return a default type
      return 'subgroup';
    }
  };
  
  
  const handleAddNode = async (parentKey) => {
    try {
      if (!parentKey) {
        console.error("Invalid parentKey:", parentKey);
        return;
      }

      console.log("Adding node with parentKey:", parentKey);
      const newNodeType = determineNodeType(parentKey);

      const newNodeData = {
        title: inputValue,
        key: `node-${Date.now()}`,
        type: newNodeType,
        parent_key: parentKey || null,
      };

      // Use Supabase Realtime to listen for changes
      const { data, error } = await supabase.from('debitors').upsert([newNodeData]);
      console.log('Supabase response:', data, error);

      if (error) {
        console.error('Error adding node to Supabase:', error.message);
        return null;
      }

      // The added node is already available in the real-time response
      const addedNode = data[0];

      // Update the tree with the new node
      setTreeData((prevTreeData) => addNode(addedNode.key, addedNode.title, addedNode.parent_key, prevTreeData, addedNode.type));
    } catch (error) {
      console.error('Error in handleAddNode:', error.message);
    }
  };
  const [arabicName, setArabicName] = useState('');
  const handleSave = async () => {
    try {
      const newNodeData = {
        title: inputValue,
        key: `node-${Date.now()}`,
        type: nodeToAdd.type,
        arabic_name:arabicName,
        parent_key: nodeToAdd.parent.key,
        nature: nodeToAdd.nature,
      };
  
      if (nodeToAdd.key) {
        // If nodeToAdd has a key, it means it's in edit mode
        newNodeData.key = nodeToAdd.key;
  
        // Update the node in Supabase
        await supabase.from('debitors').upsert([newNodeData]);
  
        // Update the local state with the new data
        setTreeData((prevTreeData) => updateNode(prevTreeData, newNodeData.key, newNodeData));
      } else {
        // If no key is present, it means it's in add mode
        // Use Supabase Realtime to listen for changes
        const { data, error } = await supabase.from('debitors').upsert([newNodeData]);
  
        if (error) {
          console.error('Error adding/editing node to Supabase:', error.message);
          return;
        }
  
        // The added/edit node is already available in the real-time response
        const addedNode = data[0];
  
        // Update the tree with the new node
        setTreeData((prevTreeData) => updateNode(prevTreeData, addedNode.key, addedNode));
      }
      setVisible(false);
      setInputValue('');
      setNodeToAdd(null);
    } catch (error) {
      console.error('Error in handleSave:', error.message);
    }
    setVisible(false);
    window.location.reload();
  };
  
  
  

  const onDeleteNode = async (key, tree = treeData) => {
    if (!Array.isArray(tree)) {
      console.error('Invalid tree structure:', tree);
      return treeData;
    }
  
    // Find the node to delete
    const nodeToDelete = tree.find((data) => data.key === key);
  
    if (nodeToDelete) {
      // Fetch all descendants of the node to be deleted
      const descendants = [];
      const collectDescendants = (node) => {
        descendants.push(node.key);
        if (node.children) {
          node.children.forEach(collectDescendants);
        }
      };
      collectDescendants(nodeToDelete);
  
      // Delete each descendant from Supabase
      for (const descendantKey of descendants) {
        await deleteNodeFromSupabase(descendantKey);
      }
  
      // Return the updated tree without the deleted node
      return tree.filter((data) => !descendants.includes(data.key));
    }
    
    // Recursively check each child node
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].children && Array.isArray(tree[i].children)) {
        let newChildren = await onDeleteNode(key, tree[i].children);
        if (newChildren !== tree[i].children) {
          // Return the updated tree with the modified children
          return [...tree.slice(0, i), { ...tree[i], children: newChildren }, ...tree.slice(i + 1)];
        }
      }
    }
    window.location.reload();

  
    return tree;
  };  
  const deleteNodeFromSupabase = async (key) => {
    try {
      // Perform the delete operation on Supabase using the key
      const { error } = await supabase.from('debitors').delete().eq('key', key);

      if (error) {
        console.error('Error deleting node from Supabase:', error.message);
      }
    } catch (error) {
      console.error('Error in deleteNodeFromSupabase:', error.message);
      throw error;
    }
  };



  const addNode = (key, value, parentKey = '0', tree = treeData, type = "group") => {
    window.location.reload();

    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.key === parentKey) {
        const newTree = [...tree];
        const newNode = { key, title: value, type };
        newTree[i] = { ...node, children: node.children ? [...node.children, newNode] : [newNode] };
        return newTree;
      }
      if (node.children) {
        const newChildrenTree = addNode(key, value, parentKey, node.children, type);
        if (newChildrenTree) {
          const newTree = [...tree];
          newTree[i] = { ...node, children: newChildrenTree };
          return newTree;
        }
      }
    }
    return null;
  };

  const showConfirmDelete = (nodeData, type) => {
    confirm({
      title: `Delete ${type}`,
      content: `Are you sure you want to delete this ${type}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setTreeData(onDeleteNode(nodeData.key));
        notification['success']({
          message: 'Operation Success',
          description: `${type.charAt(0).toUpperCase() + type.slice(1)} was deleted successfully!`,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    });    


  };

  const showConfirmAddSubSubgroup = (nodeData) => {
    confirm({
      title: `Add SubSubGroup`,
      content: `Are you sure you want to add a subsubgroup to this subgroup?`,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        showModal(nodeData, "subsubgroup");
      }
    });
  };

  const handleAddProduct = async (parentKey, productName) => {

    try {
      if (!parentKey) {
        console.error("Invalid parentKey:", parentKey);
        return;
      }
  
      console.log("Adding product with parentKey:", parentKey);
  
      const newNodeData = {
        title: productName,
        key: `node-${Date.now()}`,
        type: 'product',
        parent_key: parentKey,
      };
  
      const addedProduct = await addNodeToSupabase(newNodeData);

      if (addedProduct) {
        setTreeData((prevTreeData) => addNode(newNodeData.key, newNodeData.title, newNodeData.parent_key, prevTreeData, newNodeData.type));
      }
    } catch (error) {
      console.error('Error in handleAddProduct:', error.message);
    }
  };

  
  const showConfirmAddProduct = (nodeData, productName) => {
    confirm({
      title: `Add Product`,
      content: `Are you sure you want to add ${productName} to this subgroup or subsubgroup?`,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        setVisible(false);
        handleAddProduct(nodeData.key, productName);
        setTimeout(() => {
          // Reload the page after adding the product
          window.location.reload();
        }, 1000);

        notification.success({ message: 'Operation Success', description: `Product ${productName} was added successfully!` });
        
      },
      onCancel() { setVisible(false); }
    });

  };
  

  const showModal = (nodeData, type) => {
    setNodeToAdd({ parent: nodeData, type: type });
    setVisible(true);
  };

  const handleOk = async () => {
    try {
      // Add the following line to call handleAddNode with the parent key
      await handleAddNode(nodeToAdd.parent.key);
  
      // Rest of your existing code
      setTreeData(addNode(`node-${Date.now()}`, inputValue, nodeToAdd.parent.key, treeData, nodeToAdd.type));
      notification['success']({
        message: 'Operation Success',
        description: `${nodeToAdd.type.charAt(0).toUpperCase() + nodeToAdd.type.slice(1)} ${inputValue} was added successfully!`,
      });
      setInputValue('');
      setVisible(false);
    } catch (error) {
      console.error('Error in handleOk:', error.message);
      // Handle the error or show a notification
    }
  };
  

  const handleCancel = () => {
    setInputValue('');
    setVisible(false);
  };


  const [editingNode, setEditingNode] = useState(null);
const [editedTitle, setEditedTitle] = useState('');

const handleDoubleClick = (nodeData) => {
  setEditingNode({ key: nodeData.key, originalTitle: nodeData.title });
  setEditedTitle(nodeData.title);
};
const handleKeyDown = async (e, nodeData) => {
  // Handle Enter key press
  if (e.key === 'Enter') {
    e.preventDefault();
    await handleBlur(); // Close the edit box
  }
};

const handleInputChange = (e) => {
  setEditedTitle(e.target.value);
};

const handleBlur = async () => {
  try {
    if (editingNode) {
      const { key, originalTitle } = editingNode;

      if (editedTitle !== originalTitle) {
        // Update the node title in Supabase
        await updateNodeTitleInSupabase(key, editedTitle);

        // Update the local state with the new title
        setTreeData((prevTreeData) =>
          updateNodeTitle(prevTreeData, key, editedTitle)
        );
      }
    }
  } catch (error) {
    console.error('Error in handleBlur:', error.message);
  }

  setEditingNode(null);
};

// ... (rest of the code)

const updateNodeTitleInSupabase = async (key, newTitle) => {
  try {
    // Perform the update operation on Supabase using the key
    await supabase
      .from('debitors')
      .update({ title: newTitle })
      .eq('key', key);
  } catch (error) {
    console.error('Error in updateNodeTitleInSupabase:', error.message);
    throw error;
  }
};

const updateNodeTitle = (tree, key, newTitle) => {
  return tree.map((node) => {
    if (node.key === key) {
      return { ...node, title: newTitle };
    } else if (node.children) {
      return { ...node, children: updateNodeTitle(node.children, key, newTitle) };
    }
    return node;
  });
};

const showEditModal = (nodeData) => {
  setNodeToAdd({
    parent: nodeData,
    type: 'group',
    nature: nodeData.nature, // Assuming 'nature' is a property in your data
    key: nodeData.key,
    title: nodeData.title,
  });
  setVisible(true);
};




  return (
    <Spin spinning={submitLoading}>
    <div style={{ margin: '30px' }}>


      <Tree
        defaultExpandedKeys={['0']}
        treeData={treeData}
        showLine={true} //add tree lines
        onSelect={onSelect}
        titleRender={(nodeData) => {
          let titleStyle = {
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            marginBottom: '14px',
            marginRight: '10px',
          };
  
          let badgeStyle = {
            backgroundColor: '#52c41a',
            marginLeft: '10px',
          };
  
          let iconStyle = {
            fontSize: '14px',
            marginRight: '10px',
          };
  
          // Apply different styles based on node type
          switch (nodeData.type) {
            case 'entry':
              titleStyle.color = 'red'; // Change color for entry type
              break;
            case 'group':
              titleStyle.color = 'black'; // Change color for group type
              break;
            case 'subgroup':
              titleStyle.color = 'brown'; // Change color for subgroup type
              break;
              case 'subsubgroup':
                titleStyle.color = 'blue';
            case 'product':
              titleStyle.color = 'orange'; // Change color for product type
              break;
            default:
              break;
          }
          const isEditing = editingNode && editingNode.key === nodeData.key;

          return (
            <div style={titleStyle}>
              <FolderOpenTwoTone style={{ fontSize: '14px', marginRight: '10px' }} />
              <img src={DottedBariSV} alt="divider" style={{ marginRight: '10px' }} />
              {/* <span>{nodeData.title}</span> */}
              {isEditing ? (
              <Input
                value={editedTitle}
                autoFocus
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={handleBlur}
                onPressEnter={handleBlur}  // Close the edit box when Enter is pressed
                onKeyDown={(e) => handleKeyDown(e, nodeData)} // Handle Enter key press
              />
            ) : (
              <span onDoubleClick={() => handleDoubleClick(nodeData)}>
                {nodeData.title}
              </span>
            )}
              <Badge count={nodeData.children ? nodeData.children.length : 0} style={{ backgroundColor: '#52c41a', marginLeft: '10px' }} />
              {/* <img src={DottedBarSVG} alt="divider" style={{ marginRight: '8px' }} /> */}

              <img src={DottedBarSVG} alt="Dotted Bar" className="icon-spacing" />
              {
                nodeData.type !== 'entry' &&
                <Tooltip title="Delete singlenode"><MinusCircleOutlined
                  onClick={() => showConfirmDelete(nodeData, nodeData.type)} /></Tooltip>
              }
              {
                nodeData.type === 'entry' &&
                <Tooltip title="Add Group"><PlusOutlined onClick={() => showModal(nodeData, 'group')} /></Tooltip>
              }
                            <img src={DottedBarSVG} alt="divider" style={{ marginRight: '8px' }} />
              {
                nodeData.type === 'group' &&
                <>
                <Tooltip title="Add Subgroup"><PlusOutlined
                  onClick={() => showModal(nodeData, 'subgroup')} /></Tooltip>
                                              <img src={DottedBarSVG} alt="divider" style={{ marginRight: '8px' }} />

                <Tooltip title="Edit Group">
                <EditOutlined onClick={() => showEditModal(nodeData)} />
              </Tooltip>
              </>
              }
              {
                nodeData.type === 'subgroup' &&
                <>
                <Tooltip title="Add Subsubgroup"><PlusOutlined
                  onClick={() => showModal(nodeData, 'subsubgroup')} /></Tooltip>
                                              <img src={DottedBarSVG} alt="divider" style={{ marginRight: '8px' }} />

                  <Tooltip title="Edit Group">
                <EditOutlined onClick={() => showEditModal(nodeData)} />
              </Tooltip>
              </>
              }
              {
              nodeData.type === 'subsubgroup' &&
              <>

<Tooltip title="Edit Group">
<EditOutlined onClick={() => showEditModal(nodeData)} />
</Tooltip>

              </>
            }
            </div>
          );
        }}
      />
 <Modal
title={nodeToAdd ? `Add Group ` : 'Add Group'}  visible={visible}
  onOk={handleSave}
  onCancel={handleCancel}
>
  <div style={{ marginBottom: '16px' }}>
    <strong>Group</strong> 
  </div>
  <Input
    placeholder={`Name`}
    value={inputValue}
    onChange={(e) => setInputValue(e.target.value)}
    style={{ marginBottom: '16px' }}
  />
  <Input
  placeholder={`Arabic Name`}
  value={arabicName}
  onChange={(e) => setArabicName(e.target.value)}
  style={{ marginBottom: '16px' }}
/>
<Select
          placeholder="Account Type"
          style={{ width: '100%', marginBottom: '16px' }}
          onChange={(value) => setNodeToAdd({ ...nodeToAdd, nature: value })}
          value={nodeToAdd ? nodeToAdd.nature : undefined}
        >
          {products.map(product => (
            <Option key={product.value} value={product.value}>
              {product.label}
            </Option>
          ))}
</Select>

</Modal>

    </div>
    </Spin>
  );
};


export default App;