// Addaccount.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { a } from "../../../redux/slices/subtree";
import Subtree from "../Subtree/Subtree";
import { Modal, Input } from "antd";
import addacc from "../../../assets/images/accounttype/addacc.png";
import line from "../../../assets/images/accounttype/line.png";
import plus from "../../../assets/images/accounttype/plus.png";
import minus from "../../../assets/images/accounttype/minus.png";
import del from "../../../assets/images/accounttype/del.png";
import last from "../../../assets/images/subtree/last.png";
import "./cost.css";

const CostCenters = (props) => {
  const dispatch = useDispatch();
  const [localToggle, setLocalToggle] = useState(false); // Local state for toggle
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [subtreeItems, setSubtreeItems] = useState([
    { name: "(2) B - AL WAKEEL ALMUMYAZ TECHNICAL SERVICES" },
    { name: "(3) C - MANARAH AL SHAM VEGETABLES AND FRUIT Trading LLC" },
    { name: "(4) D - MANARAT HORAN FOODSTUFF TRADING LLC" },
    { name: "(5) E - OSCAR VEGETABLES & FRUITS LLC"},
  ]);
  const [subsubtreeItems, setSubsubtreeItems] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const handleToggleClick = () => {
    setLocalToggle(!localToggle); // Toggle the local state
    dispatch(a.changetoggle()); // You can keep the global state toggle if needed
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (editIndex !== null) {
      const updatedSubtreeItems = [...subtreeItems];
      updatedSubtreeItems[editIndex] = { name: inputValue };
      setSubtreeItems(updatedSubtreeItems);
      setEditIndex(null);
    } else {
      setSubtreeItems((prevItems) => [...prevItems, { name: inputValue }]);
    }
    setIsModalVisible(false);
    setInputValue("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setInputValue("");
    setEditIndex(null);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDelete = (index) => {
    const updatedSubtreeItems = [...subtreeItems];
    updatedSubtreeItems.splice(index, 1);
    setSubtreeItems(updatedSubtreeItems);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setInputValue(subtreeItems[index].name);
    showModal();
  };

  return (
    <div className="a">
      <div className="costcontainer">
        <div>
          {localToggle && (
            <img onClick={handleToggleClick} src={minus} alt="" />
          )}
          {!localToggle && (
            <img onClick={handleToggleClick} src={plus} alt="" />
          )}
        </div>
        <div>
          <img src={line} alt="" />
        </div>
        <div>
          <img src={addacc} alt="" />
        </div>
        <div>
          <span>{props.name}</span>
        </div>
        <div>
          <img src={line} alt="" />
        </div>
        <div>
          <button className="btn" onClick={showModal}>
            Add
          </button>
        </div>
      </div>
      <div className="sb">
        {localToggle &&
          subtreeItems.map((item, index) => (
            <div className="d" key={index}>
              <Subtree name={item.name} index={index} onDelete={handleDelete} />
              <img className="img" src={last} alt="" onClick={() => handleEdit(index)} />
              <img className="img" src={del} alt="" onClick={() => handleDelete(index)} />
            </div>
          ))}
      </div>

      <Modal
        title={editIndex !== null ? "Edit Input" : "Enter Input"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Enter your input"
          value={inputValue}
          onChange={handleInputChange}
        />
      </Modal>
    </div>
  );
};

export default CostCenters;
