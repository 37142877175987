import React, { useRef, useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Upload, Table, Space, Modal, Spin } from 'antd';
import {  EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import logo from '../assets/images/logo.png';
import Highlighter from 'react-highlight-words';
import { sha256, sha224 } from 'js-sha256';
import supabase from "../helper/supabaseClient.js";
import { FilePdfOutlined, FileExcelOutlined ,LinkOutlined, CheckOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { f } from 'html2pdf.js';
//import images from '../assets/images';
const { Dragger } = Upload;

const YourFormComponent = () => {
  const [form] = Form.useForm();
  const [submitLoading, setLoading] = useState(false);

  


  const [data, setData] = useState([
    
  ]);
  
  
  
    
  
  

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const searchInput = useRef(null);

  const [InvoiceHostedUrl, setHostedUrl] = useState('');
  console.log("hostes is "+InvoiceHostedUrl);

const fetchTwilioConfigData = async () => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    const userId = user.id;

    const { data: siteSettingsData, error: siteSettingsError } = await supabase
      .from('Site_Settings')
      .select('Invoice_Hosted_URL')
      .eq('user_id', userId)
      .order('id', { ascending: false })
      .limit(1);

    if (siteSettingsError) {
      console.error('Error fetching Site Settings data:', siteSettingsError.message);
    } else {
      // Update the form fields with Site Settings data
      const InvoiceHostedUrl = siteSettingsData[0]?.Invoice_Hosted_URL || '';
      console.log('bsdk',InvoiceHostedUrl);
      setHostedUrl(InvoiceHostedUrl);
    }
  } catch (error) {
    console.error('Error in fetchTwilioConfigData:', error.message);
  }
};

useEffect(async () => {
  // Fetch Site Settings data when the component mounts
 await fetchTwilioConfigData();
}, []);



  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={'Search ${dataIndex}'}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    await supabase.from('Employee').delete().match({ id: recordToDelete.id });
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    fetchEmployee();
  };

  const handleEditConfirm = async () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.id ? { ...editedRecord } : record
    );

    
    await supabase
      .from('Employee')
      .update({
        name: editedRecord.name,
        mobile: editedRecord.mobile,
        telephone: editedRecord.teleno,
        email: editedRecord.email,
        designation: editedRecord.designation,
        address: editedRecord.address,
        country: editedRecord.country,
        branch: editedRecord.branch,
        remarks: editedRecord.remarks,
        employee: editedRecord.employee,
      })
      .match({ id: editedRecord.id });
    
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    fetchEmployee();
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        
        <div>
          <label> Full Name: </label>
          <Input
            value={editedRecord?.name}
            onChange={(e) => handleEditInputChange('name', e.target.value)}
          />
        </div>
        <div>
          <label>Mobile No: </label>
          <Input
            value={editedRecord?.mobile}
            onChange={(e) => handleEditInputChange('mobile', e.target.value)}
          />
        </div>
        <div>
          <label>Tele No: </label>
          <Input
            value={editedRecord?.teleno}
            onChange={(e) => handleEditInputChange('teleno', e.target.value)}
          />
        </div>
        <div>
          <label>Email: </label>
          <Input
            value={editedRecord?.email}
            onChange={(e) => handleEditInputChange('email', e.target.value)}
          />
        </div>
        <div>
          <label>Designation: </label>
          <Input
            value={editedRecord?.designation}
            onChange={(e) => handleEditInputChange('designation', e.target.value)}
          />
        </div>
        <div>
          <label>Address: </label>
          <Input
            value={editedRecord?.address}
            onChange={(e) => handleEditInputChange('address', e.target.value)}
          />
        </div>
        <div>
          <label>Country: </label>
          <Input
            value={editedRecord?.country}
            onChange={(e) => handleEditInputChange('country', e.target.value)}
          />
        </div>
        <div>
          <label>Branch: </label>
          <Input
            value={editedRecord?.branch}
            onChange={(e) => handleEditInputChange('branch', e.target.value)}
          />
        </div>
        <div>
          <label>Remarks: </label>
          <Input
            value={editedRecord?.remarks}
            onChange={(e) => handleEditInputChange('remarks', e.target.value)}
          />
        </div>
        <div>
          <label>Employee: </label>
          <Input
            value={editedRecord?.employee}
            onChange={(e) => handleEditInputChange('employee', e.target.value)}
          />
        </div>
      </div>
    );
  };

  const columns = [
    {
       
      title: 'Image',
      key: 'image',
      render: (record) => (
        InvoiceHostedUrl ? (
          <img
            src={InvoiceHostedUrl}
            alt=""
            style={{ width: '25px', height: '25px', marginBottom: '0px' }}
          />
        ) : (
          <img
            src={logo}
            alt=""
            style={{ width: '25px', height: '25px', marginBottom: '0px' }}
          />
        )
      ),
      
      
      },
    {
      title: ' Full Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobile',
      key: 'mobile',
      width: '30%',
      ...getColumnSearchProps('mobile'),
    },
    {
        title: 'Tele No',
        dataIndex: 'teleno',
        key: 'teleno',
        width: '30%',
        ...getColumnSearchProps('teleno'),
      },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      width: '30%',
      ...getColumnSearchProps('designation'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '30%',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: '30%',
      ...getColumnSearchProps('country'),
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      width: '30%',
      ...getColumnSearchProps('branch'),
    },
    {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        width: '30%',
        ...getColumnSearchProps('remarks'),
      },
      {
        title: 'Employee',
        dataIndex: 'employee',
        key: 'employee',
        width: '30%',
        ...getColumnSearchProps('employee'),
      },
    {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            {/* <EyeOutlined
              onClick={() => handleView(record)}
              style={{ color: 'blue', marginRight: 8 }}
            /> */}
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },
  
  ];
  const onFinish = async (values) => {
    try {
      // Set submitLoading to true before the API call
      setLoading(true);
    // Field Validations
   
    const errors = {};
  
    // // Name Validation
    // if (!/^[A-Za-z\s]+$/.test(values.name)) {
    //   errors.name = {
    //     value: values.name,
    //     errors: ['Please enter a valid name (alphabets only)'],
    //   };
    // }
  
    // // Mobile Validation
    // if (!/^\d+$/.test(values.mobile)) {
    //   errors.mobile = {
    //     value: values.mobile,
    //     errors: ['Please enter a valid mobile number (numbers only)'],
    //   };
    // }
  
    // // Teleno Validation
    // if (!/^\d+$/.test(values.teleno)) {
    //   errors.teleno = {
    //     value: values.teleno,
    //     errors: ['Please enter a valid telephone number (numbers only)'],
    //   };
    // }
  
    // // Email Validation
    // if (!/^\S+@\S+\.\S+$/.test(values.email)) {
    //   errors.email = {
    //     value: values.email,
    //     errors: ['Please enter a valid email address'],
    //   };
    // }
  
    // // Designation Validation
    // if (!/^[A-Za-z\s]+$/.test(values.designation)) {
    //   errors.designation = {
    //     value: values.designation,
    //     errors: ['Please enter a valid designation (alphabets only)'],
    //   };
    // }
  
    // // Address Validation
    // if (!values.address.trim()) {
    //   errors.address = {
    //     value: values.address,
    //     errors: ['Please enter your address'],
    //   };
    // }
  
    // // Country Validation
    // if (!/^[A-Za-z\s]+$/.test(values.country)) {
    //   errors.country = {
    //     value: values.country,
    //     errors: ['Please enter a valid country (alphabets only)'],
    //   };
    // }
  
    // // Branch Validation
    // if (!/^[A-Za-z\s]+$/.test(values.branch)) {
    //   errors.branch = {
    //     value: values.branch,
    //     errors: ['Please enter a valid branch (alphabets only)'],
    //   };
    // }
  
    // // Remarks Validation
    // if (!values.remarks.trim()) {
    //   errors.remarks = {
    //     value: values.remarks,
    //     errors: ['Please enter remarks'],
    //   };
    // }
  
    // // Employee Validation
    // if (!/^[A-Za-z\s]+$/.test(values.employee)) {
    //   errors.employee = {
    //     value: values.employee,
    //     errors: ['Please enter a valid employee details (alphabets only)'],
    //   };
    // }
  
    // // Check for duplicate entries
    // const isDuplicate = data.some(
    //   (record) =>
    //     record.name.toLowerCase() === values.name.toLowerCase() &&
    //     record.mobile === values.mobile &&
    //     record.teleno === values.teleno &&
    //     record.email.toLowerCase() === values.email.toLowerCase() &&
    //     record.designation.toLowerCase() === values.designation.toLowerCase() &&
    //     record.address.toLowerCase() === values.address.toLowerCase() &&
    //     record.country.toLowerCase() === values.country.toLowerCase() &&
    //     record.branch.toLowerCase() === values.branch.toLowerCase() &&
    //     record.remarks.toLowerCase() === values.remarks.toLowerCase() &&
    //     record.employee.toLowerCase() === values.employee.toLowerCase()
    // );
  
    // if (isDuplicate) {
    //   errors.duplicate = {
    //     value: values.name, // You can choose any field for duplicate check
    //     errors: ['This entry already exists. Please provide unique information.'],
    //   };
    //   }
      
  
    // // Display errors
    // if (Object.keys(errors).length > 0) {
    //   form.setFields(errors);
    //   return;
      // }
      const fileUrl = null;
      if (values.photo) {
  
        const file = values.photo[0];
        const timestamp = Date.now().toString();

        // timestamp + extension
        const fileName = `${timestamp}.${file.name.split('.').pop()}`;

        const extension = file.name.split('.').pop();

        
      const download = 'download';
      const fileUrl = `images/${download}.jpeg`;
      }
      

      // await supabase
      //   .storage
      //   .from('images')
      //   .upload(images/${timestamp}/${file.name}, file, {
      //     cacheControl: '3600',
      //     upsert: false,
      //     contentType: 'image/' + extension,
      //   })
      //   .then((response) => {
      //     console.log(response);
      //     message.success('Image uploaded successfully');
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     message.error('Image upload failed');
      //   });
      
      
      
      
      // complete file name images/timestamp then name of the file
      const hashedPassword = null;
     
      if (values.password) {
        // Hash the password
        const hashedPassword = sha256(values.password);
      }
   
      const isDuplicate = data.some((record) => record.name === values.name);
        if (isDuplicate) {
          message.error('Duplicate entry. Please enter a unique name.');
          return;
        }
      
      await supabase
        .from('Employee')
        .insert([
          {
            name: values.name,
            mobile: values.mobile,
            telephone: values.teleno,
            email: values.email,
            designation: values.designation,
            address: values.address,
            country: values.country,
            branch: values.branch,
            remarks: values.remarks,
            employee: values.employee,
            photo: fileUrl,
            username: values.username,
            password: hashedPassword,
          },
        ])
        .then(() => {
          message.success('Employee added successfully');
        })
        .catch((error) => {
          console.log(error);
          message.error('Employee add failed');
        });
      
  
    // Reset the form
    // form.resetFields();
  } catch (error) {
    console.error('Error submitting form:', error);
  } finally {
    // Set submitLoading to false after the API call, whether it succeeds or fails
      form.resetFields();
      fetchEmployee();
      setLoading(false);
    }
    
  };

  const fetchEmployee = async () => {
    try {
      const { data, error } = await supabase.from('Employee').select().eq('user', (await supabase.auth.getUser()).data.user.id);
      if (error) console.log('error', error);
      else {
        // set telephone to teleno
        const updatedData = data.map((record) => ({
          ...record,
          key: record.id,
          teleno: record.telephone,
          image: logo,
        }));
        setData(updatedData);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  React.useEffect(() => {
    fetchEmployee();
  }, []);


  
  
  
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  
  


  return (
    <Spin spinning={submitLoading}>
    <>
    
    
    <div>
        <br>
        </br>
        <h1><b>Your information</b></h1>
      <Form
        onFinish={onFinish}
        name="your_form"
        style={{ width: '1000px' }}
        form={form}
      >
        {/* First Row */}
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="name" rules={[{ required: true, message: 'Please enter your full name' }]}>
              <Input placeholder="Full Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="mobile" >
              <Input placeholder="Mobile No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="teleno" >
              <Input placeholder="Tel No" />
            </Form.Item>
          </Col>
        </Row>

        {/* Second Row */}
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="email" >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="designation" >
              <Input placeholder="Designation" />
            </Form.Item>
          </Col>
          <Col span={8}>
            {/* Add more fields in the second row if needed */}
          </Col>
        </Row>

        {/* Third Row */}
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="address" >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="country" >
              <Input placeholder="Country" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="branch" >
              <Input placeholder="Branch" />
            </Form.Item>
          </Col>
        </Row>

        {/* Additional fields within the same block */}
        <Row gutter={8}>
  <Col span={8}>
    <Form.Item name="remarks" >
      <Input placeholder="Remarks" />
    </Form.Item>
  </Col>
  <Col span={8}>
    <Form.Item name="employee" >
      <Input placeholder="Employee" />
    </Form.Item>
  </Col>
  <Col span={8}>
    {/* File Upload */}
    <Form.Item
                  name="photo"
  valuePropName="fileList"
  getValueFromEvent={(e) =>
    e && e.fileList ? e.fileList : []
  }
>
  <Dragger
    name="photo"
    multiple={false}
    style={{ width: '100%' }}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Choose File </p>
  </Dragger>
</Form.Item>
  </Col>
</Row>


        {/* Login Information */}
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="username" >
              <Input placeholder="Username" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="password" >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit" style={{ background: 'white', color: 'blue' }}>
          <CheckOutlined /> Submit
        </Button>
      </Form>

      {/* <Table columns={columns} dataSource={formData} style={{ marginTop: '20px' }} /> */}

     
    </div>
    

        <br>
        </br>
    <div style={{ overflowX: 'auto' }}>
      <Table columns={columns} dataSource={data} />
      <Modal
        title={'Delete Record - ${recordToDelete ? recordToDelete.name : }'}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={'Edit Record - ${recordToEdit ? recordToEdit.name :}'}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
        {renderEditForm()}
      </Modal>
      <Modal
        title={'View Record - ${recordToView ? recordToView.name :}'}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >
        {/* ... (existing code) */}
      </Modal>
    </div>
    </>
    </Spin>
  );
};

export default YourFormComponent;