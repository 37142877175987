import React, { useState,useRef } from 'react';
import { Form, Input, Button ,Spin} from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { Select, DatePicker, Row, Col ,message } from 'antd';
import supabase from "../helper/supabaseClient.js";


const { Option } = Select;

const CompanyForm =  () => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  const [cities, setCities] = useState([]);
    const [vats, setVats] = useState([]);

  const fetchCities = async () => {
    await supabase.from('City').select('*').eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
        if (error) console.log('error', error);
        else {
          setCities(data);          
        }
      });
    }


  const fetchVats = async () => {
    await supabase.from('VAT').select('*').eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
        if (error) console.log('error', error);
        else {
          // set vats in vat select
          setVats(data);
        }
      });
    }
  

  React.useEffect(() => {
    fetchCities();
    fetchVats();
  }
  , []);
  
  const [data, setData] = useState([
    
  ]);
  const handleAdd = () => {
    setSubmitLoading(true);

    try {
    form
      .validateFields()
      .then(async (values) => {
        // Custom validation for name field - disallow numeric characters
        // if (/^\d+$/.test(values.name)) {
        //   form.setFields([
        //     {
        //       name: 'name',
        //       errors: ['Company name cannot contain numeric characters'],
        //     },
        //   ]);
        //   throw new Error('Validation error');
        // }
  
        // Custom validation for mobile_num field - allow only numeric characters
        // if (!/^\d+$/.test(values.mobile_num)) {
        //   form.setFields([
        //     {
        //       name: 'mobile_num',
        //       errors: ['mobile_num number must contain only numeric characters'],
        //     },
        //   ]);
        //   throw new Error('Validation error');
        // }
  
        // Custom validation for email field - check if the email is valid
        // if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
        //   form.setFields([
        //     {
        //       name: 'email',
        //       errors: ['Please enter a valid email address'],
        //     },
        //   ]);
        //   throw new Error('Validation error');
        // }
  
        // Custom validation for trn field - allow only alphanumeric characters
        // if (!/^[a-zA-Z0-9]+$/.test(values.trn)) {
        //   form.setFields([
        //     {
        //       name: 'trn',
        //       errors: ['TRN must contain only alphanumeric characters'],
        //     },
        //   ]);
        //   throw new Error('Validation error');
        // }
  
        // Custom validation for duplicate entries
        // const isDuplicate = data.some(
        //   (record) => record.license_num.toLowerCase() === values.license_num.toLowerCase()
        // );
  
        // if (isDuplicate) {
        //   form.setFields([
        //     {
        //       name: 'name',
        //       errors: ['Company with this name already exists'],
        //     },
        //   ]);
        //   throw new Error('Duplicate entry');
        // }
  
        // Add other validations as needed for different fields
  
        const isDuplicate = data.some((record) => record.name === values.name);
        if (isDuplicate) {
          message.error('Duplicate entry. Please enter a unique name.');
          return;
        }
        await supabase
          .from("customer")
          .insert([
            {
              name: values.name,
              arabic_name: values.arabic_name,
              city: values.city,
              address: values.address,
              description: values.description,
              phone_num: values.phone_num,
              mobile_num: values.mobile,
              email: values.email,
              contact_person: values.contact_person,
              contact_person_name: values.contact_person_name,
              trn: values.trn,
              credit_limit: values.credit_limit,
              credit_days: values.credit_days,
              vat_type: values.vat,
              fax_num: values.fax_num,
              license_num: values.license_num,
              license_expiry: values.license_expiry,
            },
          ])
          .then(() => {
            console.log('Data inserted successfully');
            fetchCustomers();
          })
          .catch((err) => {
            console.log(err);
          });
        


        form.resetFields();

        console.log('Success:', values);
      })
      .catch((error) => {
        console.error('Validation failed:', error);
      });
    }
      finally {
        setSubmitLoading(false);
      }
  };

  
  const fetchCustomers = async () => {
    await supabase
      .from('customer')
      .select('*')
      .eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
        if (error) {
          console.log('error', error);
        } else {
          // Assign a unique key to each record
          const updatedData = data.map((customer) => {
            return {
              ...customer,
              key: customer.id, // Assuming "id" is a unique identifier for each record
            };
          });
  
          // Update the state with the new data
          setData(updatedData);
        }
      });
  };
  
  React.useEffect(() => {
    fetchCustomers();
  }
  , []);
  
  

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    try {
      // Delete the record from the database
      await supabase.from('customer').delete().match({ id: recordToDelete.id });
  
      // Update the state by filtering out the deleted record
      const updatedData = data.filter(
        (record) => record.key !== recordToDelete.key
      );
  
      // Set the updated data to the state
      setData(updatedData);
  
      // Close the delete modal
      setDeleteModalVisible(false);
      setRecordToDelete(null);
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };
  
  const handleEditConfirm = async () => {
    try {
      const updatedData = data.map((record) =>
        record.key === recordToEdit.id ? { ...editedRecord } : record
      );
  
      await supabase
        .from('customer')
        .update({
          name: editedRecord.name,
          arabic_name: editedRecord.arabic_name,
          city: editedRecord.city,
          address: editedRecord.address,
          description: editedRecord.description,
          phone_num: editedRecord.phone_num,
          mobile_num: editedRecord.mobile,
          email: editedRecord.email,
          contact_person: editedRecord.contact_person,
          contact_person_name: editedRecord.contact_person_name,
          trn: editedRecord.trn,
          credit_limit: editedRecord.credit_limit,
          credit_days: editedRecord.credit_days,
          vat_type: editedRecord.vat,
          fax_num: editedRecord.fax_num,
          license_num: editedRecord.license_num,
          license_expiry: editedRecord.license_expiry,
        })
        .match({ id: editedRecord.id });
  
      setEditModalVisible(false);
      setRecordToEdit(null);
      setEditedRecord(null);
      setData(updatedData);
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
  
  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Name: </label>
          <Input
            value={editedRecord?.name}
            onChange={(e) => handleEditInputChange('name', e.target.value)}
          />
        </div>
        <div>
          <label>Arabic Name: </label>
          <Input
            value={editedRecord?.arabic_name}
            onChange={(e) => handleEditInputChange('arabic_name', e.target.value)}
          />
        </div>
        <div>
          <label>City: </label>
          <Select
            value={editedRecord?.city}
            onChange={(value) => handleEditInputChange('city', value)}
            dropdownStyle={{ minWidth: '200px' }}
          >
            {cities.map((city) => (
              <Option key={city.id} value={city.id}>
                {city.city}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <label>Address: </label>
          <Input
            value={editedRecord?.address}
            onChange={(e) => handleEditInputChange('address', e.target.value)}
          />
        </div>
        <div>
          <label>Description: </label>
          <Input
            value={editedRecord?.description}
            onChange={(e) => handleEditInputChange('description', e.target.value)}
          />
        </div>
        <div>
          <label>phone_num No: </label>
          <Input
            value={editedRecord?.phone_num}
            onChange={(e) => handleEditInputChange('phone_num', e.target.value)}
          />
        </div>
        <div>
          <label>Mobile No: </label>
          <Input
            value={editedRecord?.mobile}
            onChange={(e) => handleEditInputChange('mobile', e.target.value)}
          />
        </div>
        <div>
          <label>Email: </label>
          <Input
            value={editedRecord?.email}
            onChange={(e) => handleEditInputChange('email', e.target.value)}
          />
        </div>
        <div>
          <label>Contact Person: </label>
          <Input
            value={editedRecord?.contact_person}
            onChange={(e) => handleEditInputChange('contact_person', e.target.value)}
          />
        </div>
        <div>
          <label>Contact Person Name: </label>
          <Input
            value={editedRecord?.contact_person_name}
            onChange={(e) => handleEditInputChange('contact_person_name', e.target.value)}
          />
        </div>
        <div>
          <label>TRN NO: </label>
          <Input
            value={editedRecord?.trn}
            onChange={(e) => handleEditInputChange('trn', e.target.value)}
          />
        </div>
        <div>
          <label>Credit Limit: </label>
          <Input
            value={editedRecord?.credit_limit}
            onChange={(e) => handleEditInputChange('credit_limit', e.target.value)}
          />
        </div>
        <div>
          <label>Credit Days: </label>
          <Input
            value={editedRecord?.credit_days}
            onChange={(e) => handleEditInputChange('credit_days', e.target.value)}
          />
        </div>
        <div>
          <label>VAT: </label>
          <Select
            value={editedRecord?.vat_type}
            onChange={(value) => handleEditInputChange('vat_type', value)}
            dropdownStyle={{ minWidth: '200px' }}
          >
            {vats.map((vat) => (
              <Option key={vat.id} value={vat.id}>
                {vat.name}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <label>Fax Number: </label>
          <Input
            value={editedRecord?.fax_num}
            onChange={(e) => handleEditInputChange('fax_num', e.target.value)}
          />
        </div>
        <div>
          <label>License Number: </label>
          <Input
            value={editedRecord?.license_num}
            onChange={(e) => handleEditInputChange('license_num', e.target.value)}
          />
        </div>
        <div>
          <label>License Expiry Date: </label>
          <DatePicker
            value={editedRecord?.license_expiry}
            onChange={(value) => handleEditInputChange('license_expiry', value)}
          />
        </div>
        {/* ... Add more fields as needed */}
      </div>
    );
  };
  
  const columns = [
    {
      title: 'Code',
      dataIndex: 'key',
      key: 'key',
      width: '30%',
      ...getColumnSearchProps('key'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Arabic Name',
      dataIndex: 'arabic_name',
      key: 'arabic_name',
      width: '30%',
      ...getColumnSearchProps('arabic_name'),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: '30%',
      ...getColumnSearchProps('city'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '30%',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Phone No',
      dataIndex: 'phone_num',
      key: 'phone_num',
      width: '30%',
      ...getColumnSearchProps('phone_num'),
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobile_num',
      key: 'mobile_num',
      width: '30%',
      ...getColumnSearchProps('mobile_num'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Contact Person',
      dataIndex: 'contact_person',
      key: 'contact_person',
      width: '30%',
      ...getColumnSearchProps('contact_person'),
    },
    {
      title: 'Contact Person Name',
      dataIndex: 'contact_person_name',
      key: 'contact_person_name',
      width: '30%',
      ...getColumnSearchProps('contact_person_name'),
    },
    {
      title: 'TRN NO',
      dataIndex: 'trn',
      key: 'trn',
      width: '30%',
      ...getColumnSearchProps('trn'),
    },
    {
      title: 'Credit Limit',
      dataIndex: 'credit_limit',
      key: 'credit_limit',
      width: '30%',
      ...getColumnSearchProps('credit_limit'),
    },
    {
      title: 'Credit Days',
      dataIndex: 'credit_days',
      key: 'credit_days',
      width: '30%',
      ...getColumnSearchProps('credit_days'),
    },
    
    // {
    //   title: 'Vat',
    //   dataIndex: 'vat_type',
    //   key: 'vat_type',
    //   width: '30%',
    //   ...getColumnSearchProps('vat_type'),
    // },
    {
      title: 'Fax Number',
      dataIndex: 'fax_num',
      key: 'fax_num',
      width: '30%',
      ...getColumnSearchProps('fax_num'),
    },
    {
      title: 'License Number',
      dataIndex: 'license_num',
      key: 'license_num',
      width: '30%',
      ...getColumnSearchProps('license_num'),
    },
    {
      title: 'License Expiry Date',
      dataIndex: 'license_expiry',
      key: 'license_expiry',
      width: '30%',
      ...getColumnSearchProps('license_expiry'),
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            {/* <EyeOutlined
              onClick={() => handleView(record)}
              style={{ color: 'blue', marginRight: 8 }}
            /> */}
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },
  
  ];
  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };


  return (
    <Spin spinning={submitLoading}>
    <>
    <div style={{ width: '1000px' }}>
      <h1><b>Your Information</b> </h1>
      <br></br>
      <Form
        form={form}
        name="company_form"
        onFinish={handleAdd}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        {/* Basic Information */}
        <Row gutter={8}>
          <Col span={8}>
        <Form.Item name="name" rules={[{ required: true, message: 'Please enter the company name' }]}>
          <Input placeholder="Name" />
        </Form.Item>
        </Col>
        <Col span={8}>
        <Form.Item name="arabic_name" rules={[{ required: true, message: 'Please enter the company name' }]}>
          <Input placeholder="Name in Arabic" />
        </Form.Item>
        </Col>
        <Col span={8}>
        <Form.Item name="city">
          <Select placeholder="City">
                  {
                    cities.map((city) => (
                      <Option value={city.id}>{city.city}</Option>
                    ))
              }
            </Select>
        </Form.Item>
        </Col>
        </Row>

        <Row gutter={8}>
  <Col span={8}>
    <Form.Item name="address">
      <Input.TextArea placeholder="Address" rows={4} />
    </Form.Item>
  </Col>

  <Col span={8}>
    <Form.Item name="description">
      <Input.TextArea placeholder="Description" rows={4} />
    </Form.Item>
  </Col>
</Row>



        {/* Contact Information */}
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="phone_num">
              <Input type="tel" placeholder="phone_num Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="mobile_num">
              <Input type="tel" placeholder="mobile_num Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="email">
              <Input type="email" placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="contact_person">
              <Input placeholder="Contact Person" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="contact_person_name" >
              <Input placeholder="Contact Person Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="trn">
              <Input placeholder="TRN No." />
            </Form.Item>
          </Col>
        </Row>

        {/* Financial Information */}
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="credit_limit" >
              <Input type="number" placeholder="Credit Limit" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="credit_days">
              <Input type="number" placeholder="Credit Days" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="fax_num">
              <Input type="tel" placeholder="Fax Number" />
            </Form.Item>
          </Col>
        </Row>

        {/* Additional Information */}
        <Row gutter={8}>
        
          <Col span={8}>
            <Form.Item name="license_num">
              <Input placeholder="License Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="license_expiry">
              <DatePicker placeholder="License Expiry Date" />
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <Form.Item >
            <Button type="primary" htmlType="submit" style={{ background: 'white', color: 'blue' }}>
              <CheckOutlined />
              Submit
            </Button>
          </Form.Item>
      </Form>
      <div>
        <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <Button type="primary" onClick={handleExportToExcel} style={{ background: 'white', color: 'blue' }}>
          <FileExcelOutlined /> Export to Excel
        </Button>
        
      </div>
    </div>
    <br>
    </br>
    <div style={{ overflowX: 'auto' }}>
    <Table id='table-to-export' columns={columns} dataSource={data} />
    <Modal
      title={`Delete Record - ${recordToDelete ? recordToDelete.name : ''}`}
      visible={deleteModalVisible}
      onOk={handleDeleteConfirm}
      onCancel={handleDeleteCancel}
    >
      <p>Are you sure you want to delete this record?</p>
    </Modal>
    <Modal
      title={`Edit Record - ${recordToEdit ? recordToEdit.name : ''}`}
      visible={editModalVisible}
      onOk={handleEditConfirm}
      onCancel={handleEditCancel}
    >
      {renderEditForm()}
    </Modal>
    <Modal
      title={`View Record - ${recordToView ? recordToView.name : ''}`}
      visible={viewModalVisible}
      onOk={handleViewConfirm}
      onCancel={handleViewCancel}
    >
      {/* ... (existing code) */}
    </Modal>
  </div>
  </>
  </Spin>
  );
};

export default CompanyForm;
