import { useState , useEffect} from "react";
import { Menu, Button ,Form}  from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";

import dashboard from "../../assets/images/dashboard.svg"
import Masters from "../../assets/images/master.svg"
import purchase from "../../assets/images/purchase.svg"
import sale from "../../assets/images/sale.svg"
import bv from "../../assets/images/bv.svg"
import pv from "../../assets/images/pv.svg"
import rv from "../../assets/images/rv.svg"
import stock from "../../assets/images/stock.svg"
import pm from "../../assets/images/pm.svg"

import supabase from "../../helper/supabaseClient";

const { SubMenu } = Menu;

function Sidenav({ color }) {
  const { pathname } = useLocation();
  

  const [hostedUrl, setHostedUrl] = useState('');
  console.log("hostes is "+hostedUrl);


  const [headerLogoShow, setHeaderLogoShow] = useState('')

const fetchTwilioConfigData = async () => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    const userId = user.id;

    const { data: siteSettingsData, error: siteSettingsError } = await supabase
      .from('Site_Settings')
      .select('Hosted_URL')
      .eq('user_id', userId)
      .order('id', { ascending: false })
      .limit(1);

    if (siteSettingsError) {
      console.error('Error fetching Site Settings data:', siteSettingsError.message);
    } else {
      // Update the form fields with Site Settings data
      const hostedUrl = siteSettingsData[0]?.Hosted_URL || '';
      console.log('bsdk',hostedUrl);
      setHostedUrl(hostedUrl);
    }

    // check if user exists or not
    const { data: existingData, error: selectError } = await supabase
    .from('logo_images')
    .select('nav_logo')
    .eq('user_id', userId);

    if(selectError){
      console.error('Error fetching Site Settings data:', selectError.message);
    }else if (existingData && existingData.length > 0){
      setHeaderLogoShow(existingData[0].nav_logo)
    }

  } catch (error) {
    console.error('Error in fetchTwilioConfigData:', error.message);
  }
};

useEffect(async () => {
  // Fetch Site Settings data when the component mounts
 await fetchTwilioConfigData();
}, []);

  

  const page = pathname.replace("/", "");

  // const Masters = [
  //   <svg
  //     width="20"
  //     height="20"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={0}
  //     style={{ marginBottom: '-5px' }}
  //   >
  //     <path
  //       d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
  //       fill={color}
  //     ></path>
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
  //       fill={color}
  //     ></path>
  //   </svg>,
  // ];

  const form = Form.useForm();

  const [mastersConfig, setMastersConfig] = useState({
    Country: true,
    City: true,
    Unit: true,
    Vat: true,
    Currency: true,
    CurrencyConversion: true,
    VoucherDocs: true,
    ProductColor: true,
    ProductMaster: true,
  });
 
  const [sidenavVisibility, setSidenavVisibility] = useState({ ...mastersConfig });
  const [activePages, setActivePages] = useState([]);
  // const fetchMastersConfigData = async () => {
  //   try {
  //     const { data: { user } } = await supabase.auth.getUser();
  //     const userId = user.id;
  
  //     // Fetch the Masters configuration data for the specific user, order by id in descending order and limit to 1 row
  //     const { data: mastersData, error: mastersError } = await supabase
  //       .from('Masters')
  //       .select('*')
  //       .eq('user_id', userId)
  //       .order('id', { ascending: false })
  //       .limit(1)
  //       .single();
  //       console.log('Data is:',mastersData);  
  //       // alert('Data is:',mastersData); 
  //     if (mastersError) {
  //       console.error('Error fetching Masters data:', mastersError.message);
  //     } else {
  //       // Update the local state with Masters configuration data
  //       setMastersConfig({
  //         Country: mastersData?.Country || false,
  //         City: mastersData?.City || false,
  //         Unit: mastersData?.Unit || false,
  //         Vat: mastersData?.Vat || false,
  //         Currency: mastersData?.Currency || false,
  //         CurrencyConversion: mastersData?.CurrencyConversion || false,
  //         VoucherDocs: mastersData?.VoucherDocs || false,
  //         ProductColor: mastersData?.ProductColor || false,
  //         ProductMaster: mastersData?.ProductMaster || false,
  //       });
  //       const activePages = Object.keys(mastersData)
  //       .filter((key) => mastersData[key] && key !== 'id' && key !== 'user_id');
  //     setActivePages(activePages);
  //       // Set the initial values for the form fields
  //       form.setFieldsValue({
  //         Country: mastersData?.Country || false,
  //         City: mastersData?.City || false,
  //         Unit: mastersData?.Unit || false,
  //         Vat: mastersData?.Vat || false,
  //         Currency: mastersData?.Currency || false,
  //         CurrencyConversion: mastersData?.CurrencyConversion || false,
  //         VoucherDocs: mastersData?.VoucherDocs || false,
  //         ProductColor: mastersData?.ProductColor || false,
  //         ProductMaster: mastersData?.ProductMaster || false,
  //       });


        
  //     }
  //   } catch (error) {
  //     console.error('Error in fetchMastersConfigData:', error.message);
  //   }
  // };
  
  

  const handleCheckboxChange = (page) => {
    setMastersConfig((prevConfig) => ({
      ...prevConfig,
      [page]: !prevConfig[page],
    }));


     // Update visibility in the sidebar immediately
  setSidenavVisibility((prevVisibility) => ({
    ...prevVisibility,
    [page]: !prevVisibility[page],
  }));
  };

  // useEffect(() => {
  //   // Fetch Masters Configuration data when the component mounts
  //   fetchMastersConfigData();

  // }, [fetchMastersConfigData]);




  return (
    <>
      <div className="brand">
      {/* {hostedUrl? <img src={hostedUrl} alt="User Logo" style={{ width: '50px', height: '50px', marginBottom: '20px' }} />:<img src={logo} alt="User Logo" style={{ width: '50px', height: '50px', marginBottom: '20px' }} />} */}
      {headerLogoShow? <img src={headerLogoShow} alt="User Logo" style={{ width: '80px', height: '80px', marginBottom: '15px' }} />:<img src={logo} alt="User Logo" style={{ width: '80px', height: '80px', marginBottom: '15px' }} />}
      <span></span>
    </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1" className="dbord">
          <NavLink to="/dashboard">
            {/* <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span> */}
            {/* <img
        src={dashboard}
        alt="Master SVG"
        style={{ width: '35px', height: '35px', marginBottom: '-5px',marginRight:'5px' }}
      /> */}
            <span className="label dashbord">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.SubMenu key="4-11" title={<><img
        src={Masters}
        alt="Master SVG"
        style={{ width: '35px', height: '35px', marginBottom: '-13px' }}
      /> Masters </>}>
          

        <Menu.SubMenu key="4-2" title="General Masters"  popupClassName="right-submenu">
          {/* <Menu.Item key="4-2"> */}
          {/* {activePages.map((page) => (
  <Menu.Item key={page}>
    <NavLink to={`/${page}`}>
      <span className="label">{page}</span>
    </NavLink>
  </Menu.Item>
))} */}

           
          {/* </Menu.Item> */}
          <Menu.Item key="4-2">
            <NavLink to="/Country">
              <span className="label">Country</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/City">
              <span className="label">City</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/Unit">
              
              <span className="label">Unit</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/Vat">
              
              <span className="label">Vat</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/Currency">
             
              <span className="label">Currency</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/CurrencyConversion">
           
              <span className="label">Currency Convertion</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/VoucherDocs">
              
              <span className="label">Voucher Docs</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/Productcolor">
             
              <span className="label">Product color</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/ProductMaster">
             
              <span className="label">Product Master</span>
            </NavLink>
          </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item key="4-2">
          <NavLink to="/Master"> 
              <span className="label cot">Master</span>
            </NavLink>
          </Menu.Item>
          <Menu.SubMenu key="4-111" title="Add Account">
          <Menu.Item key="4-2">
            <NavLink to="/Employees">
              {/* <span
                className="icon"
                style={{
                  background: page === "/Employees" ? color : "",
                }}
              >
                {addaccount}
              </span> */}
              <span className="label">Employees</span>
            </NavLink>
          </Menu.Item>
         
          <Menu.Item key="4-2">
            <NavLink to="/Bank">
              {/* <span
                className="icon"
                style={{
                  background: page === "/Employees" ? color : "",
                }}
              >
                {addaccount}
              </span> */}
              <span className="label">Bank</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="4-2">
            <NavLink to="/Customers">
              {/* <span
                className="icon"
                style={{
                  background: page === "/Customers" ? color : "",
                }}
              >
                {addaccount}
              </span> */}
              <span className="label">Customers</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/Vendors">
              {/* <span
                className="icon"
                style={{
                  background: page === "/Vendors" ? color : "",
                }}
              >
                {addaccount}
              </span> */}
              <span className="label">Vendors</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/Expenses">
              {/* <span
                className="icon"
                style={{
                  background: page === "/Expenses" ? color : "",
                }}
              >
                {addaccount}
              </span> */}
              <span className="label">Expenses</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/Customerupload">
              {/* <span
               
                style={{
                  background: page === "/Customer upload" ? color : "",
                }}
              >
                {Masters}
              </span> */}
              <span className="label">Customer upload</span>
            </NavLink>
          </Menu.Item>
          </Menu.SubMenu>
          {/* //<Menu.SubMenu key="4-1111" title="Account Types">          */}
          <Menu.Item key="4-2">
            <NavLink to="/Accounttypes">
              {/* <span
                className="icon"
                style={{
                  background: page === "/Creditors" ? color : "",
                }}
              >
                {accounttypes}
              </span> */}
              <span className="label">Account Types</span>
            </NavLink>
          </Menu.Item>
          
          <Menu.Item key="4-2">
            <NavLink to="/Chartofaccount">
            
              <span className="label cot">Chart OF Account</span>
            </NavLink>
          </Menu.Item>
       
          <Menu.SubMenu key="4-11112" title="Products Master">       
          <Menu.Item key="4-2">
            <NavLink to="/Products">
              {/* <span
                className="icon"
                style={{
                  background: page === "/products" ? color : "",
                }}
              >
                {PT}
              </span> */}
              <span className="label">Products</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/Productslist">
              
              <span className="label">Products List</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/ProductUpload">
              {/* <span
                className="icon"
                style={{
                  background: page === "/Product Upload" ? color : "",
                }}
              >
                {PT}
              </span> */}
              <span className="label">Product Upload</span>
            </NavLink>
          </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="4-11113" title="Assets Master">           
          <Menu.Item key="4-2">
            <NavLink to="/Assetlist">
              
              <span className="label">Asset list</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-2">
            <NavLink to="/AssetsT">
              {/* <span
                className="icon"
                style={{
                  background: page === "/AssetsT" ? color : "",
                }}
              >
                {AT}
              </span> */}
              <span className="label">Assets Tree</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="4-2">
            <NavLink to="/Assets">
              {/* <span
                className="icon"
                style={{
                  background: page === "/Assets" ? color : "",
                }}
              >
                {AT}
              </span> */}
              <span className="label">Assets Upload</span>
            </NavLink>
          </Menu.Item>
          

          </Menu.SubMenu>
          <Menu.SubMenu key="4-11114" title="Services Master">   
          <Menu.Item key="4-2">
            <NavLink to="/Servicelist">
              
              <span className="label">Service List</span>
            </NavLink>
          </Menu.Item>
          
       
          <Menu.Item key="4-2">
            <NavLink to="/Services">
              {/* <span
                className="icon"
                style={{
                  background: page === "/Services" ? color : "",
                }}
              >
                {ST}
              </span> */}
              <span className="label">Services Upload</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="4-2">
            <NavLink to="/ServicesT">
              {/* <span
                className="icon"
                style={{
                  background: page === "/ServicesT" ? color : "",
                }}
              >
                {ST}
              </span> */}
              <span className="label">Services Tree</span>
            </NavLink>
          </Menu.Item>
          </Menu.SubMenu>
        </Menu.SubMenu>

        <SubMenu key="411" title={<>  <img
        src={purchase}
        alt="Master SVG"
        style={{ width: '25px', height: '25px', marginBottom: '-5px',marginRight:'15px',marginLeft:'5px' }}
      />Purchase </>}>
          <Menu.Item key="4-11">
            <NavLink to="/PurchaseRequest">
              {/* <span
                className="icon"
                style={{
                  background: page === "/PurchaseRequest" ? color : "",
                }}
              >
                {purchase}
              </span> */}
              <span className="label">Purchase Request</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/PurchaseQuotation">
              {/* <span
                className="icon"
                style={{
                  background: page === "/PurchaseQuotation" ? color : "",
                }}
              >
                {purchase}
              </span> */}
              <span className="label">Purchase Quotation</span>
            </NavLink>
          </Menu.Item>
          
          <Menu.Item key="4-11">
            <NavLink to="/PurchaseOrder">
              {/* <span
                className="icon"
                style={{
                  background: page === "/PurchaseOrder" ? color : "",
                }}
              >
                {purchase}
              </span> */}
              <span className="label">Purchase Order</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/PurchaseReceived">
              {/* <span
                className="icon"
                style={{
                  background: page === "/PurchaseReceived" ? color : "",
                }}
              >
                {purchase}
              </span> */}
              <span className="label">Purchase Received</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/PurchaseInvoice">
              {/* <span
                className="icon"
                style={{
                  background: page === "/PurchaseInvoice" ? color : "",
                }}
              >
                {purchase}
              </span> */}
              <span className="label">PurchaseInvoice</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/PurchaseReturn">
              {/* <span
                className="icon"
                style={{
                  background: page === "/PurchaseReturn" ? color : "",
                }}
              >
                {purchase}
              </span> */}
              <span className="label">PurchaseReturn</span>
            </NavLink>
          </Menu.Item>
          </SubMenu>
          <SubMenu key="4111" title={<><img
        src={sale}
        alt="Master SVG"
        style={{ width: '25px', height: '25px', marginBottom: '-5px',marginRight:'15px',marginLeft:'5px' }}
      />Sales</>}>
          <Menu.Item key="4-11">
            <NavLink to="/SalesEnquiry">
              {/* <span
                className="icon"
                style={{
                  background: page === "/SalesEnquiry" ? color : "",
                }}
              >
                {sales}
              </span> */}
              <span className="label">SalesEnquiry</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/SalesQuotations">
              {/* <span
                className="icon"
                style={{
                  background: page === "/SalesQuotations" ? color : "",
                }}
              >
                {sales}
              </span> */}
              <span className="label">SalesQuotations</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/SalesOrder">
              {/* <span
                className="icon"
                style={{
                  background: page === "/SalesOrder" ? color : "",
                }}
              >
                {sales}
              </span> */}
              <span className="label">SalesOrder</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/SalesNote">
              {/* <span
                className="icon"
                style={{
                  background: page === "/SalesNote" ? color : "",
                }}
              >
                {sales}
              </span> */}
              <span className="label">SalesNote</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/SalesInvoice">
              {/* <span
                className="icon"
                style={{
                  background: page === "/SalesInvoice" ? color : "",
                }}
              >
                {sales}
              </span> */}
              <span className="label">SalesInvoice</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/SalesReturn">
              {/* <span
                className="icon"
                style={{
                  background: page === "/SalesReturn" ? color : "",
                }}
              >
                {sales}
              </span> */}
              <span className="label">Sales Return</span>
            </NavLink>
          {/* </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/AlRwanFoodstuffTrading">
              <span
                className="icon"
                style={{
                  background: page === "/AlRwanFoodstuffTrading" ? color : "",
                }}
              >
                {sales}
              </span>
              <span className="label">Al Rwan Food stuff Trading</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/AlWakeelAlmumyazTechnicalsevices">
              <span
                className="icon"
                style={{
                  background: page === "/AlwakeelAlmumyazTechnicalservices" ? color : "",
                }}
              >
                {sales}
              </span>
              <span className="label">Al wakeel Almumyaz Technical services</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/ManarahAlShamVegetableAndFruitTradingLLC">
              <span
                className="icon"
                style={{
                  background: page === "/ManarahAlShamVegetableAndFruitTradingLLC" ? color : "",
                }}
              >
                {sales}
              </span>
              <span className="label">Manarah Al Sham Vegetable And Fruit Trading LLC</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/ManaratHoranFoodstuffTradingLLC">
              <span
                className="icon"
                style={{
                  background: page === "/ManaratHoranFoodstuffTradingLLC" ? color : "",
                }}
              >
                {sales}
              </span>
              <span className="label">Manarat Horan Foodstuff Trading LLC</span>
            </NavLink>
          </Menu.Item>  
          <Menu.Item key="4-11"> */}
            {/* <NavLink to="/OscarVegetableandFruitsLLC">
              <span
                className="icon"
                style={{
                  background: page === "/OscarVegetableandFruitsLLC" ? color : "",
                }}
              >
                {sales}
              </span>
              <span className="label">Oscar Vegetable and Fruits LLC</span>
            </NavLink> */}
          </Menu.Item> 
          </SubMenu>
          <SubMenu key="41111" title={<><img
        src={bv}
        alt="Master SVG"
        style={{ width: '20px', height: '20px', marginBottom: '-5px',marginRight:'15px',marginLeft:'11px' }}
      />Basic Vouchers</>}>
          <Menu.Item key="4-11">
            <NavLink to="/OpeningBalance">
              {/* <span
                className="icon"
                style={{
                  background: page === "/OpeningBalance" ? color : "",
                }}
              >
                {basicvouchers}
              </span> */}
              <span className="label">Opening Balance</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/JournalVoucher">
              {/* <span
                className="icon"
                style={{
                  background: page === "/JournalVoucher" ? color : "",
                }}
              >
                {basicvouchers}
              </span> */}
              <span className="label">Journal Voucher</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/CompanyExpense">
              {/* <span
                className="icon"
                style={{
                  background: page === "/CompanyExpense " ? color : "",
                }}
              >
                {basicvouchers}
              </span> */}
              <span className="label">Company Expense</span>
            </NavLink>
          </Menu.Item>
          </SubMenu>
          <SubMenu key="411111" title={<><img
        src={pv}
        alt="Master SVG"
        style={{ width: '20px', height: '20px', marginBottom: '-5px',marginRight:'9px',marginLeft:'12px' }}
      />Payment Vouchers</>}>
          <Menu.Item key="4-11">
            <NavLink to="/CashPayment">
              {/* <span
                className="icon"
                style={{
                  background: page === "/CashPayment" ? color : "",
                }}
              >
                {Paymentvouchers}
              </span> */}
              <span className="label">Cash Payment</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/FundPayment">
              {/* <span
                className="icon"
                style={{
                  background: page === "/FundPayment" ? color : "",
                }}
              >
                {Paymentvouchers}
              </span> */}
              <span className="label">Fund Payment</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/ChequePayment">
              {/* <span
                className="icon"
                style={{
                  background: page === "/ChequePayment" ? color : "",
                }}
              >
                {Paymentvouchers}
              </span> */}
              <span className="label">Cheque Payment</span>
            </NavLink>
          </Menu.Item>
          </SubMenu>
          <SubMenu key="4111111" title={<><img
        src={rv}
        alt="Master SVG"
        style={{ width: '20px', height: '20px', marginBottom: '-5px',marginRight:'11px',marginLeft:'11px' }}
      />Receipt Vouchers</>}>
          <Menu.Item key="4-11">
            <NavLink to="/CashReceipt">
              {/* <span
                className="icon"
                style={{
                  background: page === "/CashReceipt" ? color : "",
                }}
              >
                {receiptvouchers}
              </span> */}
              <span className="label">Cash Receipt</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/FundReceipt">
              {/* <span
                className="icon"
                style={{
                  background: page === "/FundReceipt" ? color : "",
                }}
              >
                {receiptvouchers}
              </span> */}
              <span className="label">Fund Receipt</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/ChequeReceipt">
              {/* <span
                className="icon"
                style={{
                  background: page === "/ChequeReceipt" ? color : "",
                }}
              >
                {receiptvouchers}
              </span> */}
              <span className="label">Cheque Receipt</span>
            </NavLink>
          </Menu.Item>
          </SubMenu>

          <SubMenu key="41221111" title={<><img
        src={stock}
        alt="Master SVG"
        style={{ width: '20px', height: '20px', marginBottom: '-5px',marginRight:'15px',marginLeft:'11px' }}
      />Stocks</>}>
          {/* <Menu.Item key="4-11">
            <NavLink to="/openingStock">
           
              <span className="label">Opening Stock</span>
            </NavLink>
          </Menu.Item> */}
          <Menu.Item key="411">
            <NavLink to="/StockIssue">
              <span className="label">Stock Issue</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-1661">
            <NavLink to="/StockReceive">
              <span className="label">Stock Receive</span>
            </NavLink>
          </Menu.Item>
          </SubMenu>

          <SubMenu key="41111111" title={<><img
        src={pm}
        alt="Master SVG"
        style={{ width: '20px', height: '20px', marginBottom: '-5px',marginRight:'5px',marginLeft:'11px' }}
      />PDC Management</>}>
          <Menu.Item key="4-11">
            <NavLink to="/pendingpdcs">
              {/* <span
                className="icon"
                style={{
                  background: page === "/pendingpdcs" ? color : "",
                }}
              >
                {pdcmanagement}
              </span> */}
              <span className="label">Pending PDC's</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/processedpdcs">
              {/* <span
                className="icon"
                style={{
                  background: page === "/processedpdcs" ? color : "",
                }}
              >
                {pdcmanagement}
              </span> */}
              <span className="label">Processed PDC's</span>
            </NavLink>
          </Menu.Item>
          
          </SubMenu>
         
          <SubMenu key="41109" title={<><img
        src={bv}
        alt="Master SVG"
        style={{ width: '20px', height: '20px', marginBottom: '-5px',marginRight:'15px',marginLeft:'11px' }}
      />Reports</>}>
          {/* <Menu.Item key="4-11">
            <NavLink to="/StatementofAccount">
              
              <span className="label">Statement of Account</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4-11">
            <NavLink to="/TrialBalance">
              <span className="label">Trial Balance</span>
            </NavLink>
          </Menu.Item> */}
          
          <Menu.Item key="4-11">
            <NavLink to="/ProfitLoss">
              <span className="label">Profit & Loss</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="4-12">
            <NavLink to="/BalanceSheet">
              <span className="label">Balance Sheet</span>
            </NavLink>
          </Menu.Item>

          </SubMenu>

      
      </Menu>
      
      
    </>
  );
}

export default Sidenav;