import React, { useState,useRef } from 'react';
import { Form, Input, Button, select,Spin } from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { useEffect } from 'react';
import { message } from 'antd';
import { DatePicker } from 'antd';


const App = () => {
  const [date, setDate] = useState(null);
  const [remarks, setRemarks] = useState('');
    
  // const [data, setData] = useState([
  //   {
  //     key: '1',
  //     accountcode: '1',
  //     accountname: 'fffy',
  //     creditamount: 'sadii',
  //     debitamount: 'address',
  //     remarks: 'pach pach',
     
  //   },
  //   {
  //     key: '2',
  //     accountcode: '1',
  //     accountname: 'fffy',
  //     creditamount: 'sadii',
  //     debitamount: 'address',
  //     remarks: 'pach pach',
  //   },
  //   {
  //     key: '3',
  //     accountcode: '1',
  //     accountname: 'fffy',
  //     creditamount: 'sadii',
  //     debitamount: 'address',
  //     remarks: 'pach pach',
  //   },
  //   {
  //     key: '4',
  //     accountcode: '1',
  //     accountname: 'fffy',
  //     creditamount: 'sadii',
  //     debitamount: 'address',
  //     remarks: 'pach pach',
  //   },
  // ]);

  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...editedRecord } : record
    );
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    setData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Code: </label>
          <Input
            value={editedRecord?.code}
            onChange={(e) => handleEditInputChange('code', e.target.value)}
          />
        </div>
        <div>
          <label>Name: </label>
          <Input
            value={editedRecord?.name}
            onChange={(e) => handleEditInputChange('name', e.target.value)}
          />
        </div>
        <div>
          <label>Name in Arabic: </label>
          <Input
            value={editedRecord?.name_ara}
            onChange={(e) => handleEditInputChange('name_ara', e.target.value)}
          />
        </div>
        <div>
          <label>Description: </label>
          <Input
            value={editedRecord?.descrip}
            onChange={(e) => handleEditInputChange('descrip', e.target.value)}
          />
        </div>
        
      
        {/* ... Add more fields as needed */}
      </div>
    );
  };

  const columns = [
    {
    title: 'Acount Code',
      dataIndex: 'accountcode',
      key: 'accountcode',
      width: '30%',
      ...getColumnSearchProps('accountcode'),
    },
    {
      title: 'Account Name',
      dataIndex: 'accountname',
      key: 'accountname',
      width: '30%',
      ...getColumnSearchProps('accountname'),
    },
    {
      title: 'Credit Amount',
      dataIndex: 'creditamount',
      key: 'creditamoun',
      width: '30%',
      ...getColumnSearchProps('creditamount'),
    },
    {
      title: 'Debit Amount',
      dataIndex: 'debitamount',
      key: 'debitamount',
      width: '30%',
      ...getColumnSearchProps('debitamount'),
    },

    {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        width: '30%',
        ...getColumnSearchProps('remarks'),
      },
 
   
  
  ];

  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const [form] = Form.useForm(); // Add this line to create a form instance

  const onFinish = (values) => {
    // Handle form submission here
    console.log('Received values:', values);
  };

  return (
    <>
    {/* <div style={{ backgroundColor: 'yellow', padding: '10px', textAlign: 'center' }}>
      <strong>Under Construction:</strong> This page is currently under construction.
    </div> */}
    <Form form={form} onFinish={onFinish}>
            <div style={{ width: 600 }}>
              <Form.Item name="date"  rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <DatePicker placeholder="Date" style={{ width: '30%' }} />
              </Form.Item>
              <Form.Item name="remarks" rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <Input placeholder="Remarks" style={{ width: '80%' }} />
              </Form.Item>
              </div>
              <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>

              
        </Form>
              
              <br></br>




    <div style={{ overflowX: 'auto' }}>
      <Table id='table-to-export' columns={columns} dataSource={data} />
      <Modal
        title={`Delete Record - ${recordToDelete ? recordToDelete.name : ''}`}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={`Edit Record - ${recordToEdit ? recordToEdit.name : ''}`}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
        {renderEditForm()}
      </Modal>
      <Modal
        title={`View Record - ${recordToView ? recordToView.name : ''}`}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >
        {/* ... (existing code) */}
      </Modal>
    </div>
    </>
  );
};

export default App;
