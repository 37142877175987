import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table,Modal, Popconfirm, message } from 'antd';
import supabase from "../helper/supabaseClient.js";
import { CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Master = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const fetchData = async () => {
      const { data: { user } } = await supabase.auth.getUser();

      const userId = user.id;

        const { data: fetchData, error } = await supabase
          .from('master')
          .select('*').eq('user',userId);
    
        if (error) {
          console.error('Error fetching data from Supabase:', error);
        } else {
          setData(fetchData || []);
        }
      };
    
      const onFinish = async (values) => {
        setLoading(true);
        try {
          // Add to Supabase table and return the inserted data, including the ID
          const { data: insertedData, error } = await supabase
            .from('master')
            .upsert([{ name: values.name }], { returning: 'representation' });
    
          if (error) {
            console.error('Error inserting data into Supabase:', error);
          } else {
            console.log('Data inserted into Supabase:', insertedData);
    
            // Update local state with the inserted data, including the ID
            const newData = { ...values, id: insertedData };
            setData([...data, newData]);
            message.success('Record added successfully');
          }
        } finally {
          setLoading(false);
          await form.resetFields(); // Reset the form after data is updated
          fetchData(); // Fetch updated data and re-render the table
        }
      };
    
      
      
      

  const onDelete = async (record) => {
    setLoading(true);
    try {
      // Delete from Supabase table
      const { data: deletedData, error } = await supabase
        .from('master')
        .delete()
        .eq('id', record.id);

      if (error) {
        console.error('Error deleting data from Supabase:', error);
      } else {
        console.log('Data deleted from Supabase:', deletedData);

        // Update local state after deletion
        const updatedData = data.filter((item) => item.id !== record.id);
        setData(updatedData);
        message.success('Record deleted successfully');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  const handleEditModalOk = async (editedValues) => {
    setLoading(true);
    try {
      // Update Supabase table
      const { data: updatedData, error } = await supabase
        .from('master')

        .update(editedValues)
        .eq('id', selectedRecord.id);

      if (error) {
        console.error('Error updating data in Supabase:', error);
      } else {
        console.log('Data updated in Supabase:', updatedData);

        // Update local state after edit
        const updatedLocalData = data.map((item) =>
          item.id === selectedRecord.id ? { ...item, ...editedValues } : item
        );
        setData(updatedLocalData);
        message.success('Record updated successfully');
      }
    } finally {
      setLoading(false);
      setIsModalVisible(false);
      setSelectedRecord(null);
    }
  };
  const columns = [
    {
      title: 'Master Code',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            type="link"
            
            onClick={() => handleEdit(record)}
          >
            <EditOutlined />

          </Button>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" >
            <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  

  useEffect(() => {
    const fetchData = async () => {
      const { data: { user } } = await supabase.auth.getUser();

      const userId = user.id;

      const { data: fetchData, error } = await supabase
        .from('master')
        .select('*').eq('user',userId);

      if (error) {
        console.error('Error fetching data from Supabase:', error);
      } else {
        setData(fetchData || []);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Form form={form} onFinish={onFinish} style={{ marginBottom: 16 }}>
        <Form.Item
          name="name"
          style={{ width: '30%' }}
          rules={[{ required: true, message: 'Please enter a name!' }]}
        >
          <Input placeholder="General Master Name" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ background: 'white', color: 'blue' }}
          >
            <CheckOutlined /> Submit
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Table dataSource={data} columns={columns} />
      <Modal
        title="Edit Record"
        visible={isModalVisible}
        onCancel={handleEditModalCancel}
        onOk={form.submit}
        confirmLoading={loading}
      >
        <Form form={form} onFinish={handleEditModalOk} initialValues={selectedRecord}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter a name!' }]}>
            <Input placeholder="General Master Name" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Master;