import React, { useState, useEffect } from 'react';
import { Tree, Modal, Input, notification, Select, Popover, Badge, Tooltip, Spin } from 'antd';
import { PlusCircleTwoTone, MinusCircleTwoTone, FolderOpenTwoTone, CodeTwoTone } from '@ant-design/icons';
import DottedBarSVG from '../assets/images/dotted-bar.svg';
import DottedBariSV from '../assets/images/dotted-bar2.svg';
import 'antd/dist/antd.css';
import supabase from "../helper/supabaseClient.js";
import ProductsList from './Productslist'; 

const { Option } = Select;
const { confirm } = Modal;

const defaultTreeData = [{ title: "Product", key: "0", type: "entry" }];
const products = ["Product 1", "Product 2", "Product 3"];

const App = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [treeData, setTreeData] = useState(defaultTreeData);
  const [visible, setVisible] = useState(false);
  const [nodeToAdd, setNodeToAdd] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [products, setProducts] = useState([]);

  const convertFlatToHierarchical = async (flatData) => {
    try {
      const map = new Map();
      const hierarchicalData = [];
  
      // Use a Promise to ensure that forEach waits for all asynchronous operations
      await new Promise((resolve) => {
        flatData.forEach(async (item, index, array) => {
          const node = { ...item, children: [] }; // Ensure each node has a children property
          map.set(item.key, node);
  
          // If this is the last iteration, resolve the Promise
          if (index === array.length - 1) {
            resolve();
          }
        });
      });
  
      // Use a Promise to ensure that forEach waits for all asynchronous operations
      await new Promise((resolve) => {
        flatData.forEach(async (item, index, array) => {
          const node = map.get(item.key);
  
          if (!node) {
            // Skip if the node is undefined
            return;
          }
  
          if (item.parent_key === null) {
            hierarchicalData.push(node);
          } else {
            const parent = map.get(item.parent_key);
  
            if (parent) {
              parent.children.push(node);
            } else {
              // Handle the case where the parent is undefined
              console.error(`Parent node with key ${item.parent_key} not found`);
            }
          }
  
          // If this is the last iteration, resolve the Promise
          if (index === array.length - 1) {
            resolve();
          }
        });
      });
  
      return hierarchicalData;
    } catch (error) {
      console.error('Error in convertFlatToHierarchical:', error.message);
      throw error; // Rethrow the error if needed
    }
  }; 
  const handleAddGroup = () => {
    // Set the nodeToAdd state to the root node
    setNodeToAdd(defaultTreeData[0]);
    handleAddNode('null'); // Pass null as parentKey to indicate it's the root
  };
  const fetchTreeData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;
  
      const { data, error } = await supabase
        .from('product_tree_data')
        .select('*')
        .in('user_id', ['e239ab34-4152-4eed-bb99-db75f4a1b3de', userId]);
  
      if (error) {
        console.error('Error fetching tree data:', error.message);
        return [];
      }
  
      console.log('Fetched data:', data);
  
      const { data: productData, error: productError } = await supabase
        .from('Product')
        .select('english_name')
        .eq('user', userId);
  
      if (productError) {
        console.error('Error fetching products:', productError.message);
        return;
      }
  
      // Extract the english_name values from the fetched product data
      const productNames = productData.map((product) => product.english_name);
      setProducts(productNames);
  
      // Assuming the fetched data is in flat form, convert it to a hierarchical structure
      const hierarchicalTreeData = await convertFlatToHierarchical(data);
      console.log('Hierarchical data:', hierarchicalTreeData);
  
      setTreeData(hierarchicalTreeData || []);
    } catch (e) {
      console.error('Error in fetchTreeData:', e.message);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setSubmitLoading(true); // Start loading before fetching data
        await fetchTreeData(); // Await the completion of data fetching
      } catch (error) {
        // Handle any errors here
        console.error('Error fetching tree data:', error);
      } finally {
        setSubmitLoading(false); // Stop loading after fetching is complete
      }
    };
  
    fetchData();
  }, []); // Empty dependency array to run only on component mount
  
  
  // Empty dependency array ensures it runs only once on mount
  const addNodeToSupabase = async (nodeData) => {
    try {
     
      const { data, error } = await supabase.from('product_tree_data').insert([nodeData]);
  

      if (error) {
        console.error('Error adding node to Supabase:', error.message);
        return null;
      }

      return data[0];
    } catch (error) {
      console.error('Error in addNodeToSupabase:', error.message);
      throw error;
    }
   
  };
  const handleAddNode = async (parentKey) => {
    try {
      // Ensure that the parentKey is not null
      if (!parentKey) {
        console.error("Invalid parentKey:", parentKey);
        return;
      }
  
      console.log("Adding node with parentKey:", parentKey);

      // Create a new node data object
      const newNodeData = {
        title: 'New Node',
        key: `node-${Date.now()}`,
        type: 'group', // Adjust the type as needed
        parent_key: parentKey || null, // Use the provided parent key or set to null
      };


      // Add the node to Supabase
      const { data: addedNode, error: addError } = await supabase.from('product_tree_data').insert([newNodeData]);

  
      if (addError) {
        console.error('Error adding node to Supabase:', addError.message);
        return;
      }
  
      // Fetch the updated tree data from Supabase
      const { data: updatedData, error: fetchError } = await supabase.from('product_tree_data').select('*');
  
  
      if (fetchError) {
        console.error('Error fetching updated tree data:', fetchError.message);
        return;
      }
  
      // Convert the fetched data to hierarchical structure
      const updatedTreeData = await convertFlatToHierarchical(updatedData);
  
      // Update the local state with the updated tree data
      setTreeData(updatedTreeData || []);

    } catch (error) {
      console.error('Error in handleAddNode:', error.message);
    }
  };  
  const onDeleteNode = async (key, tree = treeData) => {
    if (!Array.isArray(tree)) {
      console.error('Invalid tree structure:', tree);
      return treeData;
    }
  
    let index = tree.findIndex((data) => data.key === key);
    if (index > -1) {
      // Perform the delete operation on Supabase
      await deleteNodeFromSupabase(key);
  
      // Return the updated tree without the deleted node
      return [...tree.slice(0, index), ...tree.slice(index + 1)];
    }
  
    // Check if the node is in the children of any parent
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].children && Array.isArray(tree[i].children)) {
        let newChildren = await onDeleteNode(key, tree[i].children);
        if (newChildren !== tree[i].children) {
          // Return the updated tree with the modified children
          return [...tree.slice(0, i), { ...tree[i], children: newChildren }, ...tree.slice(i + 1)];
        }
      }
    }
  
    return tree;
  };  
  const deleteNodeFromSupabase = async (key) => {
    try {
      // Perform the delete operation on Supabase using the key
      const { error } = await supabase.from('product_tree_data').delete().eq('key', key);
  

      if (error) {
        console.error('Error deleting node from Supabase:', error.message);
      }
    } catch (error) {
      console.error('Error in deleteNodeFromSupabase:', error.message);
      throw error;
    }
  };
  
  // Usage example
  // Call onDeleteNode with the key of the node you want to delete
  // await onDeleteNode(nodeKey);
  const addNode = async (key, value, parentKey = '0', tree = treeData, type = "group") => {
    try {
      

      const addProductToSupabase = async () => {
        
        try{        await addNodeToSupabase({ title: value, key, type, parent_key: parentKey });
      }
      catch(e)
      {

      }
        // Add the product to Supabase
      };
  
      const findNodeAndAdd = (parentNode) => {
        const updatedTree = [...tree];
  
        const findAndUpdate = (node) => {
          if (node.key === parentKey) {
            if (!node.children) {
              node.children = [];
            }
  
            // Check if the type is 'product' to handle product addition
            if (type === 'product') {
              addProductToSupabase();
            }
  
            node.children.push({ title: value, key, type });
            return true;
          }
  
          if (node.children) {
            return node.children.some(findAndUpdate);
          }
  
          return false;
        };
  
        parentNode.children.some(findAndUpdate);
  
        return updatedTree;
      };
  
      return findNodeAndAdd({ children: tree });
    } catch (error) {
      console.error('Error in addNode:', error.message);
      throw error; // Rethrow the error if needed
    }
  };
  const showConfirmDelete = async (nodeData, type) => {
    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: `Delete ${type}`,
          content: `Are you sure you want to delete this ${type}?`,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            // Use onDeleteNode to handle deletion asynchronously
            const handleDelete = async () => {
              try {
                await onDeleteNode(nodeData.key, treeData);
                resolve();
              } catch (error) {
                reject(error);
              }
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            };
  
            handleDelete();
          },
          onCancel() {
            reject(new Error('Deletion canceled'));
          },
        });
      });
  
      // If the promise resolves, update the UI
      setTreeData((prevTreeData) => onDeleteNode(nodeData.key, prevTreeData));
  
      notification['success']({
        message: 'Operation Success',
        description: `${type.charAt(0).toUpperCase() + type.slice(1)} was deleted successfully!`,
      });
    } catch (error) {
      console.error('Error in showConfirmDelete:', error.message);
      // Handle errors or log them as needed
      notification['error']({
        message: 'Operation Failed',
        description: `Failed to delete ${type}. Please try again.`,
      });
    }
  };
  const showConfirmAddProduct = async (nodeData, productName) => {
    try {
      await new Promise((resolve, reject) => {
        confirm({
          title:`Add Product`,
          content: `Are you sure you want to add ${productName} to this subgroup?`,
          okText: 'Yes',
          okType: 'primary',
          cancelText: 'No',
          async onOk() {
            // Add the product to Supabase
            const newNodeKey = `node-${Date.now()}`;
            setTimeout(() => {
              // Reload the page after adding the product
              window.location.reload();
            }, 100);
            const newNodeData = { title: productName, key: newNodeKey, type: 'product', parent_key: nodeData.key };

            await addNodeToSupabase(newNodeData);

  
            // Update the local state with the new product node
            setTreeData((prevTreeData) => addNode(newNodeKey, productName, nodeData.key, prevTreeData, 'product'));
  
            resolve();
          },
          onCancel() {
            reject(new Error('Add Product canceled'));
          },
        });
      });
  
      notification['success']({
        message: 'Operation Success',
        description: `Product ${productName} was added successfully!`,
      });
    } catch (error) {
      console.error('Error in showConfirmAddProduct:', error.message);
      // Handle errors or log them as needed
      notification['error']({
        message: 'Operation Failed',
        description: `Failed to add ${productName}. Please try again.`,
      });
    }
  }; 

  const [selectedEntryNode, setSelectedEntryNode] = useState(null);
  const showModal = (nodeData) => {
    console.log("showModal called with nodeData:", nodeData);
    setNodeToAdd(nodeData);
    setProductsListVisible(nodeData?.type === 'product');
    setVisible(true);
  };
  const handleOk = async () => {
    if (!nodeToAdd) {
      console.error("Invalid nodeToAdd:", nodeToAdd);
      return;
    }
  
    try {
      // Create a new node data object
      const parentKey = nodeToAdd && nodeToAdd.parent && nodeToAdd.parent.key ? nodeToAdd.parent.key : '0';

      setTimeout(() => {
        // Reload the page after adding the product
        window.location.reload();
      }, 100);
      const newNodeData = {
        title: inputValue,
        key: `node-${Date.now()}`,
        type: nodeToAdd.type,
        parent_key: parentKey,
      };

      // Add the node to Supabase
      const addedNode = await addNodeToSupabase(newNodeData);

      
  
      if (addedNode) {
        // Update the local state or trigger a refresh
        setTreeData((prevTreeData) => addNode(newNodeData.key, newNodeData.title, newNodeData.parent_key, prevTreeData, newNodeData.type));
      }
    } catch (e) {
      console.error('Error in handleOk:', e.message);
    }
  
    // Close the modal or perform any necessary cleanup
    setInputValue('');
    setVisible(false);
    setProductsListVisible(false);
  };  
  const handleok2 =async()=>{
    setTimeout(() => {
      // Reload the page after adding the product
      window.location.reload();
    }, 100);
  }
  const handleCancel = () => {
    
    setInputValue('');
    setVisible(false);
    setProductsListVisible(false);
  };
  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };
  // ... (previous code)

const [editingNode, setEditingNode] = useState(null);
const [editedTitle, setEditedTitle] = useState('');

const handleDoubleClick = (nodeData) => {
  setEditingNode({ key: nodeData.key, originalTitle: nodeData.title });
  setEditedTitle(nodeData.title);
};
const handleKeyDown = async (e, nodeData) => {
  // Handle Enter key press
  if (e.key === 'Enter') {
    e.preventDefault();
    await handleBlur(); // Close the edit box
  }
};

const handleInputChange = (e) => {
  setEditedTitle(e.target.value);
};

const handleBlur = async () => {
  try {
    if (editingNode) {
      const { key, originalTitle } = editingNode;

      if (editedTitle !== originalTitle) {
        // Update the node title in Supabase
        await updateNodeTitleInSupabase(key, editedTitle);

        // Update the local state with the new title
        setTreeData((prevTreeData) =>
          updateNodeTitle(prevTreeData, key, editedTitle)
        );
      }
    }
  } catch (error) {
    console.error('Error in handleBlur:', error.message);
  }

  setEditingNode(null);
};

// ... (rest of the code)

const updateNodeTitleInSupabase = async (key, newTitle) => {
  try {
    // Perform the update operation on Supabase using the key
    await supabase
      .from('product_tree_data')
      .update({ title: newTitle })
      .eq('key', key);
  } catch (error) {
    console.error('Error in updateNodeTitleInSupabase:', error.message);
    throw error;
  }
};

const updateNodeTitle = (tree, key, newTitle) => {
  return tree.map((node) => {
    if (node.key === key) {
      return { ...node, title: newTitle };
    } else if (node.children) {
      return { ...node, children: updateNodeTitle(node.children, key, newTitle) };
    }
    return node;
  });
};

// ... (rest of the code)

const [productsListVisible, setProductsListVisible] = useState(false);

// ... (existing functions)

const handleAddProductButtonClick = () => {
  setProductsListVisible(true);
};



const handleProductsListSubmit = async (productName) => {
  // Perform actions with the submitted productName (e.g., store it as a product)
  console.log('Product Name from ProductsList:', productName);

  // Close the modal after submission
  setProductsListVisible(false);
  setVisible(false);

  try {
    // Create a new node data object
    const parentKey = nodeToAdd && nodeToAdd.parent && nodeToAdd.parent.key ? nodeToAdd.parent.key : '0';

    const newNodeData = {
      title: productName,
      key: `node-${Date.now()}`,
      type: 'product',
      parent_key: parentKey,
    };

    // Add the node to Supabase
    const addedNode = await addNodeToSupabase(newNodeData);

    if (addedNode) {
      // Update the local state or trigger a refresh
      setTreeData((prevTreeData) =>
        addNode(newNodeData.key, newNodeData.title, newNodeData.parent_key, prevTreeData, newNodeData.type)
      );
    }
  } catch (error) {
    console.error('Error in handleProductsListSubmit:', error.message);
  }
  handleok2();

};


return (
  <Spin spinning={submitLoading}>
  <div style={{ margin: '30px' }}>
    <Tree
      defaultExpandedKeys={['0']}
      treeData={treeData}
      showLine={true}
      titleRender={(nodeData) => {
        let titleStyle = {
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          marginBottom: '14px',
          marginRight: '10px',
        };

        let badgeStyle = {
          backgroundColor: '#52c41a',
          marginLeft: '10px',
        };

        let iconStyle = {
          fontSize: '14px',
          marginRight: '10px',
        };
 
        // Apply different styles based on node type
        switch (nodeData.type) {
          case 'entry':
            titleStyle.color = 'blue'; // Change color for entry type
            break;
          case 'group':
            titleStyle.color = 'black'; // Change color for group type
            break;
          case 'subgroup':
            titleStyle.color = 'brown'; // Change color for subgroup type
            break;
          case 'product':
            titleStyle.color = 'orange'; // Change color for product type
            break;
          default:
            break;
        }

        const isEditing = editingNode && editingNode.key === nodeData.key;

        return (
          <div style={titleStyle}>
            <FolderOpenTwoTone style={{ ...iconStyle, color: titleStyle.color }} />
            <img src={DottedBariSV} alt="divider" style={{ marginRight: '10px' }} />

            {isEditing ? (
              <Input
                value={editedTitle}
                autoFocus
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={handleBlur}
                onPressEnter={handleBlur}  // Close the edit box when Enter is pressed
                onKeyDown={(e) => handleKeyDown(e, nodeData)} // Handle Enter key press
              />
            ) : (
              <span onDoubleClick={() => handleDoubleClick(nodeData)}>
                {nodeData.title}
              </span>
            )}

            <Badge count={nodeData.children ? nodeData.children.length : 0} style={badgeStyle} />
            <img src={DottedBarSVG} alt="divider" style={{ marginRight: '8px' }} />

            {nodeData.type !== 'entry' && (
              <Tooltip title="Delete" placement="bottom">
                <MinusCircleTwoTone
                  style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}
                  twoToneColor="red"
                  onClick={() => showConfirmDelete(nodeData, nodeData.type)}
                />
              </Tooltip>
            )}

            <img src={DottedBarSVG} alt="divider" style={{ marginRight: '8px' }} />

            {nodeData.type === 'entry' && (
              <Tooltip title="Add Group" placement="bottom">
                <PlusCircleTwoTone
                  style={{ marginLeft: '10px', cursor: 'pointer', color: 'green' }}
                  onClick={() => showModal({ parent: nodeData, type: 'group' })}
                />
              </Tooltip>

            )}

            {nodeData.type === 'group' && (
              <Tooltip title="Add Subgroup" placement="bottom">
                <CodeTwoTone
                  style={{ marginLeft: '10px', cursor: 'pointer', color: 'orange' }}
                  onClick={() => showModal({ parent: nodeData, type: 'subgroup' })}
                />
              </Tooltip>
            )}

{nodeData.type === 'subgroup' && (
  <Tooltip title="Add Product" placement="bottom">
    <PlusCircleTwoTone
      style={{ marginLeft: '10px', cursor: 'pointer', color: 'blue' }}
      onClick={() => showModal({ parent: nodeData, type: 'product' })}
    />
  </Tooltip>
)}
          </div>
        );
      }}
    />

    <Modal
      title={`Add ${nodeToAdd ? nodeToAdd.type.charAt(0).toUpperCase() + nodeToAdd.type.slice(1) : ''}`}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Input
        placeholder={`Enter ${nodeToAdd ? nodeToAdd.type : ''} name`}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </Modal>

    {/* "Productslist" modal */}
    <Modal
        title={`Add ${nodeToAdd ? nodeToAdd.type.charAt(0).toUpperCase() + nodeToAdd.type.slice(1) : ''}`}
        visible={productsListVisible}
        onOk={handleok2}
        onCancel={handleCancel}
      >
        {/* Show ProductsList component when node type is 'product' */}
        {nodeToAdd?.type === 'product' && (
          <ProductsList onProductSubmit={handleProductsListSubmit} />
        )}
        {/* Add the rest of your modal content here */}

      </Modal>
  </div>
  </Spin>
);


  
  
};

export default App;