import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/img/logo.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import {  NavLink } from 'react-router-dom';
export default function TopNavbar() {
  const [sidebarOpen, toggleSidebar] = useState(false);

  return (
    <>
       <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="animate whiteBg">
        <NavInner className="container">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            {/* <LogoIcon /> */}
            <img src={LogoIcon} alt="" width={80} />
            {/* <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
              fanatic
            </h1> */}
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                Services
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
                Projects
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="blog" spy={true} smooth={true} offset={-80}>
                Blog
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>
                Pricing
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                Contact
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <NavLink to="/sign-in" style={{ padding: "10px 30px 10px 0" }}>
                Log in
              </NavLink>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <NavLink to="/sign-up" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                Get Started
              </NavLink>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.nav`
  width: 100%;
  position: relative;
`;

const NavInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0; /* Add padding for better spacing */
  flex-direction: row; /* Stack items in a column on smaller screens */
  align-items: center; /* Center items in the column */

`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    flex-direction: column; /* Stack items in a column on smaller screens */
    align-items: center; /* Center items in the column */
    display: ${props => (props.showOnSmallScreen ? "flex" : "none")}; /* Show on small screens */
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    flex-direction: column; /* Stack items in a column on smaller screens */
    align-items: center; /* Center items in the column */
    display: ${props => (props.showOnSmallScreen ? "flex" : "none")}; /* Show on small screens */
  }
`;

