import React, { useState,useRef,useEffect } from 'react';
import { Form, Input, Button,Select,Row,Col,DatePicker ,Switch,Spin} from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { message } from 'antd';
import moment from 'moment';
import card from "../assets/images/company.png";
import card1 from "../assets/images/signature.png";
const { Option } = Select;

const App = () => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [submitLoading, setLoading] = useState(false);
  const [check , setcheck] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [enableMail, setEnableMail] = useState(false);
  const [enableSMS, setEnableSMS] = useState(false);
  const [localData, setLocalData] = useState([]);
  const [fixedCustomer, setFixedCustomer] = useState(null);
const [invoicePrinted, setInvoicePrinted] = useState(false);
const [fixedFields, setFixedFields] = useState({
  customer: null,
  mobile: null,
  creditAccount: null,
  orderNo: null,
  remarks: null,
  master1: null,
  master2: null,
  master3: null,
  placeOfSupply: null,
  salesEmployee: null,
});

const [discount, setDiscount] = useState('');
const [unit, setUnit] = useState('');
const [product, setProduct] = useState('');

const [printReturnClicked, setPrintReturnClicked] = useState(false);




  const fetchRecipientEmail = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;

      const { data, error } = await supabase
        .from('customer') // Replace with your actual Supabase table name for vendors
        .select('email')
        .eq('user', userId)
        .order('id', { ascending: false })
        .limit(1); // Limit the result to 1 row

      if (error) {
        console.error('Error fetching recipient email:', error.message);
        return null;
      }

      // Check if data is not empty and return the first row's email
      const recipientEmail = data.length > 0 ? data[0].email : null;

      console.log('Recipient Email:', recipientEmail); // Add this line for logging

      return recipientEmail;
    } catch (error) {
      console.error('Error in fetchRecipientEmail:', error.message);
      return null;
    }
  };


  const fetchSenderEmail = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;

      const { data, error } = await supabase
        .from('SMTP') // Replace with your actual Supabase table name for SMTP settings
        .select('SMTP_UserName')
        .eq('user_id', userId)
        .order('id', { ascending: false })
        .limit(1); // Limit the result to 1 row

      if (error) {
        console.error('Error fetching sender email:', error.message);
        return null;
      }

      // Check if data is not empty and return the first row's email
      const senderEmail = data.length > 0 ? data[0].SMTP_UserName : null;

      console.log('Sender Email:', senderEmail); // Add this line for logging

      return senderEmail;
    } catch (error) {
      console.error('Error in fetchSenderEmail:', error.message);
      return null;
    }
  };
  const [isAddEntryModalVisible, setIsAddEntryModalVisible] = useState(false);
  const [newEntryDetails, setNewEntryDetails] = useState({
    discount: 0,
    quantity: 0,
    unit: '',
    unitPrice: 0,
    vat:0,
    roundvat:0,
    tax:'',
    // other necessary fields
  });
  const handlePrintReturn = () => {
    // Extract the field values from the form
    const customer = form.getFieldValue('supplier');
    const mobile = form.getFieldValue('mobile');
    const creditAccount = form.getFieldValue('debitAccount');
    const orderNo = form.getFieldValue('code');
    const remarks = form.getFieldValue('remarks');
    const master1 = form.getFieldValue('master1');
    const master2 = form.getFieldValue('master2');
    const master3 = form.getFieldValue('master3');
    const placeOfSupply = form.getFieldValue('placeofsupply');
    const salesEmployee = form.getFieldValue('salesEmployee');
  
    // Update the fixedFields object with the new values
    setFixedFields({
      ...fixedFields,
      customer,
      mobile,
      creditAccount,
      orderNo,
      remarks,
      master1,
      master2,
      master3,
      placeOfSupply,
      salesEmployee,
    });
  
    // Rest of your code to add a new row and reset values
    const newRow = {
      customer,
      mobile,
      creditAccount,
      orderNo,
      remarks,
      master1,
      master2,
      master3,
      placeOfSupply,
      salesEmployee,
      discount,
      unit,
      product,
    };
  
    setData((prevData) => [...prevData, newRow]);
    setDiscount('');
    setUnit('');
    setProduct('');
    setPrintReturnClicked(true);
  };
  
  
  const onFinish = async (values) => {
    console.log('Form Values:', values);

    setLoading(true);
    const { data: { user } } = await supabase.auth.getUser();

    const userId = user.id;

    if (!enableMail && !enableSMS) {
      alert('Please enable at least one of Mail or SMS before submitting.');
      setLoading(false);
      return; // Stop further execution
    }
    try {
      // Extract form values
      const {
        mobile,
        debitAccount,
        code,
        unit,
        quantity,
        vat,
        vatType,
        unitPrice,
        remarks,
        date,
        deliveryDate,
        returnDate,
        master1,
        master2,
        master3,
        placeofsupply,
        currency,
        supplier,
        gross,
        dicount,
   
        roundvat,
        product,

      } = values;
    
const formattedDate = date ? date.format('YYYY-MM-DD') : null;
const formattedDate1 =  deliveryDate ?  deliveryDate.format('YYYY-MM-DD') : null;
const formattedDate2 =   returnDate ?   returnDate.format('YYYY-MM-DD') : null;

      const gross1 = quantity *  unitPrice;
      let vatAmount = 0;
      
  
     
      vatAmount = (vat / 100) * gross1;
    
    console.log('vataaa',vatAmount);

    const roundv = (gross1 + vatAmount).toFixed(0);
    const dicount2 = (roundv -((dicount / 100) * roundv));
      
  
      // Create a new row object
      const newRow = {
        mobile,
        debitAccount,
        code: code?Number(code):null,
        unit ,
        quantity,
        vat,
        vatType,
        unitPrice,
        remarks,
        date:formattedDate,
        deliveryDate:formattedDate1 ,
        returnDate:formattedDate2,
        master1,
        master2,
        master3,
        placeofsupply,
        currency,
        supplier,
        gross : gross1,
        dicount : dicount2,
      
        roundvat:roundv,
        product,
      }; 
  
      console.log('New Row:', newRow);
      console.log('Form Values:', values);
      console.log('New Row:', newRow);
      setLocalData([...localData, newRow]);
  
      // Store data in Supabase
      const { data: createdData, error } = await supabase
        .from('sales_return')
        .insert([newRow],{ skipRole: true });

        console.log('Created Data:', createdData);
          console.log('Error:', error);
          const newCode = await getNextCodeNumber();
if (newCode === null) {
  // handle error
  return;
}
// if (!fixedFields.supplier) {
//   setFixedFields({
//     supplier: values.supplier,
//     mobile: values.mobile,
//     debitAccount: values.debitAccount,
//     code: values.code,
//     unit: values.unit,
//     remarks: values.remarks,
//     master1: values.master1,
//     master2: values.master2,
//     master3: values.master3,
//     placeofsupply: values.placeofsupply,
//     currency: values.currency,
//     // Include other fields that need to be fixed after the first submission
//   });
// }
  
      if (error) {
        console.error('Error storing data in Supabase:', error.message);
      } else {
        console.log('Data stored in Supabase:', createdData);
        setcheck(!check);
        form.resetFields(); // Clear the form fields after successful submission
        if (enableMail){
          const toEmail = await fetchRecipientEmail();
        const senderEmail = await fetchSenderEmail();

        if (!toEmail || !senderEmail) {
          console.error('Recipient or sender email not found');
          setLoading(false);
          return;
        }

        // Fetch SMTP details from Supabase
        const { data: smtpData, error: smtpError } = await supabase
          .from('SMTP') // Replace with your actual Supabase table name for SMTP settings
          .select('SMTP_Host, SMTP_Port, SMTP_UserName, SMTP_Pass')
          .eq('user_id', userId)
          .order('id', { ascending: false })
          .limit(1);

        if (smtpError) {
          console.error('Error fetching SMTP details:', smtpError.message);
          setLoading(false);
          return;
        }
        const { data: EmailData, error: emailError } = await supabase
          .from('Email_Template') // Replace with your actual Supabase table name for SMTP settings
          .select('subject,email_template')
          .eq('user_id', userId)
          .order('id', { ascending: false })
          .limit(1);


          
        if (emailError) {
          console.error('Error fetching Email details:', emailError.message);
          setLoading(false);
          return;
        }
        const { subject,email_template } = EmailData[0];
        const { SMTP_Host, SMTP_Port, SMTP_UserName, SMTP_Pass } = smtpData[0];
        
        // Continue with your existing code, using the fetched 'toEmail' and 'senderEmail' values
        const mailOptions = {
          from: SMTP_Host,
          to: toEmail,
          subject: subject,
          text: email_template,
        };
        console.log('The subject is:',subject);
        console.log('the email is :',email_template);

        // Send email
        const emailResponse = await fetch('https://alpha-payment-backend.vercel.app/api/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: toEmail,
            from: SMTP_Host,
            host: SMTP_Host,
            port: SMTP_Port,
            secure: true,
            user: SMTP_UserName,
            pass: SMTP_Pass,
          }),
        });

        console.log('Server Response:', emailResponse);

        if (!emailResponse.ok) {
          throw new Error(`Error sending email: ${emailResponse.statusText}`);
        }

        const contentType = emailResponse.headers.get('content-type');
        let emailData;

        if (contentType && contentType.includes('application/json')) {
          // If content type is JSON, parse the response as JSON
          emailData = await emailResponse.json();
          console.log('Email sent successfully:', emailData);
        } else {
          // If content type is not JSON (e.g., plain text), extract and use the text
          const plainTextResponse = await emailResponse.text();
          console.log('Email sent successfully:', plainTextResponse);
        }
        }
        
      }
    

    //   const { data: fetchData, error: fetchError } = await supabase.from('sales_return').select('*');
    // if (fetchError) {
    //   console.error('Error fetching data from Supabase:', fetchError.message);
    // } else {
     
      
    //   setData(fetchData || []);
    // }

  } catch (error) {
    setLoading(false);
    form.resetFields(['quantity', 'vat', 'vatType', 'unitPrice', 'product', 'taxable', 'discount']);
    console.error('Error in onFinish:', error.message);
  }
  form.resetFields();

  };
  const addEntryToLocalData = () => {
    setIsAddEntryModalVisible(true);
  };
  
;

  

   



  
  
  

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);


  const [customerOptions, setCustomerOptions] = useState([]);
const [unitOptions, setUnitOptions] = useState([]);
const [masterOptions, setmasterOptions] = useState([]);
const [vatOptions, setVatOptions] = useState([]);
const [vatTypeOptions, setVatTypeOptions] = useState([]);
const [salesEmployeeOptions, setSalesEmployeeOptions] = useState([]);
const [productOptions, setProductOptions] = useState([]);
//const [vatOptions, setVatOptions] = useState([]);
const [selectedProduct, setSelectedProduct] = useState(null);
const [selectedVatType, setSelectedVatType] = useState(null);
const [selectedVat, setSelectedVat] = useState(null);


const getNextCodeNumber = async () => {
  try {
    const { data, error } = await supabase
      .from('sales_return')
      .select('code')
      .order('code', { ascending: false })
      .limit(1);

    if (error) throw error;

    if (data.length > 0) {
      return data[0].code + 1;
    }
    return 1; // default starting code
  } catch (error) {
    console.error('Error in getNextCodeNumber:', error.message);
    return null;
  }
};

const handleProductChange = async (option) => {
  setSelectedProduct(option);

  try {
    if (!option.type) {
      console.error('Type not provided for the selected product. Please select a type.');
      // You can show an error message or prompt the user to select a type
      return;
    }

    let selectedProduct;
    console.log('Product ID:', option.value);


    // Determine the table based on the type
    switch (option.type) {
      case 'Product':
        // Fetch information for Product
        const { data: productData, error: productError } = await supabase
          .from('Product')
          .select('vat, selling_price')
          .eq('id', option.value);

        if (productError) {
          console.error('Error fetching product information:', productError.message);
          return;
        }

        selectedProduct = productData[0];
        break;

      case 'Service':
        // Fetch information for Service
        // You need to replace 'Service' with your actual table name
        const { data: serviceData, error: serviceError } = await supabase
          .from('Service')
          .select('vat, selling_price')
          .eq('id', option.value);

        if (serviceError) {
          console.error('Error fetching service information:', serviceError.message);
          return;
        }

        selectedProduct = serviceData[0];
        break;

      case 'Asset':
        // Fetch information for Asset
        // You need to replace 'Asset' with your actual table name
        const { data: assetData, error: assetError } = await supabase
          .from('Asset')
          .select('vat, selling_price')
          .eq('id', option.value);

        if (assetError) {
          console.error('Error fetching asset information:', assetError.message);
          return;
        }

        selectedProduct = assetData[0];
        break;

      default:
        console.warn('Unknown item type:', option.type);
        return;
    }

    // Fetch VAT data from the "VAT" table in Supabase
    const { data: vatData, error: vatError } = await supabase
      .from('VAT') // Replace with your actual VAT table name
      .select('id, name')
      .eq('id', selectedProduct?.vat);

    if (vatError) {
      console.error('Error fetching VAT information:', vatError.message);
      return;
    }

    const matchingVat = vatData[0];

    if (matchingVat) {
      setSelectedVatType(matchingVat.name); // Set the name of VAT
      form.setFieldsValue({ vatType: matchingVat.name });
      setSelectedVat(matchingVat.id); // Set the name of VAT
      form.setFieldsValue({ vat: matchingVat.id });

      // Set unitPrice using the selling_price from the selected product
      form.setFieldsValue({ unitPrice: selectedProduct?.selling_price });
      console.log('selectedProduct:', selectedProduct);
      console.log('selling_price:', selectedProduct?.selling_price);

    } else {
      // Handle the case where no matching VAT is found
      console.warn('No matching VAT found for the selected product.');
      setSelectedVatType(null);
      form.setFieldsValue({ vatType: null });
    }
    // resetForm();
  } catch (error) {
    console.error('Error handling product change:', error.message);
  }
};


// useEffect(() => {
//   if (fixedFields) {
//     form.setFieldsValue(fixedFields);
//   }
// }, [fixedFields, form]);


const incrementCode = async () => {
  const newCode = await getNextCodeNumber();
  if (newCode !== null) {
    form.setFieldsValue({ code: newCode });
  }
};


useEffect(() => {
  const fetchData = async () => {
    
    try {
     
      const { data: { user } } = await supabase.auth.getUser();

      const userId = user.id;

      const { data: masterData } = await supabase
        .from("master") // Replace with the actual unit table name
        .select("id,name").eq('user', userId);
     setmasterOptions(masterData || []);

     const { data: unitData } = await supabase
     .from("Unit") // Replace with the actual unit table name
     .select("id,unit_name").eq('user', userId);

   setUnitOptions(unitData || []);

   const { data: productData, error: productError } = await supabase
   .from("Product")
   .select('id, english_name')  // Adjust the fields as needed
   .eq('user', userId);
 
 if (productError) {
   console.error('Error fetching product options:', productError.message);
   return;
 }
 
 const productOptions = productData.map((product) => ({
   id: product.id,
   english_name: product.english_name,
   type: 'Product',
 }));

    const { data: serviceData, error: serviceError } = await supabase
    .from('Service') // Replace with your actual Service table name
    .select('*').eq('user', userId);

    if (serviceError) {
    console.error('Error fetching service options:', serviceError.message);
    return;
    }

    const serviceOptions = serviceData.map((service) => ({
    id: service.id,
    english_name: service.english_name,
    type: 'Service',
    }));

// Fetch options from Asset
const { data: assetData, error: assetError } = await supabase
 .from('Asset') // Replace with your actual Asset table name
 .select('id, english_name').eq('user', userId);

if (assetError) {
 console.error('Error fetching asset options:', assetError.message);
 return;
}

const assetOptions = assetData.map((asset) => ({
 id: asset.id,
 english_name: asset.english_name,
 type: 'Asset',
}));

// Combine options from Product, Service, and Asset
const combinedOptions = productOptions.concat(serviceOptions, assetOptions).map((option) => ({
  ...option,
  type: option.type || 'Product', // Provide a default type if not present
}));
// Update the state or form with the combined product options
setProductOptions(combinedOptions);

      // Fetch data for vat
      const { data: vatData } = await supabase
        .from("VAT") // Replace with the actual vat table name
        .select("id,value").eq('user', userId);

      setVatOptions(vatData || []);

      const { data: customerData } = await supabase
      .from("customer") // Replace with the actual vat table name
      .select("id,name").eq('user', userId);

    setCustomerOptions(customerData || []);

      // Fetch data for vat type
      const { data: vatTypeData } = await supabase
        .from("VAT") // Replace with the actual vat type table name
        .select("id,name").eq('user', userId);

      setVatTypeOptions(vatTypeData || []);

      // Fetch data for sales employee
      const { data: salesEmployeeData } = await supabase
        .from("Employee") // Replace with the actual sales employee table name
        .select("id,name").eq('user', userId);

      setSalesEmployeeOptions(salesEmployeeData || []);

      if (selectedProduct) {
        const { data: productVatData, error: productVatError } = await supabase
          .from('Product')
          .select('vat')
          .eq('id', selectedProduct);

   
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Handle error, show a message, etc.
    }
    setLoading(true);
    try {
      // const { data: { user } } = await supabase.auth.getUser();

      // const userId = user.id;
      
      // const { data: fetchData, error } = await supabase.from('sales_return').select('*').eq('user_id', userId);
      // if (error) {
      //   console.error('Error fetching data from Supabase:', error.message);
      // } else {
      //   setData(fetchData || []);
      // }
    } catch (error) {
      console.error('Error in fetchData:', error.message);
    }
    setLoading(false);
    

  
  
};

  // Fetch data when the component mounts
  fetchData();
}, [check,setcheck]); // Empty dependency array means this effect runs once when the component mounts

  

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const customConfirm = window.confirm;
// Now use `customConfirm` instead of `confirm`

    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  function convertVatAmountToWords(amount) {
    // Define arrays for the words
    const ones = [
      'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
      'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
  
    const tens = [
      '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];
  
    // Function to convert a number less than 1000 to words
    function convertLessThanOneThousand(number) {
      if (number === 0) return '';
      if (number < 20) {
        return ones[number];
      }
      if (number < 100) {
        return tens[Math.floor(number / 10)] + ' ' + ones[number % 10];
      }
      return ones[Math.floor(number / 100)] + ' Hundred ' + convertLessThanOneThousand(number % 100);
    }
  
    // Function to convert the entire amount to words
    function convertToWords(amount) {
      if (amount === 0) return 'Zero';
  
      const units = ['', 'Thousand', 'Million', 'Billion'];
  
      let result = '';
      let unitIndex = 0;
  
      while (amount > 0) {
        if (amount % 1000 !== 0) {
          result = convertLessThanOneThousand(amount % 1000) + ' ' + units[unitIndex] + ' ' + result;
        }
        amount = Math.floor(amount / 1000);
        unitIndex++;
      }
  
      return result.trim();
    }
  
    // Format the amount with two decimal places
    const formattedAmount = parseFloat(amount).toFixed(2);
  
    // Split the integer and decimal parts
    const [integerPart, decimalPart] = formattedAmount.split('.');
  
    // Convert the integer part to words
    const integerWords = convertToWords(parseInt(integerPart, 10));
  
    // Create the final result
    let result = integerWords + ' Dollars';
  
    // If there is a non-zero decimal part, add it to the result
    if (decimalPart !== '00') {
      const decimalWords = convertToWords(parseInt(decimalPart, 10));
      result += ' and ' + decimalWords + ' Cents';
    }
  
    return result;
  }
  
  // Example usage:
 
  
  

  // const resetForm = () => {
  //   if (!fixedCustomer) {
  //     form.resetFields(); // Only reset form fields if fixedCustomer is not set
  //   }
  //   // ... other reset operations, if any ...
  // };

  const generatePdf = (values, newRow) => {
    console.log('New Row Data:', newRow);
    const goodsReceivedContent = generatePDFContent(values,localData);

   
   
    const pdfOptions = {
      margin: 10,
      filename: `Sales_Return_${values.code}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    html2pdf().from(goodsReceivedContent).set(pdfOptions).save();
    // setInvoicePrinted(true);
    // setFixedFields({}); // Clear fixed fields after printing
    form.resetFields();
  };
  const generatePDFContent = (values,localData) => {
    const companyDetails = {
      name: "Ozone",
      address: "Company Address, City",
      email: "info@company.com",
      phone: "+1 234 567 890",
      logoUrl: "",
    };
    let   totalGross = 0;
    data.forEach(item => {
      
      totalGross += Number(item.gross);
      
    });
    // Format dates using moment.js
    const vatamountinwords = convertVatAmountToWords(values.vat);
    const dateFormatted = values.date ? moment(values.date).format('YYYY-MM-DD') : 'N/A';
    const deliveryDateFormatted = values.deliveryDate ? moment(values.deliveryDate).format('YYYY-MM-DD') : 'N/A';
    const returnDateFormatted = values.returnDate ? moment(values.returnDate).format('YYYY-MM-DD') : 'N/A';
    let tableData = Array.isArray(values) ? values : [values];
    // Construct table rows string
  
    const grossamountinwords = convertVatAmountToWords(totalGross);
    console.log('Table Data:', tableData);
    let tableRows = localData.map((row, index) => {
      return `<tr>
                <td>${index + 1}</td>
                <td>${row.quantity || ''}</td>
                <td>${row.unit || ''}</td>
                <td>${row.unitPrice || ''}</td>
                <td>${row.gross || ''}</td>
                <td>${row.dicount || ''}</td>

                <td>${row.vatType || ''}</td>
                <td>${row.vat || ''}</td>
                <td>${row.roundvat || ''}</td>
              </tr>`;
    }).join('');
  
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Sales_Enquiry</title>
      <style>
        body {
          font-family: 'Arial', sans-serif;
          margin: 0;
          padding: 0;
          font-size: 12px;
        }
        .container {
          padding: 20px;
        }
        .header, .footer {
          text-align: left;
          margin-bottom: 20px;
        }
        .footer {
          margin-top: 20px;
        }
        .content-section {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }
        .left-section, .right-section {
          width: 48%;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th, td {
          border: 1px solid #000;
          padding: 5px;
          text-align: left;
        }
        th {
          background-color: #f2f2f2;
        }
        .totals {
          margin-top: 10px;
          padding-top: 5px;
          border-top: 2px solid #000;
        }
        .totals div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
        }
        .signature {
          text-align: right;
          margin-top: 30px;
        }
      </style>
      </head>
      <body>
        
          
<div class="left-section left-align" style="text-align: left !important;">
<img src="${card}" alt="Company Logo">
<h2>${companyDetails.name}</h2>
<p>${companyDetails.address}</p>
<p>Email: ${companyDetails.email}</p>
<p>Phone: ${companyDetails.phone}</p>
</div>

        
        <div class="header  left-align" >
        
            <h1>Sales Return</h1>
            </div>
          
          <div class="content-section">
            <div class="left-section">
              <p>Name: ${values.supplier || ''}</p>
              <p>Phone: ${values.mobile || ''}</p>
              
              <p>Address: ${values.placeofsupply|| ''}</p>
              <p>Narration: ${values.remarks || ''}</p>
            </div>
            <div class="right-section right-align" style="border: 1px solid #000; padding: 10px;">
            <p style="text-align: right !important;">Voucher No: ${values.code || ''}</p>
            <p style="text-align: right !important;">Date: ${dateFormatted}</p>
            <p style="text-align: right !important;">Delivery Date: ${deliveryDateFormatted}</p>
            <p style="text-align: right !important;">Return Date: ${returnDateFormatted}</p>
            <p style="text-align: right !important;">Place of Supply: ${values.placeofsupply || ''}</p>
          </div>
          
          
          
          </div>
          <table>
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Qty</th>
                <th>Unit</th>
                <th>Unit Price</th>
                <th>Gross</th>
                <th>Disc</th>
            
                <th>VAT%</th>
                <th>VAT</th>
                <th>Roundvat</th>
              </tr>
            </thead>
            <tbody>
              ${tableRows}
            </tbody>
          </table>
          <div class="totals">
          <div class="content-section">
         
            <span>Total Gross: ${totalGross}</span>
            <span>Amount in words: ${grossamountinwords}</span>
            <span>Discount: ${localData.dicount || ''}</span>
            <span> VAT Amount in words: ${vatamountinwords} </span>
           
          </div>
         
        </div>
          <div class="footer">
            <p>Authorised Signature</p>
           
          </div>
          <div class=" left-align">
          <img src="${card1}" alt="Signature Logo">
          </div>
        </div>
      </body>
    </html>
  `;
   
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };



  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };


  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditingRecord(record);
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleDeleteConfirm = async () => {
    try {
      // Delete data from Supabase
      const { data: deletedData, error } = await supabase
        .from('sales_return')
        .delete()
        .eq('id', recordToDelete.id);
  
      if (error) {
        console.error('Error deleting data in Supabase:', error.message);
      } else {
        console.log('Data deleted in Supabase:', deletedData);
        setcheck(!check);
  
        // Fetch data after successful deletion
        const { data: fetchData, error: fetchError } = await supabase.from('sales_return').select('*');
        if (fetchError) {
          console.error('Error fetching data from Supabase:', fetchError.message);
        } else {
          setData(fetchData || []);
        }
      }
    } catch (error) {
      console.error('Error in handleDeleteConfirm:', error.message);
    } finally {
      setDeleteModalVisible(false);
      setRecordToDelete(null);
    }
  };
  

  const handleEditConfirm = async () => {
    try {
      // Update data in Supabase
      const { data: updatedData, error } = await supabase
      .from('sales_return')
      .update([{ ...editedRecord }], { skipRole: true, returning: 'minimal' }) // Add returning: 'minimal'
      .eq('id', editedRecord.id);
  
      if (error) {
        console.error('Error updating data in Supabase:', error.message);
      } else {
        console.log('Data updated in Supabase:', updatedData);
        setcheck(!check);
        form.resetFields();
  
        // Fetch data after successful update
        const { data: fetchData, error: fetchError } = await supabase.from('sales_return').select('*');
        if (fetchError) {
          console.error('Error fetching data from Supabase:', fetchError.message);
        } else {
          setData(fetchData || []);
        }
      }
    } catch (error) {
      console.error('Error in handleEditConfirm:', error.message);
    } finally {
      setEditModalVisible(false);
      setEditingRecord(null);
      setRecordToEdit(null);
      setEditedRecord(null);
    }
  };


  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const calculateTotals = () => {
    let totalQuantity = 0, totalUnitPrice = 0, totalGross = 0, totalDiscount = 0, totalTaxable = 0, totalRoundVat = 0;
    data.forEach(item => {
      totalQuantity += Number(item.quantity);
      totalUnitPrice += Number(item.unitprice);
      totalGross += Number(item.gross);
      totalDiscount += Number(item.dicount);
     
      totalRoundVat += Number(item.roundvat);
    });
    return {
        key: 'total',
        code: 'Total',
        name: '',
        descrip: '',
        unit: '',
        vat: '',
        quantity: totalQuantity,
        unitprice: totalUnitPrice,
        gross: totalGross,
        dicount: totalDiscount,
   
        roundvat: totalRoundVat,
      };
    };

    const renderEditForm = () => {
      return (
        <div>
          <p>Edit Record</p>
          <div>
            <label>Code: </label>
            <Input
              value={editedRecord?.code}
              onChange={(e) => handleEditInputChange('id', e.target.value)}
            />
          </div>
          <div>
            <label>Date: </label>
            <Input
              value={editedRecord?.date}
              onChange={(e) => handleEditInputChange('date', e.target.value)}
            />
          </div>
          <div>
            <label>Customer: </label>
            <Input
              value={editedRecord?.supplier}
              onChange={(e) => handleEditInputChange('supplier', e.target.value)}
            />
          </div>
          <div>
            <label>Mobile NO: </label>
            <Input
              value={editedRecord?.mobile}
              onChange={(e) => handleEditInputChange('mobile', e.target.value)}
            />
          </div>
          <div>
            <label>Credit Account: </label>
            <Input
              value={editedRecord?.debitAccount}
              onChange={(e) => handleEditInputChange('debitAccount', e.target.value)}
            />
          </div>
          <div>
            <label>Remarks: </label>
            <Input
              value={editedRecord?.remarks}
              onChange={(e) => handleEditInputChange('remarks', e.target.value)}
            />
          </div>
          <div>
            <label>Delivery Date: </label>
            <Input
              value={editedRecord?.deliveryDate}
              onChange={(e) => handleEditInputChange('deliveryDate', e.target.value)}
            />
          </div>
          <div>
            <label>ReturnDate: </label>
            <Input
              value={editedRecord?.returnDate}
              onChange={(e) => handleEditInputChange('returnDate', e.target.value)}
            />
          </div>
          <div>
            <label>Master 1: </label>
            <Input
              value={editedRecord?.master1}
              onChange={(e) => handleEditInputChange('master1', e.target.value)}
            />
          </div>
          <div>
            <label>Master 2: </label>
            <Input
              value={editedRecord?.master2}
              onChange={(e) => handleEditInputChange('master2', e.target.value)}
            />
          </div>
          <div>
            <label>Master 3: </label>
            <Input
              value={editedRecord?.master3}
              onChange={(e) => handleEditInputChange('master3', e.target.value)}
            />
          </div>
          <div>
            <label>Sales Employee: </label>
            <Input
              value={editedRecord?.currency}
              onChange={(e) => handleEditInputChange('currency', e.target.value)}
            />
          </div>
          <div>
            <label>Unit: </label>
            <Input
              value={editedRecord?.unit}
              onChange={(e) => handleEditInputChange('unit', e.target.value)}
            />
          </div>
          <div>
            <label>VAT: </label>
            <Input
              value={editedRecord?.vat}
              onChange={(e) => handleEditInputChange('vat', e.target.value)}
            />
          </div>
          <div>
            <label>VAT TYPE: </label>
            <Input
              value={editedRecord?.vattype}
              onChange={(e) => handleEditInputChange('vattype', e.target.value)}
            />
          </div>
          <div>
            <label>Quantity: </label>
            <Input
              value={editedRecord?.quantity}
              onChange={(e) => handleEditInputChange('quantity', e.target.value)}
            />
          </div>
          <div>
            <label>Unit Price: </label>
            <Input
              value={editedRecord?.unitprice}
              onChange={(e) => handleEditInputChange('unitprice', e.target.value)}
            />
          </div>
          <div>
            <label>Gross: </label>
            <Input
              value={editedRecord?.gross}
              onChange={(e) => handleEditInputChange('gross', e.target.value)}
            />
          </div>
          <div>
            <label>Discount: </label>
            <Input
              value={editedRecord?.dicount}
              onChange={(e) => handleEditInputChange('dicount', e.target.value)}
            />
          </div>
          <div>
            <label>Round VAT: </label>
            <Input
              value={editedRecord?.roundvat}
              onChange={(e) => handleEditInputChange('roundvat', e.target.value)}
            />
          </div>  
        </div>
      );
    };
  
  const dataSourceWithTotal = [...data, calculateTotals()];

  const columns = [

    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: '30%',
      ...getColumnSearchProps('code'),
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '20%',
        ...getColumnSearchProps('date'),

      },
      {
        title: 'Customer Id',
        dataIndex: 'supplier',
        key: 'supplier',
        width: '20%',
        ...getColumnSearchProps('supplier'),

      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        width: '20%',
        ...getColumnSearchProps('mobile'),

      },
      {
        title: 'Credit Account',
        dataIndex: 'debitAccount',
        key: 'debitAccount',
        width: '20%',
        ...getColumnSearchProps('debitAccount'),

      },
      {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        width: '20%',
        ...getColumnSearchProps('remarks'),

      },
      {
        title: 'Delivery Date',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        width: '20%',
        ...getColumnSearchProps('deliveryDate'),

      },
      {
        title: 'Return Date',
        dataIndex: 'returnDate',
        key: 'returnDate',
        width: '20%',
        ...getColumnSearchProps('returnDate'),

      },
      {
        title: 'Master 1 Id',
        dataIndex: 'master1',
        key: 'master1',
        width: '20%',
        ...getColumnSearchProps('master1'),

      },
      {
        title: 'Master 2 Id',
        dataIndex: 'master2',
        key: 'master2',
        width: '20%',
        ...getColumnSearchProps('master2'),

      },
      {
        title: 'Master 3 Id',
        dataIndex: 'master3',
        key: 'master3',
        width: '20%',
        ...getColumnSearchProps('master3'),

      },
      {
        title: 'Sales Employee Id',
        dataIndex: 'currency',
        key: 'currency',
        width: '20%',
        ...getColumnSearchProps('currency'),

      },
      {
        title: 'Product Id',
        dataIndex: 'product',
        key: 'product',
        width: '20%',
        ...getColumnSearchProps('product'),

      },
    {
      title: 'Unit Id',
      dataIndex: 'unit',
      key: 'unit',
      width: '30%',
      ...getColumnSearchProps('unit'),

    },
    {
      title: 'Vat',
      dataIndex: 'vat',
      key: 'vat',
      width: '30%',
      ...getColumnSearchProps('vat'),
    },
    {
        title: 'VAT Type',
        dataIndex: 'vatType',
        key: 'vatType',
        width: '30%',
        ...getColumnSearchProps('vat'),
      },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '30%',
      ...getColumnSearchProps('qunatity'),
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: '30%',
      ...getColumnSearchProps('unitPrice'),
    },
    {
        title: 'Gross',
        dataIndex: 'gross',
        key: 'gross',
        width: '30%',
        ...getColumnSearchProps('gross'),
      },
      {
        title: 'Discount',
        dataIndex: 'dicount',
        key: 'dicount',
        width: '30%',
        ...getColumnSearchProps('dicount'),
      },
     
        {
            title: 'Round Vat',
            dataIndex: 'roundvat',
            key: 'roundvat',
            width: '30%',
            ...getColumnSearchProps('roundvat'),
          },
          {
            title: 'Print Invoice',
            key: 'printInvoice',
            render: (text, record) => (
              <Button onClick={() => {
                generatePdf(record);
               
              }}>Print Return</Button>
              
            ),
          },
//          



          {
            title: 'Actions',
            key: 'actions',
            align: 'right',
            render: (_, record) => (
              <>
                <EditOutlined
                  onClick={() => handleEdit(record)}
                  style={{ color: 'blue', marginRight: 8 }}
                />
                <DeleteOutlined
                  onClick={() => handleDelete(record)}
                  style={{ color: 'blue' }}
                />
              </>
            ),
          },
      
   
  
  ];
  function handleToggleEnable(field, checked, record) {
    const updatedData = data.map((dataItem) =>
      dataItem.key === record.key ? { ...dataItem, [field]: checked } : dataItem
    );
    setData(updatedData);
  };
    const initialDate = {
    date: moment(),
    // ... other initial values
  };

  return (
    <Spin spinning={submitLoading}>
    <>
    <Form form={form} onFinish={onFinish}initialValues={{ date: moment(),quantity:1,vat:0,vatType:0,unitPrice:0 }}>
        <Row gutter={16}>
          <Col span={12}>
            <div style={{ width: 600 }}>
              <Form.Item name="date" 
                >
                   <DatePicker
                        placeholder="Date"
                        style={{ width: '30%' }}
                        //defaultValue={moment()}// Update form value on date change
                      />
              </Form.Item>
              <div style={{ display: 'flex' }}>
  
              <Form.Item name="supplier" rules={[{ required: true, message: 'Please fill the field' }]} style={{width :'30%'}}>
  <Select
    placeholder="Customer"
    // disabled={!!fixedCustomer} // Disable if fixedCustomer is set
    style={{ width: '100%' }}
    showSearch
    optionFilterProp="children"
    mode="single"
    // onChange={(value, option) => {
    //   if (!invoicePrinted) {
    //     setFixedCustomer({ id: value, name: option.children });
    //   }
    // }}
  >
    {customerOptions.map((customer) => (
      <Option key={customer.id} value={customer.id}>
        {customer.name}
      </Option>
    ))}
  </Select>
</Form.Item>


              <Form.Item
                name="mobile"
               style={{marginLeft:'10px'}}
              >
                <Input placeholder="Mobile Number" style={{ width: '100%' }} 
                />
              </Form.Item>
              </div>
              <div style={{ display: 'flex' }}>
              <Form.Item name="debitAccount" style={{ width: '30%' }} rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <Input placeholder="Credit Account"   />
              </Form.Item>
              <Form.Item name="code" style={{ width: '30%',marginLeft:'8px' }} >
                <Input placeholder="Order No"   />
              </Form.Item>
              </div>
              <div style={{ display: 'flex' }}>
              <Form.Item name="product" style={{ width: '30%' }}rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
              <Select
                  placeholder="Product"
                  style={{ width: '100%' }}
                  onChange={(value, option) => handleProductChange(option)}
                >
                  {productOptions.map((Product) => (
                    <Option key={Product.id} value={Product.id} type={Product.type}>
                      {Product.english_name}
                    </Option>
                  ))}
              </Select>

</Form.Item>

              <Form.Item name="quantity" >
                <Input placeholder="Quantity" type="number" style={{ width: '95%',marginLeft:'10px' }} />
              </Form.Item>

              
              </div>
              <div style={{ display: 'flex' }}>
              <Form.Item name="vat" rules={[
    {
      required: true,
      message: 'Please fill the field',
    },
  ]} style={{ width: '30%' }}>
  <Select placeholder="VAT" style={{ width: '100%', marginRight: '10px' }}disabled>
    {vatOptions.map((VAT) => (
      <Option key={VAT.id} value={VAT.id}>
        {VAT.value}
      </Option>
    ))}
  </Select>
</Form.Item>

<Form.Item name="vatType" style={{ width: '30%' }}>
<Select placeholder="VAT Type" style={{ width: '100%',marginLeft:'8px' }} disabled>
          {vatOptions.map((vat) => (
            <Option key={vat.id} value={vat.id}>
              {vat.name}
            </Option>
          ))}
        </Select>
</Form.Item>
              </div>
              <Form.Item name="unit" style={{ width: '30%' }}>
  <Select placeholder="Unit" style={{ width: '100%' }}>
    {unitOptions.map((Unit) => (
      <Option key={Unit.id} value={Unit.id}>
        {Unit.unit_name}
      </Option>
    ))}
  </Select>
</Form.Item>
              <Form.Item name="unitPrice" rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]} style={{width:'30%'}}>
                <Input placeholder="Unit Price" type="number" style={{ width: '100%'}} disabled/>
              </Form.Item>
              <Form.Item name="remarks">
                <Input placeholder="Remarks" style={{ width: '80%' }} />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item name="enableMail" valuePropName="checked" initialValue={false} style={{ width: '30%' }}>
                    <Switch
                      checkedChildren="Enable Mail"
                      unCheckedChildren="Disable Mail"
                      defaultChecked={false}
                      onChange={(checked) => setEnableMail(checked)}
                    />
                  </Form.Item>
                  {/* <Form.Item name="enableSMS" valuePropName="checked" initialValue={false} style={{ width: '100%' }}>
                    <Switch
                      checkedChildren="Enable SMS"
                      unCheckedChildren="Disable SMS"
                      defaultChecked={false}
                      onChange={(checked) => setEnableSMS(checked)}
                    />
                  </Form.Item> */}
              </div>
              
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item name="deliveryDate" style={{ width: '30%' }} >
            <DatePicker placeholder="Delivery Date" />
          </Form.Item>
          <Form.Item name="returnDate" style={{ width: '100%',marginLeft:'8px' }} >
            <DatePicker placeholder="Return Date" />
          </Form.Item>
        </div>
            </div>
          </Col>
          <Col span={12}>
            <Form.Item name="master1" >
              <Select placeholder="Master 1" style={{ width: '40%'  }}>
              {masterOptions.map((master) => (
      <Option key={master.id} value={master.id}>
        {master.name}
      </Option>
    ))}
  </Select>
            </Form.Item>
            <Form.Item name="master2" >
              <Select placeholder="Master 2" style={{ width: '40%'  }}>
              {masterOptions.map((master) => (
      <Option key={master.id} value={master.id}>
        {master.name}
      </Option>
    ))}
  </Select>
            </Form.Item>
            <Form.Item name="master3">
              <Select placeholder="Master 3" style={{ width: '40%'  }}>
              {masterOptions.map((master) => (
      <Option key={master.id} value={master.id}>
        {master.name}
      </Option>
    ))}
  </Select> 
            </Form.Item>
            <Form.Item name="placeofsupply" >
                <Input placeholder="Place of Supply" style={{ width: '40%' , }} />
              </Form.Item>
              <Form.Item name="currency" >
  <Select placeholder="Sales Employee" style={{ width: '40%' }}>
    {salesEmployeeOptions.map((Employee) => (
      <Option key={Employee.id} value={Employee.id}>
        {Employee.name}
      </Option>
    ))}
  </Select>
</Form.Item>

            <Form.Item name="dicount" >
                <Input placeholder="Discount" style={{ width: '80%' }} />
              </Form.Item>
          </Col>
        </Row>

        <Space>
        <Button
  type="primary"
  htmlType="submit"
  style={{ background: 'white', color: 'blue', marginLeft: '400px' }}
  onClick={handlePrintReturn} // Call handlePrintReturn function on button click
>
  <CheckOutlined /> Submit
</Button>
        </Space>
      </Form>
      
      <br></br>
      <div style={{ overflowX: 'auto' }}>
     
     <Modal
      title={`Delete Record - ${recordToDelete ? recordToDelete.date : ''}`}
       visible={deleteModalVisible}
       onOk={handleDeleteConfirm}
       onCancel={handleDeleteCancel}
     >
       <p>Are you sure you want to delete this record?</p>
     </Modal>
     <Modal
       title={`Edit Record - ${recordToEdit ? recordToEdit.date : ''}`}
       visible={editModalVisible}
       onOk={handleEditConfirm}
       onCancel={handleEditCancel}
     >
       {renderEditForm()}
     </Modal>
     <Modal
       title={'View Record - ${recordToView ? recordToView.name : }'}
       visible={viewModalVisible}
       onOk={handleViewConfirm}
       onCancel={handleViewCancel}
     >
       {/* ... (existing code) */}
     </Modal>
   </div>
    <div style={{ overflowX: 'auto' }}>
    <Table columns={columns} dataSource={localData} />
      {/* ... [existing modals] */}
    </div>
    </>
    </Spin>
  );


};

export default App;