import { createSlice } from "@reduxjs/toolkit";

const subtree = createSlice({
  name: "subtree",
  initialState: {
    toggle: false,
  },
  reducers: {
    changetoggle: (state) => {
      state.toggle = !state.toggle;
    },
  },
});

export const  a  = subtree.actions;
export default subtree;