import React, { useState,useRef } from 'react';
import { Form, Input, Button , Spin } from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { Select, DatePicker, Row, Col ,message} from 'antd';
import supabase from "../helper/supabaseClient.js";
import { useEffect } from 'react';

const { Option } = Select;

const CompanyForm = () => {
  const [form] = Form.useForm();
  const [load ,setLoading] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [cities, setCities] = useState([]);
    const [vats, setVats] = useState([]);

  const fetchCities = async () => {
    await supabase.from('City').select('*').eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
        if (error) console.log('error', error);
        else {
          // set cities in city select
          setCities(data);
          
        }
      });
    }


  const fetchVats = async () => {
    await supabase.from('VAT').select('*').eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
        if (error) console.log('error', error);
        else {
          // set vats in vat select
          setVats(data);
          
        }
      });
    }
  

  React.useEffect(() => {
    
    fetchCities();
    fetchVats();
    fetchVendor();
  }
  , []);
  
  const [data, setData] = useState([
    
  ]);
  const handleAdd = () => {
    setSubmitLoading(true);
    try {
    form
      .validateFields()
      .then(async (values) => {
       
       
  
       
        const isDuplicate = data.some((record) => record.name === values.name);
        if (isDuplicate) {
          message.error('Duplicate entry. Please enter a unique name.');
          return;
        }
        // Add other validations as needed for different fields

        await supabase
          .from("vendor")
          .insert([
            {
              name: values.name,
              arabic_name: values.arabicname,
              city: values.city,
              address: values.address,
              description: values.description,
              phone_num: values.phone,
              mobile_num: values.mobile,
              email: values.email,
              contact_person: values.contactperson,
              contact_person_name: values.contactpersonname,
              trn: values.trn,
              credit_limit: values.creditlimit,
              credit_days: values.creditdays,
              vat: values.vat,
              fax: values.faxnumber,
              license_num: values.licensenumber,
              license_expiry: values.licenseexpiry,
            },
          ])
          .then(() => {
            console.log('Data inserted successfully');
          })
          .catch((err) => {
            console.log(err);
          });

        form.resetFields();
      })
      .catch((error) => {
        console.error('Validation failed:', error);
      });
    
    } finally {
                  fetchVendor();
      setSubmitLoading(false);
    }
    fetchVendor();
      
  };

  const fetchVendor = async () => {

    await supabase.from('vendor').select('*').eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
        if (error) console.log('error', error);
        else {
          data.forEach((vendor) => {
            vendor.key = vendor.id;
            vendor.code = vendor.id;
            vendor.arabicname = vendor.arabic_name;
            vendor.mobile = vendor.mobile_num;
            vendor.phone = vendor.phone_num;
            vendor.contactperson = vendor.contact_person;
            vendor.contactpersonname = vendor.contact_person_name;
            vendor.creditlimit = vendor.credit_limit;
            vendor.creditdays = vendor.credit_days;
            vendor.faxnumber = vendor.fax;
            vendor.licensenumber = vendor.license_num;
            vendor.licenseexpiry = vendor.license_expiry;
          });
          
          // set city name instead of city id using supabase lookup
          data.forEach((vendor) => {
            supabase
              .from('City')
              .select('city')
              .eq('id', vendor.city)
              .then(({ data, error }) => {
                if (error) console.log('error', error);
                else {
                  vendor.city = data[0].city;
                }
              });
          }
          );

          // set vat name instead of vat id using supabase lookup
          data.forEach((vendor) => {
            supabase
              .from('VAT')
              .select('name')
              .eq('id', vendor.vat)
              .then(({ data, error }) => {
                if (error) console.log('error', error);
                else {
                  vendor.vat = data[0].name;
                }
              });
          }
          );

          setData(data);
        }
      });
  };
  
  // useEffect(() => {

    
  // }, []);
  
  
  

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    await supabase.from('vendor').delete().match({ id: recordToDelete.key });
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  // const handleEditConfirm = async () => {
  //   const updatedData = data.map((record) =>
  //     record.key === recordToEdit.id ? { ...editedRecord } : record
  //   );
  //   await supabase.from('vendor').update(
  //     {
  //       name: editedRecord.name,
  //       address: editedRecord.address,
  //       mobile_num: editedRecord.mobile,
  //       email: editedRecord.email,
  //       trn: editedRecord.trn,
  //     },
  //   ).match({ id: editedRecord.id });
  //   setEditModalVisible(false);
  //   setRecordToEdit(null);
  //   setEditedRecord(null);
  //   setData(updatedData);
  // };

  const handleEditConfirm = async () => {
    try {
      if (!editedRecord) {
        console.error('No record to edit');
        // Handle error (show message, log, etc.)
        return;
      }
  
      const { id, ...updatedFields } = editedRecord;
  
      const { data: updatedData, error } = await supabase
        .from('vendor')
        .update(updatedFields)
        .match({ id });
  
      if (error) {
        console.error('Error updating record:', error);
        // Handle error (show message, log, etc.)
        return;
      }
  
      // Update the state with the updated data
      setData((prevData) =>
        prevData.map((record) =>
          record.id === id ? { ...record, ...updatedFields } : record
        )
      );
    } catch (error) {
      console.error('Error handling edit:', error);
      // Handle error (show message, log, etc.)
    } finally {
      // Close the modal whether the update was successful or not
      setEditModalVisible(false);
      setRecordToEdit(null);
      setEditedRecord(null);
    }
  };
  
  

  
  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Name: </label>
          <Input
            value={editedRecord?.name}
            onChange={(e) => handleEditInputChange('name', e.target.value)}
          />
        </div>
        <div>
          <label>Arabic Name: </label>
          <Input
            value={editedRecord?.arabicname}
            onChange={(e) => handleEditInputChange('arabicname', e.target.value)}
          />
        </div>
        <div>
          <label>City: </label>
          <Select
            value={editedRecord?.city}
            onChange={(value) => handleEditInputChange('city', value)}
            dropdownStyle={{ minWidth: '200px' }}
          >
            {cities.map((city) => (
              <Option key={city.id} value={city.id}>
                {city.city}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <label>Address: </label>
          <Input
            value={editedRecord?.address}
            onChange={(e) => handleEditInputChange('address', e.target.value)}
          />
        </div>
        <div>
          <label>Description: </label>
          <Input.TextArea
            value={editedRecord?.description}
            onChange={(e) => handleEditInputChange('description', e.target.value)}
          />
        </div>
        <div>
          <label>Phone No: </label>
          <Input
            value={editedRecord?.phone}
            onChange={(e) => handleEditInputChange('phone', e.target.value)}
          />
        </div>
        <div>
          <label>Mobile No: </label>
          <Input
            value={editedRecord?.mobile}
            onChange={(e) => handleEditInputChange('mobile', e.target.value)}
          />
        </div>
        <div>
          <label>Email: </label>
          <Input
            value={editedRecord?.email}
            onChange={(e) => handleEditInputChange('email', e.target.value)}
          />
        </div>
        <div>
          <label>Contact Person: </label>
          <Input
            value={editedRecord?.contactperson}
            onChange={(e) => handleEditInputChange('contactperson', e.target.value)}
          />
        </div>
        <div>
          <label>Contact Person Name: </label>
          <Input
            value={editedRecord?.contactpersonname}
            onChange={(e) => handleEditInputChange('contactpersonname', e.target.value)}
          />
        </div>
        <div>
          <label>TRN NO: </label>
          <Input
            value={editedRecord?.trn}
            onChange={(e) => handleEditInputChange('trn', e.target.value)}
          />
        </div>
        <div>
          <label>Credit Limit: </label>
          <Input
            type="number"
            value={editedRecord?.creditlimit}
            onChange={(e) => handleEditInputChange('creditlimit', e.target.value)}
          />
        </div>
        <div>
          <label>Credit Days: </label>
          <Input
            type="number"
            value={editedRecord?.creditdays}
            onChange={(e) => handleEditInputChange('creditdays', e.target.value)}
          />
        </div>
        <div>
          <label>VAT: </label>
          <Select
            value={editedRecord?.vat}
            onChange={(value) => handleEditInputChange('vat', value)}
            dropdownStyle={{ minWidth: '200px' }}
          >
            {vats.map((vat) => (
              <Option key={vat.id} value={vat.id}>
                {vat.name}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <label>Fax Number: </label>
          <Input
            value={editedRecord?.faxnumber}
            onChange={(e) => handleEditInputChange('faxnumber', e.target.value)}
          />
        </div>
        <div>
          <label>License Number: </label>
          <Input
            value={editedRecord?.licensenumber}
            onChange={(e) => handleEditInputChange('licensenumber', e.target.value)}
          />
        </div>
        <div>
          <label>License Expiry Date: </label>
          <DatePicker
            value={editedRecord?.licenseexpiry}
            onChange={(date) => handleEditInputChange('licenseexpiry', date)}
          />
        </div>
      </div>
    );
  };
  

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: '30%',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Arabic Name',
      dataIndex: 'arabicname',
      key: 'arabicname',
      width: '30%',
      ...getColumnSearchProps('arabicname'),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: '30%',
      ...getColumnSearchProps('city'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '30%',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Phone No',
      dataIndex: 'phone',
      key: 'phone',
      width: '30%',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobile',
      key: 'mobile',
      width: '30%',
      ...getColumnSearchProps('mobile'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Contact Person',
      dataIndex: 'contactperson',
      key: 'contactperson',
      width: '30%',
      ...getColumnSearchProps('contactperson'),
    },
    {
      title: 'Contact Person Name',
      dataIndex: 'contactpersonname',
      key: 'contactpersonname',
      width: '30%',
      ...getColumnSearchProps('contactpersonname'),
    },
    {
      title: 'TRN NO',
      dataIndex: 'trn',
      key: 'trn',
      width: '30%',
      ...getColumnSearchProps('trn'),
    },
    {
      title: 'Credit Limit',
      dataIndex: 'creditlimit',
      key: 'creditlimit',
      width: '30%',
      ...getColumnSearchProps('creditlimit'),
    },
    {
      title: 'Credit Days',
      dataIndex: 'creditdays',
      key: 'creditdays',
      width: '30%',
      ...getColumnSearchProps('creditdays'),
    },
    
    {
      title: 'Vat',
      dataIndex: 'vat',
      key: 'vat',
      width: '30%',
      ...getColumnSearchProps('vat'),
    },
    {
      title: 'Fax Number',
      dataIndex: 'faxnumber',
      key: 'faxnumber',
      width: '30%',
      ...getColumnSearchProps('faxnumber'),
    },
    {
      title: 'License Number',
      dataIndex: 'licensenumber',
      key: 'licensenumber',
      width: '30%',
      ...getColumnSearchProps('licensenumber'),
    },
    {
      title: 'License Expiry Date',
      dataIndex: 'licenseexpiry',
      key: 'licenseexpiry',
      width: '30%',
      ...getColumnSearchProps('licenseexpiry'),
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            {/* <EyeOutlined
              onClick={() => handleView(record)}
              style={{ color: 'blue', marginRight: 8 }}
            /> */}
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },
  
  ];
  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };

  return (
    <Spin spinning={submitLoading}>
    <>
    <div style={{ width: '1000px' }}>
      <h1><b>Your Information</b> </h1>
      <br></br>
      <Form
        form={form}
        name="company_form"
        onFinish={handleAdd}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        {/* Basic Information */}
        <Row gutter={8}>
          <Col span={8}>
        <Form.Item name="name" rules={[{ required: true, message: 'Please enter the company name' }]}>
          <Input placeholder="Name" />
        </Form.Item>
        </Col>
        <Col span={8}>
        <Form.Item name="arabicname">
          <Input placeholder="Name in Arabic" />
        </Form.Item>
        </Col>
        <Col span={8}>
        <Form.Item name="city">
          <Select placeholder="City">
                  {
                    cities.map((city) => (
                      <Option value={city.id}>{city.city}</Option>
                    ))
              }
            </Select>
        </Form.Item>
        </Col>
        </Row>

        <Row gutter={8}>
  <Col span={8}>
    <Form.Item name="address" >
      <Input.TextArea placeholder="Address" rows={4} />
    </Form.Item>
  </Col>

  <Col span={8}>
    <Form.Item name="description" >
      <Input.TextArea placeholder="Description" rows={4} />
    </Form.Item>
  </Col>
</Row>



        {/* Contact Information */}
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="phone">
              <Input type="tel" placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="mobile">
              <Input type="tel" placeholder="Mobile Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="email">
              <Input type="email" placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="contactpersonname">
              <Input placeholder="Contact Person" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="contactPersonname">
              <Input placeholder="Contact Person Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="trn" >
              <Input placeholder="TRN No." />
            </Form.Item>
          </Col>
        </Row>

        {/* Financial Information */}
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="creditlimit" >
              <Input type="number" placeholder="Credit Limit" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="creditdays">
              <Input type="number" placeholder="Credit Days" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="vat">
              <Select placeholder="VAT">
                  {
                    vats.map((vat) => (
                      <Option value={vat.id}>{vat.name}</Option>
                    ))
              }
            </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Additional Information */}
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="faxnumber">
              <Input type="tel" placeholder="Fax Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="licensenumber">
              <Input placeholder="License Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="licenseexpiry">
              <DatePicker placeholder="License Expiry Date" />
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <Form.Item >
        <Button
  type="primary"
  htmlType="submit"
  style={{ background: 'white', color: 'blue' }}
  submitLoading={setSubmitLoading}
>
  <CheckOutlined /> Submit
</Button>
          </Form.Item>
      </Form>
      <div>
        <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <Button type="primary" onClick={handleExportToExcel} style={{ background: 'white', color: 'blue' }}>
          <FileExcelOutlined /> Export to Excel
        </Button>
        
      </div>
    </div>
    <br>
    </br>
    <div style={{ overflowX: 'auto' }}>
    <Table id='table-to-export' columns={columns} dataSource={data} />
    <Modal
      title={`Delete Record - ${recordToDelete ? recordToDelete.name : ''}`}
      visible={deleteModalVisible}
      onOk={handleDeleteConfirm}
      onCancel={handleDeleteCancel}
    >
      <p>Are you sure you want to delete this record?</p>
    </Modal>
    <Modal
      title={`Edit Record - ${recordToEdit ? recordToEdit.name : ''}`}
      visible={editModalVisible}
      onOk={handleEditConfirm}
      onCancel={handleEditCancel}
    >
      {renderEditForm()}
    </Modal>
    <Modal
      title={`View Record - ${recordToView ? recordToView.name : ''}`}
      visible={viewModalVisible}
      onOk={handleViewConfirm}
      onCancel={handleViewCancel}
    >
      {/* ... (existing code) */}
    </Modal>
  </div>
  </>
  </Spin>
  );
};

export default CompanyForm;
