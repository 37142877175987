
import React, { useState,useRef ,useEffect} from 'react';
import { Form, Input, Button ,Spin} from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { Select, DatePicker, Row, Col ,message } from 'antd';
import supabase from "../helper/supabaseClient.js";
import { Group } from 'antd/lib/radio/index.js';
const { TextArea } = Input;
const { Option } = Select;

const BankForm = () => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editedBank, setEditedBank] = useState(null);

  const [banks, setBanks] = useState([]);
  const [accountGroups, setAccountGroups] = useState([]);
  const [accountdebitGroups, setAccountdebitGroups] = useState([]);

  const [currencies, setCurrencies] = useState([]);

  const fetchCurrencies = async () => {
    await supabase
      .from('Currency')
      .select('*')
      .eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
        if (error) console.log('error', error);
        else {
          setCurrencies(data);
        }
      });
  };

  const fetchBanks = async () => {
    await supabase
      .from('bank')
      .select('*').eq('user_id', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
        if (error) console.log('Error fetching banks:', error);
        else {
          console.log('Fetched banks:', data);
          setBanks(data);
        }
      });
  };
  

  const fetchAccountGroups = async () => {
    await supabase
      .from('creditors')
      .select('*')
      .eq('user_id', (await supabase.auth.getUser()).data.user.id).eq('type','group')
      .then(({ data, error }) => {
        if (error) console.log('error', error);
        else {
          setAccountGroups(data);
        }
      });
  };

  const fetchdebitAccountGroups = async () => {
    await supabase
      .from('debitors')
      .select('*')
      .eq('user_id', (await supabase.auth.getUser()).data.user.id).eq('type','group')
      .then(({ data, error }) => {
        if (error) console.log('error', error);
        else {
          setAccountdebitGroups(data);
        }
      });
  };
  useEffect(() => {
    fetchBanks();
    fetchAccountGroups();
    fetchCurrencies();
    fetchdebitAccountGroups(); // Add this line to fetch currencies
  }, []);

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleAdd = async() => {
    setSubmitLoading(true);
    const { data: { user } } = await supabase.auth.getUser();

    const userId = user.id;
    
  
    try {

      form.validateFields().then(async (values) => {
        const bankData = {
          bank_name: values.bank_name,
          acc_name: values.acc_name,
          currency: values.currency,
          acc_no: values.acc_no,
          iban_num: values.iban_num,
          swift_code: values.swift_code,
          address: values.address,
          notes: values.notes,
          account_group: values.account_group,
          user_id:userId,
        };
  
        console.log('Submitting data:', bankData); // Log the data being submitted
  
        console.log('Before Supabase Insert');
        const { banks : createdData, error } =await supabase
          .from('bank')
          .insert([bankData],{ skipRole: true });
  
          if (error) {
            console.log('Error inserting data:', error);
          } else {
            console.log('Data inserted successfully');
            fetchBanks();
            form.resetFields();
            console.log('Success:', values);
            // If you need to update state, do it here
            setBanks((prevData) => [...prevData, createdData]);
          }
          console.log('After Supabase Insert');
        });
      } finally {
        setSubmitLoading(false);
      }
  };
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };
  const handleDeleteConfirm = async () => {
    try {
      const { data, error } = await supabase
        .from('bank')
        .delete()
        .eq('id', recordToDelete.id);

      if (error) {
        console.error('Error deleting data:', error);
      } else {
        console.log('Data deleted successfully');
        fetchBanks();
        setDeleteModalVisible(false);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEdit = (record) => {
    // Set the initial editedBank
    setEditedBank({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };
  const handleEditConfirm = async () => {
    try {
      form.validateFields().then(async (values) => {
        const updatedBankData = {
          bank_name: values.bank_name,
          acc_name: values.acc_name,
          currency: values.currency,
          acc_no: values.acc_no,
          iban_num: values.iban_num,
          swift_code: values.swift_code,
          address: values.address,
          notes: values.notes,
          account_group: values.account_group,
        };

        const { data: updatedData, error } = await supabase
          .from('bank')
          .update(updatedBankData)
          .eq('id', recordToEdit.id);

        if (error) {
          console.log('Error updating data:', error);
        } else {
          console.log('Data updated successfully');
          fetchBanks();
          form.resetFields();
          setEditModalVisible(false);
        }
      });
    } finally {
      setSubmitLoading(false);
    }
  };
  const handleEditCancel = () => {
    setEditModalVisible(false);
    setEditedBank(null);
    setRecordToEdit(null);
  };

  React.useEffect(() => {
    fetchBanks();
  }, []);

  const columns = [
    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
      key: 'bank_name',
      width: '15%',
      ...getColumnSearchProps('bank_name'),
    },
    {
      title: 'Account Name',
      dataIndex: 'acc_name',
      key: 'acc_name',
      width: '15%',
      ...getColumnSearchProps('acc_name'),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      width: '10%',
      ...getColumnSearchProps('currency'),
    },
    {
      title: 'Account No',
      dataIndex: 'acc_no',
      key: 'acc_no',
      width: '15%',
      ...getColumnSearchProps('acc_no'),
    },
    {
      title: 'IBAN Number',
      dataIndex: 'iban_num',
      key: 'iban_num',
      width: '15%',
      ...getColumnSearchProps('iban_num'),
    },
    {
      title: 'Swift Code',
      dataIndex: 'swift_code',
      key: 'swift_code',
      width: '15%',
      ...getColumnSearchProps('swift_code'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '15%',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: '15%',
      ...getColumnSearchProps('notes'),
    },
    {
      title: 'Account Group',
      dataIndex: 'account_group',
      key: 'account_group',
      width: '15%',
      ...getColumnSearchProps('account_group'),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          {/* Add your desired actions here */}
          <EditOutlined
            onClick={() => handleEdit(record)}
            style={{ color: 'blue', marginRight: 8 }}
          />
          <DeleteOutlined
            onClick={() => handleDelete(record)}
            style={{ color: 'blue' }}
          />
        </>
      ),
    },
  ];

  const renderCurrencyOptions = () => {
    return currencies.map((currency) => (
      <Option key={currency.id} value={currency.id}>
        {currency.name}
      </Option>
    ));
  };
  return (
    <Spin spinning={submitLoading}>
      <>
        <div style={{ width: '1000px' }}>
          <h1>
            <b>Bank Information</b>{' '}
          </h1>
          <br></br>
          <Form
            form={form}
            name="bank_form"
            onFinish={handleAdd}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            {/* Basic Information */}
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item
                  name="bank_name"
                  rules={[
                    { required: true, message: 'Please enter the bank name' },
                  ]}
                >
                  <Input placeholder="Bank Name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="acc_name">
                  <Input placeholder="Account Name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="currency">
                <Select placeholder="Currency">
                    {renderCurrencyOptions()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* Additional Fields */}
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item name="acc_no">
                  <Input placeholder="Account No" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="iban_num">
                  <Input placeholder="IBAN Number" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="swift_code">
                  <Input placeholder="Swift Code" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item name="address">
                  <Input placeholder="Address" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="notes">
                  <TextArea placeholder="Notes" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="account_group">
                  <Select placeholder="Account Group">
                    {accountGroups.map((group) => (
                      <Option key={group.id} value={group.title}>
                        {group.title}
                      </Option>
                         ))}
                         {
                      accountdebitGroups.map((groups) => (
                        <Option key={groups.id} value={groups.title}>
                          {groups.title}
                        </Option>
                      ))}
                 
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* Submit Button */}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: 'white', color: 'blue' }}
              >
                <CheckOutlined />
                Submit
              </Button>
            </Form.Item>
            <Modal
              title={`Edit Bank - ${editedBank ? editedBank.bank_name : ''}`}
              visible={editModalVisible}
              onOk={handleEditConfirm}
              onCancel={handleEditCancel}
            >
              {/* Add the form fields for editing here */}
              <Form.Item
                name="bank_name"
                initialValue={editedBank ? editedBank.bank_name : ''}
              >
                <Input placeholder="Bank Name" />
              </Form.Item>
              <Form.Item
                name="acc_name"
                initialValue={editedBank ? editedBank.acc_name : ''}
              >
                <Input placeholder="Account Name" />
              </Form.Item>
              <Form.Item
                name="currency"
                initialValue={editedBank ? editedBank.currency : ''}
              >
                <Select placeholder="Currency">
                  {renderCurrencyOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                name="acc_no"
                initialValue={editedBank ? editedBank.acc_no : ''}
              >
                <Input placeholder="Account No" />
              </Form.Item>
              <Form.Item
                name="iban_num"
                initialValue={editedBank ? editedBank.iban_num : ''}
              >
                <Input placeholder="IBAN Number" />
              </Form.Item>
              <Form.Item
                name="swift_code"
                initialValue={editedBank ? editedBank.swift_code : ''}
              >
                <Input placeholder="Swift Code" />
              </Form.Item>
              <Form.Item
                name="address"
                initialValue={editedBank ? editedBank.address : ''}
              >
                <Input placeholder="Address" />
              </Form.Item>
              <Form.Item
                name="notes"
                initialValue={editedBank ? editedBank.notes : ''}
              >
                <TextArea placeholder="Notes" />
              </Form.Item>
              <Form.Item
                name="account_group"
                initialValue={editedBank ? editedBank.account_group : ''}
              >
                <Select placeholder="Account Group">
                  {accountGroups.map((group) => (
                    <Option key={group.id} value={group.title}>
                      {group.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Modal>
            <Modal
              title={`Delete Bank - ${recordToDelete ? recordToDelete.bank_name : ''}`}
              visible={deleteModalVisible}
              onOk={handleDeleteConfirm}
              onCancel={handleDeleteCancel}
            >
              <p>Are you sure you want to delete this bank record?</p>
            </Modal>
          </Form>
          <div>
            {/* ... (other buttons) */}
          </div>
        </div>
        <br></br>
        <div style={{ overflowX: 'auto' }}>
          <Table id="table-to-export" columns={columns} dataSource={banks} />
          {/* ... (other modals) */}
        </div>
      </>
    </Spin>
  );
};

export default BankForm;