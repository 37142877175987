import React, { useState,useRef } from 'react';
import { Form, Input, Button } from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { UploadOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

const App = () => {
  const [data, setData] = useState([
    {
      key: '1',
      code: '0',
      product: '001',
      name_ara: 'sadii',
      barcode: 'address',
      sell:'0',
      purchase:'0',
    },
    {
      key: '2',
      code: '0',
      product: '001',
      name_ara: 'sadii',
      barcode: 'address',
      sell:'0',
      purchase:'0',
    },
    {
      key: '3',
      code: '0',
      product: '001',
      name_ara: 'sadii',
      barcode: 'address',
      sell:'0',
      purchase:'0',
    },
    {
      key: '4',
      code: '0',
      product: '001',
      name_ara: 'sadii',
      barcode: 'address',
      sell:'0',
      purchase:'0',
    },
  ]);
  const [confirmUploadModalVisible, setConfirmUploadModalVisible] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...editedRecord } : record
    );
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    setData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Code: </label>
          <Input
            value={editedRecord?.code}
            onChange={(e) => handleEditInputChange('code', e.target.value)}
          />
        </div>
        <div>
          <label>Name: </label>
          <Input
            value={editedRecord?.name}
            onChange={(e) => handleEditInputChange('name', e.target.value)}
          />
        </div>
        <div>
          <label>Mobile No: </label>
          <Input
            value={editedRecord?.mobile}
            onChange={(e) => handleEditInputChange('mobile', e.target.value)}
          />
        </div>
        <div>
          <label>Email: </label>
          <Input
            value={editedRecord?.email}
            onChange={(e) => handleEditInputChange('email', e.target.value)}
          />
        </div>
        <div>
          <label>TRN NO: </label>
          <Input
            value={editedRecord?.trn}
            onChange={(e) => handleEditInputChange('trn', e.target.value)}
          />
        </div>
        <div>
          <label>Address: </label>
          <Input
            value={editedRecord?.address}
            onChange={(e) => handleEditInputChange('address', e.target.value)}
          />
        </div>
        <div>
          <label>City: </label>
          <Input
            value={editedRecord?.city}
            onChange={(e) => handleEditInputChange('city', e.target.value)}
          />
        </div>
        <div>
          <label>Account No: </label>
          <Input
            value={editedRecord?.acco}
            onChange={(e) => handleEditInputChange('acco', e.target.value)}
          />
        </div>
        {/* ... Add more fields as needed */}
      </div>
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'code',
      key: 'code',
      width: '30%',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'Product Code',
      dataIndex: 'product',
      key: 'product',
      width: '30%',
      ...getColumnSearchProps('product'),
    },
    {
      title: 'Name in Arabic',
      dataIndex: 'name_ara',
      key: 'name_ara',
      width: '30%',
      ...getColumnSearchProps('name_ara'),
    },
    {
      title: 'Bar Code',
      dataIndex: 'barcode',
      key: 'barcode',
      width: '30%',
      ...getColumnSearchProps('barcode'),
    },
    {
      title: 'Sell Price',
      dataIndex: 'sell',
      key: 'sell',
      width: '30%',
      ...getColumnSearchProps('sell'),
    },
    {
      title: 'Purchase Price',
      dataIndex: 'purchase',
      key: 'purchase',
      width: '30%',
      ...getColumnSearchProps('purchase'),
    },
    
  ];

  const handleUpload = (info) => {
    if (info.fileInput) {
      // Manual file input
      const file = info.file;
      setFileToUpload(file);
      showConfirmUploadModal();
    }
  };

  const showConfirmUploadModal = () => {
    setConfirmUploadModalVisible(true);
  };

  const handleFile = () => {
    try {
      if (fileToUpload) {
        // Parse the Excel file and update the 'data' state
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
          // Assuming your Excel file has headers in the first row
          const headers = excelData[0];
          const parsedData = excelData.slice(1).map((row, index) => {
            const record = {};
            headers.forEach((header, columnIndex) => {
              record[header] = row[columnIndex];
            });
            record.key = `new_${index + 1}`; // Ensure the key is unique
            return record;
          });
  
          setData((prevData) => [...prevData, ...parsedData]);
          message.success(`${fileToUpload.name} file uploaded successfully`);
        };
  
        reader.readAsArrayBuffer(fileToUpload);
      }
    } catch (error) {
      console.error('Error parsing Excel file:', error);
      message.error(`Error parsing ${fileToUpload.name} file`);
    } finally {
      setFileToUpload(null);
      setConfirmUploadModalVisible(false);
    }
  };
  
  const handleManualUpload = () => {
    // This function is called when the "Upload" button is clicked
    // You can trigger a click on the hidden input element to open the file dialog
    const input = document.getElementById('fileInput');
    input.click();
  };
  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };

  return (
    <>
        <div style={{ padding: '20px' }}>
        <input
          id="fileInput"
          type="file"
          accept=".xlsx, .xls"
          style={{ display: 'none' }}
          onChange={(e) =>
            handleUpload({ file: e.target.files[0], fileInput: true })
          }
        />
        <Dragger
  name="file"
  showUploadList={false}
  beforeUpload={(file) => {
    // You can add custom validation logic here
    handleUpload({ file, fileInput: true });
    return false; // Prevent automatic upload
  }}
  style={{ width: '200px', padding: '20px', textAlign: 'center' }}
>
  
  <Button icon={<UploadOutlined />}>
    Choose Excel File
  </Button>
</Dragger>

        <Modal
          title="Confirm Upload"
          visible={confirmUploadModalVisible}
          onCancel={() => setConfirmUploadModalVisible(false)}
          onOk={handleFile}
        >
          <p>Are you sure you want to upload this file?</p>
        </Modal>
        <br></br>
        <Button
          type="primary"
          onClick={handleExportToExcel}
          style={{ background: 'white', color: 'blue' }}
        >
          <FileExcelOutlined /> Export to Excel
        </Button>
        <br></br>
      </div>
    <div style={{ overflowX: 'auto' }}>
      <Table columns={columns} dataSource={data} />
      <Modal
        title={`Delete Record - ${recordToDelete ? recordToDelete.name : ''}`}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={`Edit Record - ${recordToEdit ? recordToEdit.name : ''}`}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
        {renderEditForm()}
      </Modal>
      <Modal
        title={`View Record - ${recordToView ? recordToView.name : ''}`}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >
        {/* ... (existing code) */}
      </Modal>
    </div>
    </>
  );
};

export default App;
