import React, { useState,useRef } from 'react';
import { Form, Input, Button , Spin } from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { Upload, message,Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import supabase from '../../helper/supabaseClient';
import { useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
const { Dragger } = Upload;
const { Option } = Select;

const App = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData] = useState([
    
  ]);
  const [category, setCategory] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [confirmUploadModalVisible, setConfirmUploadModalVisible] = useState(
    false
  );

  const fetchAsset = async () => {
    await supabase.from('Asset').select('*').eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
      const updatedData = data.map((record) => ({
          ...record,
        key: record.id,
        englishname: record.english_name,
        arabicname: record.arabic_name,
        sellingprice: record.selling_price,
        purchaseprice: record.purchase_price,
        salesaccount: record.sales_account,
        purchaseaccount: record.purchase_account,
        amcname: record.amc_company,
        supplier: record.supplier_name,
        depriciation: record.depreciation_year,
        descrip: record.description,
        warranty: record.warranty_date,
      }));

        setData(updatedData);
    });
  };

    const [vats, setVats] = useState([]);
  const [units, setUnits] = useState([]);
  const [countries, setCountries] = useState([]);

  // load data from supabase
  useEffect(() => {
    fetchVats();
    fetchUnits();
    fetchCountries();
    fetchAsset();
  }, []);

  const fetchVats = async () => {
    const { data, error } = await supabase.from('VAT').select();
    if (error) console.log('error', error);
    else setVats(data);
  };

  const fetchUnits = async () => {
    const { data, error } = await supabase.from('Unit').select();
    if (error) console.log('error', error);
    else setUnits(data);
  };

  const fetchCountries = async () => {
    const { data, error } = await supabase.from('Country').select();
    if (error) console.log('error', error);
    else setCountries(data);
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    await supabase.from('Asset').delete().match({ id: recordToDelete.id });
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    fetchAsset();
  };

  const handleEditConfirm = async () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.id ? { ...editedRecord } : record
    );

    await supabase.from('Asset').update([
      {
        code: editedRecord.code,
        english_name: editedRecord.englishname,
        arabic_name: editedRecord.arabicname,
        barcode: editedRecord.barcode,
        selling_price: editedRecord.sellingprice,
        purchase_price: editedRecord.purchaseprice,
        sales_account: editedRecord.salesaccount,
        purchase_account: editedRecord.purchaseaccount,
        specification: editedRecord.specification,
        model: editedRecord.model,
        supplier_name: editedRecord.supplier,
        description: editedRecord.descrip,
      },
    ]).match({ id: recordToEdit.id })
      .then(() => {
        message.success('Asset Updated Successfully');
      })
      .catch((err) => {
        message.error('Error while updating Asset');
        console.log(err);
      });
    

    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    fetchAsset();
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Code: </label>
          <Input
            value={editedRecord?.code}
            onChange={(e) => handleEditInputChange('code', e.target.value)}
          />
        </div>
        <div>
          <label> English Name: </label>
          <Input
            value={editedRecord?.englishname}
            onChange={(e) => handleEditInputChange('englishname', e.target.value)}
          />
        </div>
        <div>
          <label>Arabic Name: </label>
          <Input
            value={editedRecord?.arabicname}
            onChange={(e) => handleEditInputChange('arabicname', e.target.value)}
          />
        </div>
        <div>
          <label>Barcode: </label>
          <Input
            value={editedRecord?.barcode}
            onChange={(e) => handleEditInputChange('barcode', e.target.value)}
          />
        </div>
        <div>
          <label>Selling Price: </label>
          <Input
            value={editedRecord?.sellingprice}
            onChange={(e) => handleEditInputChange('sellingprice', e.target.value)}
          />
        </div>
        <div>
          <label>Purchase Price: </label>
          <Input
            value={editedRecord?.purchaseprice}
            onChange={(e) => handleEditInputChange('purchaseprice', e.target.value)}
          />
        </div>
        <div>
          <label> Sales Account: </label>
          <Input
            value={editedRecord?.salesaccount}
            onChange={(e) => handleEditInputChange('salesaccount', e.target.value)}
          />
        </div>
        <div>
          <label>Purchase Account: </label>
          <Input
            value={editedRecord?.purchaseaccount}
            onChange={(e) => handleEditInputChange('purchaseaccount', e.target.value)}
          />
        </div>
        <div>
          <label>Specification: </label>
          <Input
            value={editedRecord?.specification}
            onChange={(e) => handleEditInputChange('specification', e.target.value)}
          />
        </div>
        <div>
          <label>Model: </label>
          <Input
            value={editedRecord?.model}
            onChange={(e) => handleEditInputChange('model', e.target.value)}
          />
        </div>
        <div>
          <label>Supplier: </label>
          <Input
            value={editedRecord?.supplier}
            onChange={(e) => handleEditInputChange('supplier', e.target.value)}
          />
        </div>
        <div>
          <label>Description: </label>
          <Input
            value={editedRecord?.descrip}
            onChange={(e) => handleEditInputChange('descrip', e.target.value)}
          />
        </div>
      </div>
    );
  };

  const columns = [

    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: '30%',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'English Name',
      dataIndex: 'englishname',
      key: 'englishname',
      width: '30%',
      ...getColumnSearchProps('englishname'),
    },
    {
      title: 'Arabic Name',
      dataIndex: 'arabicname',
      key: 'arabicname',
      width: '30%',
      ...getColumnSearchProps('arabicname'),
    },
    {
      title: 'Bar code',
      dataIndex: 'barcode',
      key: 'barcode',
      width: '30%',
      ...getColumnSearchProps('barcode'),
    },
    {
      title: 'Selling Price',
      dataIndex: 'sellingprice',
      key: 'sellingprice',
      width: '30%',
      ...getColumnSearchProps('sellingprice'),
    },
    {
      title: 'Purchase Price',
      dataIndex: 'purchaseprice',
      key: 'purchaseprice',
      width: '30%',
      ...getColumnSearchProps('purchaseprice'),
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      key: 'vat',
      width: '30%',
      ...getColumnSearchProps('vat'),
    },
    {
        title: 'Sales Account',
        dataIndex: 'salesaccount',
        key: 'salesaccount',
        width: '30%',
        ...getColumnSearchProps('salesaccount'),
      },
      {
        title: 'Purchase Account',
        dataIndex: 'purchaseaccount',
        key: 'purchaseaccount',
        width: '30%',
        ...getColumnSearchProps('purchaseaccount'),
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        key: 'unit',
        width: '30%',
        ...getColumnSearchProps('unit'),
      },

      {
        title: 'Specification',
        dataIndex: 'specification',
        key: 'specification',
        width: '30%',
        ...getColumnSearchProps('specification'),
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        width: '30%',
        ...getColumnSearchProps('model'),
      },
      {
        title: 'AMC Company',
        dataIndex: 'amcname',
        key: 'amcname',
        width: '30%',
        ...getColumnSearchProps('amcname'),
      },
      {
        title: 'Supplier Name',
        dataIndex: 'supplier',
        key: 'supplier',
        width: '30%',
        ...getColumnSearchProps('supplier'),
      },
      {
        title: 'Depriciation Year',
        dataIndex: 'depriciation',
        key: 'depriciation',
        width: '30%',
        ...getColumnSearchProps('depriciation'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '30%',
        ...getColumnSearchProps('description'),
      },
      {
        title: 'Waranty Date',
        dataIndex: 'warranty',
        key: 'warranty',
        width: '30%',
        ...getColumnSearchProps('warranty'),
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        width: '30%',
        ...getColumnSearchProps('country'),
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            {/* <EyeOutlined
              onClick={() => handleView(record)}
              style={{ color: 'blue', marginRight: 8 }}
            /> */}
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },
   
  
  ];

  const handleUpload = (info) => {
    if (info.fileInput) {
      // Manual file input
      const file = info.file;
      setFileToUpload(file);
      showConfirmUploadModal();
    }
  };

  const showConfirmUploadModal = () => {
    setConfirmUploadModalVisible(true);
  };


  const requiredColums = ['code', 'englishname', 'arabicname', 'barcode', 'sellingprice', 'purchaseprice', 'salesaccount', 'purchaseaccount', 'specification', 'model', 'supplier', 'descrip'];

  const handleFile = () => {
    try {
      if (fileToUpload) {
        // Parse the Excel file and update the 'data' state
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
          // Assuming your Excel file has headers in the first row
          const headers = excelData[0];

          // Check if the required columns are present
          const missingColumns = requiredColums.filter(
            (column) => !headers.includes(column)
          );

          if (missingColumns.length) {
            message.error(
              `The following columns are missing: ${missingColumns.join(
                ', '
              )}`
            );
            return;
          }

          const parsedData = excelData.slice(1).map(async (row, index) => {
            const record = { category }; // Initialize with the selected category
            headers.forEach((header, columnIndex) => {
              record[header] = row[columnIndex];
            });
            

            // check if vat exists
            const vat = vats.find((vat) => vat.id === record.vat);
            if (!vat) {
              message.error(
                `VAT ${record.vat} does not exist in the database`
              );
              return;
            }

            // check if unit exists
            const unit = units.find((unit) => unit.id === record.unit);
            if (!unit) {
              message.error(
                `Unit ${record.unit} does not exist in the database`
              );
              return;
            }


            // check if country exists
            const country = countries.find(
              (country) => country.id === record.country
            );
            if (!country) {
              message.error(
                `Country ${record.country} does not exist in the database`
              );
              return;
            }

            // add record to database
            await supabase.from('Asset').insert([
              {
                code: record.code,
                english_name: record.englishname,
                arabic_name: record.arabicname,
                barcode: record.barcode,
                selling_price: record.sellingprice,
                purchase_price: record.purchaseprice,
                vat: record.vat,
                sales_account: record.salesaccount,
                purchase_account: record.purchaseaccount,
                unit: record.unit,
                specification: record.specification,
                model: record.model,
                amc_company: record.amcname,
                supplier_name: record.supplier,
                depreciation_year: record.depriciation,
                description: record.descrip,
                warranty_date: record.warranty,
                country: record.country,
              },
            ]);

          });
  
          setSubmitLoading(true);
          Promise.all(parsedData).then(() => {
            fetchAsset();
            setSubmitLoading(false);
          });
          message.success(`${fileToUpload.name} file uploaded successfully`);
        };
  
        reader.readAsArrayBuffer(fileToUpload);
      }
    } catch (error) {
      console.error('Error parsing Excel file:', error);
      message.error(`Error parsing ${fileToUpload.name} file`);
    } finally {
      setFileToUpload(null);
      setConfirmUploadModalVisible(false);
    }
  };
  
  const handleFinish = (values) => {
    setSubmitLoading(true);
  
    try {
    // Update the category in the data array
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key
        ? { ...record, category }
        : record
    );
    setData(updatedData);
  } finally {
    setSubmitLoading(false);
  }
  };
  
 

  
  const handleManualUpload = () => {
    // This function is called when the "Upload" button is clicked
    // You can trigger a click on the hidden input element to open the file dialog
    const input = document.getElementById('fileInput');
    input.click();
  };
  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
 };
 

  const isUploadButtonDisabled = !category;
  return (
    <Spin spinning={submitLoading}>
    <>
    {/* <div style={{ backgroundColor: 'yellow', padding: '10px', textAlign: 'center' }}>
      <strong>Under Construction:</strong> This page is currently under construction.
    </div> */}
    
     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Form onFinish={handleFinish}>
          <Form.Item name="category">
            <Select
              placeholder="Select Category"
              style={{ width: '150px' }}
              onChange={handleCategoryChange}
            >
              <Option value="c1">Category 1</Option>
              <Option value="c2">Category 2</Option>
              {/* Add more options as needed */}
            </Select>
          </Form.Item>
          
        </Form>
      </div>

      {/* File input and Dragger components */}
      <div style={{  }}>
        <input
          id="fileInput"
          type="file"
          accept=".xlsx, .xls"
          style={{ display: 'none' }}
          onChange={(e) =>
            handleUpload({ file: e.target.files[0], fileInput: true })
          }
        />
        <Dragger
          name="file"
          showUploadList={false}
          beforeUpload={(file) => {
            handleUpload({ file, fileInput: true });
            return false; // Prevent automatic upload
          }}
          style={{ width: '200px', padding: '20px', textAlign: 'center' }}
        >
          <Button icon={<UploadOutlined />}>Choose Excel File</Button>
        </Dragger>

        <Modal
          title="Confirm Upload"
          visible={confirmUploadModalVisible}
          onCancel={() => setConfirmUploadModalVisible(false)}
          onOk={handleFile}
        >
          <p>Are you sure you want to upload this file?</p>
        </Modal>
        <br></br>
        <Button
          type="primary"
          onClick={handleExportToExcel}
          style={{ background: 'white', color: 'blue' }}
        >
          <FileExcelOutlined /> Export to Excel
        </Button>
        <br></br>
      </div>

      {/* Additional Form */}
      <div style={{ marginBottom: '20px' }}>
        <Form
          onFinish={handleFinish}
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '8px',
          }}
        >
          {/* ... (existing code) */}
        </Form>
      </div>
    <div style={{ overflowX: 'auto' }} className="table-container">
      <Table columns={columns} dataSource={data} />
      <Modal
        title={`Delete Record - ${recordToDelete ? recordToDelete.code : ''}`}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={`Edit Record - ${recordToEdit ? recordToEdit.code : ''}`}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
        {renderEditForm()}
      </Modal>
      <Modal
        title={`View Record - ${recordToView ? recordToView.code : ''}`}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >
        {/* ... (existing code) */}
      </Modal>
      
    </div>
    </>
    </Spin>
  );

};

export default App;
