import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import home1 from "./components/Home/App";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import { UserSettingsProvider } from "./pages/UserSettingsContext";
import table_Country from "./components/Tables/table_Country";
import table_City from "./components/Tables/table_City";
import table_unit from "./components/Tables/table_unit";
import table_vat from "./components/Tables/table_vat";
import table_currency from "./components/Tables/table_currency";
import table_Customers from "./components/Tables/table_Customers";
import table_vendors from "./components/Tables/table_vendors";
import table_customerupload from "./components/Tables/table_customerupload";
import table_expenses from "./components/Tables/table_expenses";
import table_productlist from "./components/Tables/table_productlist";
import table_assetlist from "./components/Tables/table_assetlist";
import table_servicelist from "./components/Tables/table_servicelist";
import table_productupload from "./components/Tables/table_productupload";
import table_producttypes from "./components/Tables/table_producttypes";
import table_productcolor from "./components/Tables/table_productcolor";
import Accounttypes from "./pages/Accounttypes";
import table_voucherdocs from "./components/Tables/table_voucherdocs";
import table_purchaserequest from "./components/Tables/table_purchaserequest";
import table_purchasequotation from "./components/Tables/table_purchasequotation";
import table_purchaseorder from "./components/Tables/table_purchaseorder";
import table_purchaserecived from "./components/Tables/table_purchaserecived";
import table_purchaseinvoice from "./components/Tables/table_purchaseinvoice";
import table_purchasereturn from "./components/Tables/table_purchasereturn"
import table_initialbalance from "./components/Tables/table_initialbalance";
import table_journalvoucher from "./components/Tables/table_journalvoucher";
import table_companyexpenses from "./components/Tables/table_companyexpenses";
import table_cashpayment from "./components/Tables/tabel_cashpayment";
import table_pendingpdcs from "./components/Tables/table_pendingpdcs";
import table_opening from "./components/Tables/table_opening";
import table_serviceupload from "./components/Tables/table_serviceupload";
import table_assetupload from "./components/Tables/table_assetupload";
import table_employees from "./components/Tables/table_employees";
import AlRwanFoodstuffTrading from "./pages/AlRwanFoodstuffTrading";

import AlWakeelAlmumyazTechnicalsevices from "./pages/AlWakeelAlmumyazTechnicalsevices";
import Assetlist from "./pages/Assetlist";
import Assets from "./pages/Assets";
import cashpayment from "./pages/cashpayment";
import Cashreceipt from "./pages/Cashreceipt";
import Chartofaccount from "./pages/Chartofaccount";
import UserProfile from "./pages/UserProfile";
import creditors from "./pages/Creditors";
import debitors from "./pages/Debitors";
import chequepayment from "./pages/chequepayment";
import chequereceipt from "./pages/chequereceipt";
import City from "./pages/City";
import companyexpense from "./pages/companyexpense";
import CostCenters from "./pages/CostCenters";
import country from "./pages/Country";
import Currency from "./pages/Currency";
import CurrencyConvertion from "./pages/CurrencyConvertion";
import Customers from "./pages/Customers";
import Customerupload from "./pages/Customerupload";
import Employees from "./pages/Employees";
import Expenses from "./pages/Expenses";
import Fundpayment from "./pages/Fundpayment";
import Fundreceipt from "./pages/Fundreceipt";
import journalvoucher from "./pages/journalvoucher";
import ManarahAlShamVegetableAndFruitTradingLLC from "./pages/ManarahAlShamVegetableAndFruitTradingLLC";
import ManaratHoranFoodstuffTradingLLC from "./pages/ManaratHoranFoodstuffTradingLLC";
import openingbalance from "./pages/openingbalance";
// import openingstock from "./pages/openingstock";
import OscarVegetableandFruitsLLC from "./pages/OscarVegetableandFruitsLLC";
import pendingpdcs from "./pages/pendingpdcs";
import processedpdcs from "./pages/processedpdcs";
import productcolor from "./pages/productcolor";
import Products from "./pages/Products";
import Productslist from "./pages/Productslist";
import Producttype from "./pages/Producttype";
import productupdate from "./pages/productupdate";
import Productupload from "./pages/Productupload";
import Purchase from "./pages/Purchase";
import purchaseinvoice from "./pages/purchaseinvoice";
import purchaseorder from "./pages/purchaseorder";
import purchasequotation from "./pages/purchasequotation";
import purchasereceived from "./pages/purchasereceived";
import purchaserequest from "./pages/purchaserequest";
import purchasereturn from "./pages/purchasereturn";
//import reconcileChequepayment from "./pages/reconcileChequepayment";
//import reconcilecheckreceipt from "./pages/reconcilecheckreceipt";
import sales from "./pages/Sales";
import Salesenquiry from "./pages/Salesenquiry";
import SalesInvoice from "./pages/SalesInvoice";
import Salesnote from "./pages/Salesnote";
import Salesorder from "./pages/Salesorder";
import SalesQuootation from "./pages/SalesQuotations";
import salesreturn from "./pages/salesreturn";
import Servicelist from "./pages/Servicelist";
import Services from "./pages/Services";

import Unite from "./pages/Unite";
import vat from "./pages/Vat";
import Vendors from "./pages/Vendors";
import VoucherDocs from "./pages/VoucherDocs"
import assetT from "./pages/AssetsT"
import serviT from "./pages/ServicesT"
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { Table } from "antd";
import { useEffect } from "react";
import { createClient } from '@supabase/supabase-js'
import { useState } from "react";
import ForgetPasswordPage from "./pages/ForgotPage";
import ResetPassword from "./pages/resetpassword";
import Homess from "./components/Home/App";
//import Pricings from "./pages/Pricing";
import Master from "./pages/master";
import Subscription from "./components/Subscription/Subscription";

import openingstock from "./pages/openingstock";
import stockissue from "./pages/stockissue";
import stockreceive from "./pages/stockreceive";

import Pricing from "./pages/pricing";
import Bank from "./pages/Bank";

import ProfitLoss from "./pages/ProfitLoss"
import BalanceSheet from "./pages/BalanceSheet"

import supabase from "./helper/supabaseClient";

const UnprotectedURLs = ({ data, error }) => {

  
  
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  // if both data and error are null, return
  console.log("isauthencaisted ",isAuthenticated)
  if (data == null && error == null) return null;
  console.log(data, error);
  if (data.user == null) {
    console.log("IF");
    return (
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Route path="/" exact component={Homess} />
        <Route path="/pricing" exact component={Pricing} />
        <Route path="/forgot" exact component={ForgetPasswordPage} />
        <Route path="*" exact component={SignIn} />
        <Route path="*" exact component={SignIn} />

      </Switch>
    )
  }
  
  else if(isAuthenticated && data.user != null ) {
    console.log("ELSE");
    return (
      <Switch>
          <Route path=  "/resetpassword" component={ResetPassword} />
          <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Route path="/" exact component={Homess} />
        <Route path="/forgot" exact component={ForgetPasswordPage} />
        <Route path="/pricing" exact component={Pricing} />

       


        
        <Main>
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/Subscription" component={Subscription} />
        <Route path="/" exact component={Homess} />
        <Route path="/Bank" exact component={Bank} />
         
        <Route path="/BalanceSheet" exact component={BalanceSheet} />
        <Route path="/ProfitLoss" exact component={ProfitLoss} />

          <Route exact path="/UserProfile" component={UserProfile} />
          <Route exact path="/Accounttypes" component={Accounttypes} />
          <Route exact path="/AlRwanFoodstuffTrading" component={table_purchaserequest} />
          <Route exact path="/AlWakeelAlmumyazTechnicalsevices" component={table_purchaserequest} />
          <Route exact path="/Assetlist" component={Assetlist} />
          <Route exact path="/Assets" component={table_assetupload} />
          <Route exact path="/AssetsT" component={assetT} />
          <Route exact path="/cashpayment" component={cashpayment} />
          <Route exact path="/Cashreceipt" component={Cashreceipt} />
          <Route exact path="/Chartofaccount" component={Chartofaccount} />
          <Route exact path="/chequepayment" component={chequepayment} />
          <Route exact path="/chequereceipt" component={chequereceipt} />
          <Route exact path="/City" component={City} />
          <Route exact path="/companyexpense" component={table_companyexpenses} />
          <Route exact path="/CostCenters" component={CostCenters} />
          <Route exact path="/Country" component={country} />
          <Route exact path="/Currency" component={Currency} />
          <Route exact path="/CurrencyConversion" component={CurrencyConvertion} />
          <Route exact path="/Customers" component={Customers} />
          <Route exact path="/Customerupload" component={Customerupload} />
          <Route exact path="/Creditors" component={creditors} />
          <Route exact path="/Debitors" component={debitors} />
          <Route exact path="/Employees" component={Employees} />
          <Route exact path="/Expenses" component={Expenses} />
          <Route exact path="/Fundpayment" component={Fundpayment} />
          <Route exact path="/Fundreceipt" component={Fundreceipt} />
          <Route exact path="/journalvoucher" component={journalvoucher} />
          <Route exact path="/ManarahAlShamVegetableAndFruitTradingLLC" component={table_purchaserequest} />
          <Route exact path="/ManaratHoranFoodstuffTradingLLC" component={table_purchaserequest} />
          <Route exact path="/openingbalance" component={openingbalance} />
          <Route exact path="/openingstock" component={openingstock} />
          <Route exact path="/OscarVegetableandFruitsLLC" component={table_purchaserequest} />
          <Route exact path="/pendingpdcs" component={pendingpdcs} />
          <Route exact path="/processedpdcs" component={processedpdcs} />
          <Route exact path="/productcolor" component={productcolor} />
          <Route exact path="/Products" component={Products} />
          <Route exact path="/Productslist" component={Productslist} />
          <Route exact path="/ProductMaster" component={Producttype} />
          <Route exact path="/Productupload" component={Productupload} />
          <Route exact path="/Purchase" component={Tables} />
          <Route exact path="/purchaseinvoice" component={purchaseinvoice} />
          <Route exact path="/purchaseorder" component={purchaseorder} />
          <Route exact path="/purchasequotation" component={purchasequotation} />
          <Route exact path="/purchasereceived" component={purchasereceived} />
          <Route exact path="/purchaserequest" component={purchaserequest} />
          <Route exact path="/purchasereturn" component={purchasereturn} />
          {/* <Route exact path="/reconcileChequepayment" component={reconcileChequepayment}/>
          <Route exact path="/reconcilecheckreceipt" component={reconcilecheckreceipt}/> */}
          <Route exact path="/Sales" component={Tables} />
          <Route exact path="/Salesenquiry" component={Salesenquiry} />
          <Route exact path="/SalesInvoice" component={SalesInvoice} />
          <Route exact path="/Salesnote" component={Salesnote} />
          <Route exact path="/Salesorder" component={Salesorder} />
          <Route exact path="/SalesQuotations" component={SalesQuootation} />
          <Route exact path="/salesreturn" component={salesreturn} />
          <Route exact path="/Servicelist" component={Servicelist} />
          <Route exact path="/Services" component={table_serviceupload} />
          <Route exact path="/ServicesT" component={serviT} />
          <Route exact path="/stockissue" component={stockissue} />
          <Route exact path="/stockreceive" component={stockreceive} />
          <Route exact path="/Unit" component={Unite} />
          <Route exact path="/Vat" component={vat} />
          <Route exact path="/Vendors" component={Vendors} />
          <Route exact path="/VoucherDocs" component={VoucherDocs} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/Master" component={Master} />

        </Main>
      </Switch>
    )
  }

  else if(data.user != null){
    return (
      <Switch>
      <Route path="/pricing" exact component={Pricing} />
      <Route path=  "/resetpassword" component={ResetPassword} />
      <Route path="/sign-up" exact component={SignUp} />
      <Route path="/sign-in" exact component={SignIn} />
      <Route path="/" exact component={Homess} />
      <Route path="/forgot" exact component={ForgetPasswordPage} />
      <Main>
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/Subscription" component={Subscription} />
        <Route path="/" exact component={Homess} />
        <Route path="/Bank" exact component={Bank} />
 <Route path="/BalanceSheet" exact component={BalanceSheet} />
        <Route path="/ProfitLoss" exact component={ProfitLoss} />
         
          <Route exact path="/UserProfile" component={UserProfile} />
          <Route exact path="/Accounttypes" component={Accounttypes} />
          <Route exact path="/AlRwanFoodstuffTrading" component={table_purchaserequest} />
          <Route exact path="/AlWakeelAlmumyazTechnicalsevices" component={table_purchaserequest} />
          <Route exact path="/Assetlist" component={Assetlist} />
          <Route exact path="/Assets" component={table_assetupload} />
          <Route exact path="/AssetsT" component={assetT} />
          <Route exact path="/cashpayment" component={cashpayment} />
          <Route exact path="/Cashreceipt" component={Cashreceipt} />
          <Route exact path="/Chartofaccount" component={Chartofaccount} />
          <Route exact path="/chequepayment" component={chequepayment} />
          <Route exact path="/chequereceipt" component={chequereceipt} />
          <Route exact path="/City" component={City} />
          <Route exact path="/companyexpense" component={table_companyexpenses} />
          <Route exact path="/CostCenters" component={CostCenters} />
          <Route exact path="/Country" component={country} />
          <Route exact path="/Currency" component={Currency} />
          <Route exact path="/CurrencyConversion" component={CurrencyConvertion} />
          <Route exact path="/Customers" component={Customers} />
          <Route exact path="/Customerupload" component={Customerupload} />
          <Route exact path="/Creditors" component={creditors} />
          <Route exact path="/Debitors" component={debitors} />
          <Route exact path="/Employees" component={Employees} />
          <Route exact path="/Expenses" component={Expenses} />
          <Route exact path="/Fundpayment" component={Fundpayment} />
          <Route exact path="/Fundreceipt" component={Fundreceipt} />
          <Route exact path="/journalvoucher" component={journalvoucher} />
          <Route exact path="/ManarahAlShamVegetableAndFruitTradingLLC" component={table_purchaserequest} />
          <Route exact path="/ManaratHoranFoodstuffTradingLLC" component={table_purchaserequest} />
          <Route exact path="/openingbalance" component={openingbalance} />
          <Route exact path="/openingstock" component={openingstock} />
          <Route exact path="/OscarVegetableandFruitsLLC" component={table_purchaserequest} />
          <Route exact path="/pendingpdcs" component={pendingpdcs} />
          <Route exact path="/processedpdcs" component={processedpdcs} />
          <Route exact path="/productcolor" component={productcolor} />
          <Route exact path="/Products" component={Products} />
          <Route exact path="/Productslist" component={Productslist} />
          <Route exact path="/ProductMaster" component={Producttype} />
          <Route exact path="/Productupload" component={Productupload} />
          <Route exact path="/Purchase" component={Tables} />
          <Route exact path="/purchaseinvoice" component={purchaseinvoice} />
          <Route exact path="/purchaseorder" component={purchaseorder} />
          <Route exact path="/purchasequotation" component={purchasequotation} />
          <Route exact path="/purchasereceived" component={purchasereceived} />
          <Route exact path="/purchaserequest" component={purchaserequest} />
          <Route exact path="/purchasereturn" component={purchasereturn} />
          {/* <Route exact path="/reconcileChequepayment" component={reconcileChequepayment}/>
          <Route exact path="/reconcilecheckreceipt" component={reconcilecheckreceipt}/> */}
          <Route exact path="/Sales" component={Tables} />
          <Route exact path="/Salesenquiry" component={Salesenquiry} />
          <Route exact path="/SalesInvoice" component={SalesInvoice} />
          <Route exact path="/Salesnote" component={Salesnote} />
          <Route exact path="/Salesorder" component={Salesorder} />
          <Route exact path="/SalesQuotations" component={SalesQuootation} />
          <Route exact path="/salesreturn" component={salesreturn} />
          <Route exact path="/Servicelist" component={Servicelist} />
          <Route exact path="/Services" component={table_serviceupload} />
          <Route exact path="/ServicesT" component={serviT} />
          <Route exact path="/stockissue" component={stockissue} />
          <Route exact path="/stockreceive" component={stockreceive} />
          <Route exact path="/Unit" component={Unite} />
          <Route exact path="/Vat" component={vat} />
          <Route exact path="/Vendors" component={Vendors} />
          <Route exact path="/VoucherDocs" component={VoucherDocs} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/Master" component={Master} />

        </Main>
      </Switch>
    )
  }
  else {
    console.log("else");
    return (
      <Switch>
        {/* <Route exact path="/home1" component={home1}/> */}
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Route path="/pricing" exact component={Pricing} />

        <Route path="/forgot" exact component={ForgetPasswordPage} />
        <Route path="*" exact component={SignIn} />
      </Switch>

    )
  }
}


function App() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);



  const setAuth = async () => {
    const supabase = createClient(
      process.env.REACT_APP_SUPABASE_URL,
      process.env.REACT_APP_SUPABASE_ANON_KEY
    );

    const { data, error } = await supabase.auth.getUser();
    // wait for the promise to resolve before using the data
    setData(data);
    setError(error);

  };

  // run useEffect only once, when the component is mounted
  // run the function setAuth before returning the component
  useEffect(() => {
    setAuth();
  }, []);




  return (
    <div className="App">



      <UnprotectedURLs data={data} error={error} />



    </div>
  );
}

export default App;