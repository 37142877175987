import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, message, Modal } from 'antd';
import 'antd/dist/antd.css';
import TopNavbar from "../components/Home/components/Nav/TopNavbar";
import Footer from "../components/Home/components/Sections/Footer";

import supabase from "../helper/supabaseClient.js";

const ForgetPasswordPage = () => {
    const [showResetModal, setShowResetModal] = useState(false);
    const [resetError, setResetError] = useState(null);
    const [email, setEmail] = useState('');

    useEffect(() => {
        return () => {
            // Cleanup function to be executed when the component is unmounted
            setResetError(null);
            setEmail('');
        };
    }, []);

    const onFinish = async (values) => {
        try {
            // Send password reset email
            const { data, error } = await supabase.auth.resetPasswordForEmail(values.email);
            //  alert("skdakkmdsak")
            if (error) {
                console.error('Error:', error.message);
                message.error('Failed to send password reset email. Please check your email address.');
            } else {
                message.success('Password reset email sent successfully!');
                setEmail(values.email);
                // setShowResetModal(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setResetError('An error occurred during password reset. Please try again.');
        }
    };

    const handlePasswordReset = async (newPassword, confirmPassword) => {
        try {
            // Check if new password and confirm password match
            if (newPassword === confirmPassword) {
                // Implement password reset logic here
                // ...

                message.success('Password reset successfully!');
                setShowResetModal(false);
            } else {
                setResetError('Passwords do not match. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setResetError('An error occurred during password reset. Please try again.');
        }
    };

    return (
        <>
            <TopNavbar />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Card title="Forget Password" style={{ width: '500px', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                    <Form
                        name="forgetPassword"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        style={{ maxWidth: '300px', margin: '0 auto' }}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not a valid email address',
                                },
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                Reset Password
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>



            {/* Error Modal */ }
    {
        resetError && (
            <Modal
                title="Password Reset Error"
                visible={true}
                onCancel={() => setResetError(null)}
                footer={null}
            >
                <p>{resetError}</p>
            </Modal>
        )
    }
    <Footer />
        </>
    );
};

export default ForgetPasswordPage;