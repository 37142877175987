import React from "react";
import { Row, Col, Typography, Button, Space } from "antd";
import "antd/dist/antd.css"; // Import Ant Design styles
import PricingTable from "../Elements/PricingTable";

const { Title } = Typography;

const buyButtonStyle = {
  backgroundColor: "#1890ff",
  color: "#fff",
  border: "none",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
};

export default function Pricing() {
  return (
    <div id="pricing" style={{ padding: "50px 0", backgroundColor: "#f8f8f8" }}>
      <div className="container">
        <div style={{ marginBottom: "50px", textAlign: "center" }}>
          <Title level={2} className="font40 extraBold">
            Check Our Pricing
          </Title>
        </div>
        <Row
          gutter={[16, 16]}
          justify="space-around"
          style={{ justifyContent: "center" }} // Add this style
        >
          <Col span={8}>
            <PricingTable
              icon="roller"
              price="$29.99/mo"
              title="Starter"
              text=""
              offers={[
                { name: "Account Management", checked: true },
                { name: "Inventory Management", checked: true },
                { name: "PDC Management", checked: true },
                { name: "Voucher Management", checked: true },
                { name: "Reports and Graphs", checked: true },
                { name: "Slow Support", checked: true },
              ]}
              action={() => alert("clicked")}
            />
          </Col>
          <Col span={8}>
            <PricingTable
              icon="monitor"
              price="$44.99/mo"
              title="Standard"
              text=""
              offers={[
                { name: "Account Management", checked: true },
                { name: "Inventory Management", checked: true },
                { name: "PDC Management", checked: true },
                { name: "Voucher Management", checked: true },
                { name: "Reports and Graphs", checked: true },
                { name: "Priority Support", checked: true },
              ]}
              action={() => alert("clicked")}
            />
          </Col>
          <Col span={8}>
            <PricingTable
              icon="browser"
              price="$59.99/mo"
              title="Premium"
              text=""
              offers={[
                { name: "Account Management", checked: true },
                { name: "Inventory Management", checked: true },
                { name: "PDC Management", checked: true },
                { name: "Voucher Management", checked: true },
                { name: "Reports and Graphs", checked: true },
                { name: "Instant Support", checked: true },
              ]}
              action={() => alert("clicked")}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

