import React , {useState} from "react";
import { Row, Col, Typography, Button, Space } from "antd";
import "antd/dist/antd.css"; // Import Ant Design styles
import PricingTable from "../components/Home/components/Elements/PricingTable";

import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import zone from "../assets/images/zone.png";

import {
    Layout,
    Menu,
    Form,
    Input,
    Switch,
  } from "antd";

import { Link } from "react-router-dom";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;
const template = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
      fill="#111827"
      className="fill-muted"
    ></path>
    <path
      data-v-4ebdc598=""
      d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const profile = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];
const signup = [
  <svg
    data-v-4ebdc598=""
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-4ebdc598=""
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
      fill="#111827"
      className="fill-muted"
    ></path>
  </svg>,
];

const buyButtonStyle = {
  backgroundColor: "#1890ff",
  color: "#fff",
  border: "none",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
};

export default function Pricing() {
const stripePromise = loadStripe("pk_test_51ODucNSBUBnZdF2vvqkzN3NNnk6fVKEwo9wMv2Sf0MBzJh3Pyq962aLfPM1AG0xVP7RyooZ4V7cIvD7XY2GZXuiO00gm3QoSIW");

const [loading, setLoading] = useState(false);

const handleClick = async (event) => {
  try {
    setLoading(true);

    const stripe = await stripePromise;

    const response = await axios.post(
      'https://alpha-payment-backend.vercel.app/api/stripe',
      { priceId: 'price_1OEeXsSBUBnZdF2vyNLNUh9T' }
    );

    const session = response.data;

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.aud);
      alert(result.aud);
    } else {
      alert(result.aud);
    }
  } catch (error) {
    console.error("Error during checkout:", error);
  } finally {
    setLoading(false);
  }
};

return (
    <>
     <Layout className="layout-default layout-signin">
          <Header>
            <div className="header-col header-brand dsn">
            <img src={zone} alt="" width={75} />
            </div>
            <div className="header-col header-nav">
              <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
                <Menu.Item key="1">
                  <Link to="/">
                    {template}
                    <span> Home</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/profile">
                    {profile}
                    <span>Profile</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="9">
                  <Link to="/sign-up">
                    {signup}
                    <span> Sign Up</span>
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="4">
                  <Link to="/sign-in">
                    {signin}
                    <span> Sign In</span>
                  </Link>
                </Menu.Item> */}
              </Menu>
            </div>
            <div className="header-col header-btn dsn">
              <Button type="primary">Quick Overview</Button>
            </div>
          </Header>
          </Layout>

    <div id="pricing" style={{ padding: "50px 0", backgroundColor: "#f8f8f8" }}>
      <div className="container">
        <div style={{ marginBottom: "50px", textAlign: "center" }}>
          <Title level={2} className="font40 extraBold">
            Check Our Pricing
          </Title>
        </div>
        <Row
          gutter={[16, 16]}
          justify="space-around"
          style={{ justifyContent: "center" }} // Add this style
        >
          <Col span={8}>
            <PricingTable
              icon="roller"
              price="$29.99/mo"
              title="Starter"
              text=""
              offers={[
                { name: "Account Management", checked: true },
                { name: "Inventory Management", checked: true },
                { name: "PDC Management", checked: true },
                { name: "Voucher Management", checked: true },
                { name: "Reports and Graphs", checked: true },
                { name: "Slow Support", checked: true },
              ]}
              action={() => handleClick()}
            />
          </Col>
          <Col span={8}>
            <PricingTable
              icon="monitor"
              price="$44.99/mo"
              title="Standard"
              text=""
              offers={[
                { name: "Account Management", checked: true },
                { name: "Inventory Management", checked: true },
                { name: "PDC Management", checked: true },
                { name: "Voucher Management", checked: true },
                { name: "Reports and Graphs", checked: true },
                { name: "Priority Support", checked: true },
              ]}
              action={() =>handleClick()}
            />
          </Col>
          <Col span={8}>
            <PricingTable
              icon="browser"
              price="$59.99/mo"
              title="Premium"
              text=""
              offers={[
                { name: "Account Management", checked: true },
                { name: "Inventory Management", checked: true },
                { name: "PDC Management", checked: true },
                { name: "Voucher Management", checked: true },
                { name: "Reports and Graphs", checked: true },
                { name: "Instant Support", checked: true },
              ]}
              action={() => handleClick()}
              
            />
          </Col>
          {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
        </Row>
      </div>
    </div>
    </>
  );
}

