import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/d1.jpeg";
import AddImage2 from "../../assets/img/add/Dash.jpeg";
import AddImage3 from "../../assets/img/add/d3.png";
import AddImage4 from "../../assets/img/add/d4.jpeg";

import { Link } from "react-scroll";
import {  NavLink } from 'react-router-dom';
export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">Accounting and Billing, Simplified</h2>
              <p className="font12">
                Simplify your accounting and inventory. Set financial goals, invoice clients, manage products, automate taxation, and see where your money is going - all inside one tool.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <NavLink style={{ width: "190px" }} to="/sign-up">
                  <FullButton title="Get Started" />
                </NavLink>
                <Link style={{ width: "190px", marginLeft: "15px" }} to="contact"  spy={true} smooth={true}>
                  <FullButton title="Contact Us"  border />
                </Link>
              </ButtonsRow>
            </AddLeft>
            <AddRight>
              <AddRightInner>
                <div className="flexNullCenter">
                  {/* <AddImgWrapp1 className="flexCenter"> */}
                    <img src="https://cdn.corporatefinanceinstitute.com/assets/managerial-accounting-768x512.jpeg" alt=""  className="flexNullCenterimg" />
                    {/* <img src="https://assets-global.website-files.com/642b3b2440806566d7573934/6446fde7b63dbe33f35f290c_623b9f191bc1d3d536b9141c_35.webp" alt="office" /> */}
                  {/* </AddImgWrapp1> */}
                  {/* <AddImgWrapp2>
                    <img src="https://cloudinary.hbs.edu/hbsit/image/upload/s--xd-ANfyW--/f_auto,c_fill,h_375,w_750,/v20200101/8FC6545D865C150668D5A2A02C13C732.jpg" alt="office" />
                  </AddImgWrapp2> */}
                </div>
                {/* <div className="flexNullCenter">
                  <AddImgWrapp3>
                    <img src="https://assets-global.website-files.com/642b3b2440806566d7573934/6446fde7b63dbe33f35f290c_623b9f191bc1d3d536b9141c_35.webp" alt="office" />
                  </AddImgWrapp3>
                  <AddImgWrapp4>
                    <img src="https://www.commerceclasseskey2success.com/wp-content/uploads/2019/03/Financial-Accounting.jpg" alt="office" />
                  </AddImgWrapp4>
                </div> */}
              </AddRightInner>
            </AddRight>
          </Advertising>
        </div>
      </div>
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Features</h1>
            <p className="font13">
                Get all the personalized features for you at a single platform to handle your Inventory.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Real-time Tracking"
                subtitle="Allows your businesses to have accurate and up-to-date information on the quantity and location of each item in their inventory. Real-time tracking helps prevent stockouts or overstock situations, enabling businesses to make informed decisions based on current data."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Order Management"
                subtitle="The process of placing, fulfilling, and tracking orders. This includes functionalities such as order creation, order processing, and order fulfillment. The system should be able to automatically update inventory levels as orders are placed and fulfilled."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Reporting and Analytics"
                subtitle="These tools enable businesses to gain insights into their inventory performance, sales trends, and overall supply chain efficiency. Customizable reports can help businesses analyze data such as slow-moving items, stock turnover rates, and forecast demand."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" title="Export to Excel" subtitle="Periodically or on-demand, export the user details from the database to an Excel file." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>

      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;