import React, { useState,useRef } from 'react';
import { Form, Input, Button, select,Spin } from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { useEffect } from 'react';
import { message } from 'antd';


const App = () => {
  const [countries, setCountries] = useState([]);
  const [loadingCity, setLoadingCity] = useState(false); // New state for loading city data

  const fetchCountries = async () => {
    const { data, error } = await supabase.from('Country').select('*').eq('user', (await supabase.auth.getUser()).data.user.id);
    // extract in the form of id,name
    const newData = data.map((item) => ({
      key: item.id,
      name: item.country_name,
    }));
    // add options to country Select
    const countrySelect = document.getElementById('country');
    newData.forEach((item) => {
      const option = document.createElement('option');
      option.value = item.name;
      option.text = item.name;
      countrySelect.add(option);
    });
    setCountries(newData);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCountries();
      await fetchCity(); // Wait for fetchCity to complete before setting loading to false
      setLoadingCity(false); // Set loadingCity to false after fetchCity is complete
    };
  
    fetchData();
  }, []);

  const [data, setData] = useState([
  ]);
  const [loading, setLoading] = useState(false);
  

  const handleAddData = async (newName, newCity) => {

   

    // Check if the city already exists for the selected country
    const existingCity = data.find((record) => record.name === newName && record.city === newCity);
    if (existingCity) {
      // Show error message for duplicate entry
      message.error('Duplicate entry: This city for the selected country already exists.');
  await fetchCountries(); // Update the country dropdown options

      return;
    }
  
    // Validate city name (alphabetic characters only)
    if (!/^[a-zA-Z]+$/.test(newCity)) {
      // Show error message for non-alphabetic city name
      message.error('Invalid city name: City name should contain only alphabetical characters.');
  await fetchCountries(); // Update the country dropdown options
      return;
    }
  
 
    const { data: countryData, error: countryError } = await supabase
    .from('Country')
    .select('id')
    .eq('country_name', newName)
    .single();


  if (countryError) {
    console.log('countryError', countryError);
    return;
  }
  
    // Get the countryId before using it
   
    
  
    // Rest of the code remains the same
    setLoading(true);
    try {
      const response = await supabase
      .from('City')
      .insert([
        {
          country: countryData.id,
          city: newCity,
        },
      ]);

    console.log(response);

    // Fetch updated country data after a successful insertion
    await fetchCountries(); // Update the country dropdown options

    // Fetch city data after a successful insertion
    await fetchCity();

    // Update the state and reset the form fields
    setname('');
    document.getElementById('city').value = '';
  } catch (error) {
    console.error(error);
    message.error('An error occurred while submitting the data. Please try again later.');
  } finally {
    setLoading(false);
  }
  };
  

  const fetchCity = async () => {
    setLoading(true); // Set loading to true before the API call
    try {
    const { data, error } = await supabase.from('City').select('*').eq('user', (await supabase.auth.getUser()).data.user.id);
    if (error) console.log('error', error);
    else {
      // id, name, city format
      const formattedData = data.map((record) => ({
        key: record.id,
        name: record.country,
        city: record.city,
      }));
      // for each record, replace the country id with the country name with supabase lookup
      for (let i = 0; i < formattedData.length; i++) {
        const { data: countryData, error: countryError } = await supabase
          .from('Country')
          .select('country_name')
          .eq('id', formattedData[i].name)
          .single();
        if (countryError) console.log('countryError', countryError);
        else {
          formattedData[i].name = countryData.country_name;
        }
      }
      setData(formattedData);
    }
  }
  finally {
    setLoading(false); // Set loading to false after the API call
  }
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedName, setEditedName] = useState('');

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    setEditedName(record.city); // Set the initial editedName
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    const updatedData = data.filter((record) => record.key !== recordToDelete.key);
    await supabase.from('City').delete().match({ id: recordToDelete.key });
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = async () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...record, city: editedName } : record
    );
    // get country name from same record
    await supabase.from('City').update({ city: editedName }).match({ id: recordToEdit.key });
    setEditModalVisible(false);
    setRecordToEdit(null);
    setData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const columns = [
    {
      title: 'Country Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),

    },
    {
        title: 'City Name',
        dataIndex: 'city',
        key: 'city',
        width: '30%',
        ...getColumnSearchProps('city'),
      },

    
      {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            {/* <EyeOutlined
              onClick={() => handleView(record)}
              style={{ color: 'blue', marginRight: 8 }}
            /> */}
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },
  
  ];
  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };
  const [name, setname] = useState('');
  const [cityname, setcityname] = useState('');
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    // Process the form submission here
    console.log('Form submitted:', values);
    // Add your logic to handle form data, e.g., handleAddData(values.countryName, values.cityyName);
    handleAddData(values.countryName, values.cityyName);
    form.resetFields(); // Reset form fields after submission
  };

  return (
    <Spin spinning={loading}>
    <>
    <div>
      <Form form={form} onFinish={handleSubmit} style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
        <Form.Item name="countryName" rules={[{ required: true, message: 'Please select a country name' }]} style={{ marginRight: '8px' }}>
          <select
              value={name}
              id='country'
            onChange={(e) => {
              setname(e.target.value);
            }}
            >
            <option style={{ width: '100%' }} value="">Select Country</option>
          </select>
            
        </Form.Item>
        <Form.Item name="cityyName" rules={[{ required: true, message: 'Please enter a country name' }]} style={{ marginRight: '8px' }}>
          <Input
              placeholder="City Name"
              id = 'city'
            value={cityname}
            onChange={(e) => {
              setname(e.target.value);
            }}
          />
        </Form.Item>

        <Button
  type="primary"
  htmlType="submit"
  onClick={handleAddData}
  style={{ background: 'white', color: 'blue' }}
  loading={loading}
>
  <CheckOutlined /> Submit
</Button>
      </Form>

      <div>
        <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <Button type="primary" onClick={handleExportToExcel} style={{ background: 'white', color: 'blue' }}>
          <FileExcelOutlined /> Export to Excel
        </Button>
        
      </div>
    </div>
    <br>
    </br>
    <div style={{ overflowX: 'auto' }}>
      <Table id='table-to-export' columns={columns} dataSource={data} />
      <Modal
        title={`Delete Record - ${recordToDelete ? recordToDelete.name : ''}`}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={`Edit Record - ${recordToEdit ? recordToEdit.city : ''}`}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
        <Input
          value={editedName}
          onChange={(e) => setEditedName(e.target.value)}
        />
      </Modal>
      <Modal
        title={`View Record - ${recordToView ? recordToView.name : ''}`}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >

      </Modal>
    </div>
    </>
    </Spin>
  );
};

export default App;
