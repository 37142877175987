import React, { useState,useRef } from 'react';
import { Form, Input, Button ,Row,Col,Upload,Select , Spin, message} from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined,InboxOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import logo from '../assets/images/logo.png';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { useEffect } from 'react';

const { Dragger } = Upload;
const { Option } = Select;

const YourFormComponent= ({ onProductSubmit }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
   const [data, setData] = useState([
    
  ]);

     const [vats, setVats] = useState([]);
  const [units, setUnits] = useState([]);

  // load data from supabase
  useEffect(() => {
    fetchVats();
    fetchUnits();
    fetchServices();
  }, []);

  const fetchVats = async () => {
    const { data, error } = await supabase.from('VAT').select().eq('user', (await supabase.auth.getUser()).data.user.id);
    if (error) console.log('error', error);
    else setVats(data);
  };

  const fetchUnits = async () => {
    const { data, error } = await supabase.from('Unit').select().eq('user', (await supabase.auth.getUser()).data.user.id);
    if (error) console.log('error', error);
    else setUnits(data);
  };



  const onFinish = async (values) => {
    // Handle form submission
    setSubmitLoading(true);
  
    try {
    console.log('Form values:', values);
  
    
    if (!values.code || !values.englishName || !values.arabicName  || !values.vat  || !values.unit ) {
      message.error('Please fill mandatory fields.');
      return;
    }
    const fileData =
    values.photo && values.photo.length > 0
      ? await getBase64(values.photo[0].originFileObj)
      : null;

      await supabase
        .from('Service')
        .insert([
          {
            code: values.code,
            english_name: values.englishName,
            arabic_name: values.arabicName,
            selling_price: values.sellingPrice,
            purchase_price: values.purchasePrice,
            vat: values.vat,
            sales_account: values.salesAccount,
            purchase_account: values.purchaseAccount,
            unit: values.unit,
            description: values.description,
          },
        ])
        .then(() => {
          message.success('Service added successfully');
        })
        .catch((err) => {
          message.error('Error adding service');
          console.log(err);
        });
      
    onProductSubmit(values.englishName);

  
  
    // Reset the form
    form.resetFields();
  } finally {
      setSubmitLoading(false);
      fetchServices();
  }
  };

  const fetchServices = async () => {
    await supabase.from('Service').select('*').eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
      const updatedData = data.map((record) => ({
          ...record,
        key: record.id,
        image: logo,
        englishName: record.english_name,
        arabicName: record.arabic_name,
        sellingPrice: record.selling_price,
        purchasePrice: record.purchase_price,
        salesAccount: record.sales_account,
        purchaseAccount: record.purchase_account,
      }));

          setData(updatedData);

    }
    );

  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  
 

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    await supabase.from('Service').delete().match({ id: recordToDelete.id });
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = async () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...editedRecord } : record
    );

    await supabase
      .from('Service')
      .update({
        code: editedRecord.code,
        english_name: editedRecord.englishName,
        arabic_name: editedRecord.arabicName,
        selling_price: editedRecord.sellingPrice,
        purchase_price: editedRecord.purchasePrice,
        sales_account: editedRecord.salesAccount,
        purchase_account: editedRecord.purchaseAccount,
        description: editedRecord.description,
      })
      .match({ id: recordToEdit.id })
      .then(() => {
        message.success('Service updated successfully');
      })
      .catch((err) => {
        message.error('Error updating service');
        console.log(err);
      });

    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    setData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Code: </label>
          <Input
            value={editedRecord?.code}
            onChange={(e) => handleEditInputChange('code', e.target.value)}
          />
        </div>
        <div>
          <label> English Name: </label>
          <Input
            value={editedRecord?.englishName}
            onChange={(e) => handleEditInputChange('englishName', e.target.value)}
          />
        </div>
        <div>
          <label>Arabic Name: </label>
          <Input
            value={editedRecord?.arabicName}
            onChange={(e) => handleEditInputChange('arabicName', e.target.value)}
          />
        </div>
  
        <div>
          <label>Selling Price: </label>
          <Input
            value={editedRecord?.sellingPrice}
            onChange={(e) => handleEditInputChange('sellingPrice', e.target.value)}
          />
        </div>
        <div>
          <label>Purchase Price: </label>
          <Input
            value={editedRecord?.purchasePrice}
            onChange={(e) => handleEditInputChange('purchasePrice', e.target.value)}
          />
        </div>
        <div>
          <label> Sales Account: </label>
          <Input
            value={editedRecord?.salesAccount}
            onChange={(e) => handleEditInputChange('salesAccount', e.target.value)}
          />
        </div>
        <div>
          <label>Purchase Account: </label>
          <Input
            value={editedRecord?.purchaseAccount}
            onChange={(e) => handleEditInputChange('purchaseAccount', e.target.value)}
          />
        </div>
        <div>
          <label>Description: </label>
          <Input
            value={editedRecord?.description}
            onChange={(e) => handleEditInputChange('description', e.target.value)}
          />
        </div>
      </div>
    );
  };

  const [InvoiceHostedUrl, setHostedUrl] = useState('');
  console.log("hostes is "+InvoiceHostedUrl);

const fetchTwilioConfigData = async () => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    const userId = user.id;

    const { data: siteSettingsData, error: siteSettingsError } = await supabase
      .from('Site_Settings')
      .select('Invoice_Hosted_URL')
      .eq('user_id', userId)
      .order('id', { ascending: false })
      .limit(1);

    if (siteSettingsError) {
      console.error('Error fetching Site Settings data:', siteSettingsError.message);
    } else {
      // Update the form fields with Site Settings data
      const InvoiceHostedUrl = siteSettingsData[0]?.Invoice_Hosted_URL || '';
      console.log('bsdk',InvoiceHostedUrl);
      setHostedUrl(InvoiceHostedUrl);
    }
  } catch (error) {
    console.error('Error in fetchTwilioConfigData:', error.message);
  }
};

useEffect(async () => {
  // Fetch Site Settings data when the component mounts
 await fetchTwilioConfigData();
}, []);
  const columns = [
    {
       
      title: 'Image',
      key: 'image',
      render: (record) => (
        InvoiceHostedUrl ? (
          <img
            src={InvoiceHostedUrl}
            alt=""
            style={{ width: '25px', height: '25px', marginBottom: '0px' }}
          />
        ) : (
          <img
            src={logo}
            alt=""
            style={{ width: '25px', height: '25px', marginBottom: '0px' }}
          />
        )
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: '30%',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'English Name',
      dataIndex: 'englishName',
      key: 'englishName',
      width: '30%',
      ...getColumnSearchProps('englishName'),
    },
    {
      title: 'Arabic Name',
      dataIndex: 'arabicName',
      key: 'arabicName',
      width: '30%',
      ...getColumnSearchProps('arabicName'),
    },
   
    {
      title: 'Selling Price',
      dataIndex: 'sellingPrice',
      key: 'sellingPrice',
      width: '30%',
      ...getColumnSearchProps('sellingPrice'),
    },
    {
      title: 'Purchase Price',
      dataIndex: 'purchasePrice',
      key: 'purchasePrice',
      width: '30%',
      ...getColumnSearchProps('purchasePrice'),
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      key: 'vat',
      width: '30%',
      ...getColumnSearchProps('vat'),
    },
    {
        title: 'Sales Account',
        dataIndex: 'salesAccount',
        key: 'salesAccount',
        width: '30%',
        ...getColumnSearchProps('salesAccount'),
      },
      {
        title: 'Purchase Account',
        dataIndex: 'purchaseAccount',
        key: 'purchaseAccount',
        width: '30%',
        ...getColumnSearchProps('purchaseAccount'),
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        key: 'unit',
        width: '30%',
        ...getColumnSearchProps('unit'),
      },

     
     
     
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '30%',
        ...getColumnSearchProps('description'),
      },

      {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            {/* <EyeOutlined
              onClick={() => handleView(record)}
              style={{ color: 'blue', marginRight: 8 }}
            /> */}
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },
   
  
  ];
  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };

  return (
    <Spin spinning={submitLoading}>
    <>
        <Form name="your_form" form={form} onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="code">
            <Input placeholder="Code" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="englishName">
            <Input placeholder="English Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="arabicName">
            <Input placeholder="Arabic Name" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        
        <Col span={8}>
          <Form.Item name="sellingPrice">
            <Input placeholder="Selling Price" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="purchasePrice">
            <Input placeholder="Purchase Price" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="vat">
            <Select placeholder="VAT">
                  {
                    vats.map((vat) => (
                      <Option value={vat.id}>{vat.name}</Option>
                    ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="salesAccount">
            <Input placeholder="Sales Account" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="purchaseAccount">
            <Input placeholder="Purchase Account" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="unit">
            <Select placeholder="Unit">
                  {
                    units.map((unit) => (
                      <Option value={unit.id}>{unit.unit_name}</Option>
                    ))
              }
            </Select>
          </Form.Item>
        </Col>
        
        <Col span={8}>
          <Form.Item name="description">
            <Input placeholder="Description" />
          </Form.Item>
        </Col>
        
      </Row>

      <Row gutter={16}>
        <Col span={24}>
        <Form.Item
  name="photo"
  valuePropName="fileList"
  getValueFromEvent={(e) =>
    e && e.fileList ? e.fileList : []
  }
>
  <Dragger
    name="photo"
    multiple={false}
    style={{ width: '30%' }}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Choose File </p>
  </Dragger>
</Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit" style={{ background: 'white', color: 'blue' }}>
          <CheckOutlined /> Submit
          </Button>
        </Col>
      </Row>

    </Form>
    <br></br>
    <div>
        <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <Button type="primary" onClick={handleExportToExcel} style={{ background: 'white', color: 'blue' }}>
          <FileExcelOutlined /> Export to Excel
        </Button>
        
      </div>
    <br></br>
    <div style={{ overflowX: 'auto' }} className="table-container">
    <Table id='table-to-export' columns={columns} dataSource={data} />
    <Modal
      title={`Delete Record - ${recordToDelete ? recordToDelete.code : ''}`}
      visible={deleteModalVisible}
      onOk={handleDeleteConfirm}
      onCancel={handleDeleteCancel}
    >
      <p>Are you sure you want to delete this record?</p>
    </Modal>
    <Modal
      title={`Edit Record - ${recordToEdit ? recordToEdit.code : ''}`}
      visible={editModalVisible}
      onOk={handleEditConfirm}
      onCancel={handleEditCancel}
    >
      {renderEditForm()}
    </Modal>
    <Modal
      title={`View Record - ${recordToView ? recordToView.code : ''}`}
      visible={viewModalVisible}
      onOk={handleViewConfirm}
      onCancel={handleViewCancel}
    >
      {/* ... (existing code) */}
    </Modal>
    
  </div>
  </>
  </Spin>
  );
};

export default YourFormComponent;
