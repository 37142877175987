import React, { useState, useRef } from 'react';
import { Form, Input, Button, Select,Spin } from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import { Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined, EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf, { f } from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { useEffect } from 'react';
import { message } from 'antd';

const { Option } = Select;

const App = () => {
  const [data, setData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadingConv, setLoadingConv] = useState(false);
  const [loadingCurr, setLoadingCurr] = useState(false);

  const [currency, setCurrency] = useState([]);

  const fetchCurrency = async () => {
    setSubmitLoading(true); // Set loading to true before the API call
    try {
    const { data, error } = await supabase.from('Currency').select('*').eq('user', (await supabase.auth.getUser()).data.user.id);
    if (error) {
      console.log('Error fetching data: ', error.message);
      return;
    }
    setCurrency(data);
  }
  finally {
    setSubmitLoading(false); // Set loading to false after the API call
  }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCurrency();
      setLoadingCurr(false); // Assuming setLoadingCurr is the correct state variable
      await fetchConversions();
      setLoadingConv(false);
    };
  
    fetchData();
  }, []);
  
  const handleAddData = async (newCurrency, newConvertedCurrency, newRate) => {
    setSubmitLoading(true);
    // Check if the rate contains only numbers or special characters
    const isNumericOrSpecial = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(newRate);
  
    if (!isNumericOrSpecial) {
      // Display notification or message for invalid rate
      // message.error('Rate should be numbers or special characters');
      return;
      setSubmitLoading(false);
    }
  
    // get ids of the selected currency and converted currency
    const currencyId = currency.find((currency) => currency.name === newCurrency)?.id;
    const convertedCurrencyId = currency.find((currency) => currency.name === newConvertedCurrency)?.id;
  
    // Check for duplicate entry in the local state
    const isDuplicateLocal = data.some(
      (record) =>
        record.currency === newCurrency &&
        record.convertedCurrency === newConvertedCurrency &&
        record.rate === newRate
    );
  
    if (isDuplicateLocal) {
      // Display notification or message for duplicate entry
      message.error('Duplicate entry: The selected data already exists locally.');
      return;
    }
  
    // Fetch the latest data from the database
    const { data: latestData, error: fetchError } = await supabase.from('Conversion').select('*');
    if (fetchError) {
      console.log('Error fetching latest data: ', fetchError.message);
      return;
    }
  
    // Check for duplicate entry in the latest data
    const isDuplicateDB = latestData.some(
      (record) =>
        record.from === currencyId &&
        record.to === convertedCurrencyId &&
        record.rate === newRate
    );
  
    if (isDuplicateDB) {
      // Display notification or message for duplicate entry
      message.error('Duplicate entry: The selected data already exists in the database.');
      return;
    }
  
   
    setSubmitLoading(true);
  
    try {
      // Proceed with adding data if not a duplicate
        const response = await supabase
        .from('Conversion')
        .insert([
          {
            from: currencyId,
            to: convertedCurrencyId,
            rate: newRate,
          },
        
        ])
        .then((response) => {
          console.log('Data inserted successfully: ', response);
          // Add the new record to the state
           fetchConversions();
               
 
        })
        .catch((error) => {
          console.log('Error inserting data: ', error.message);
        });
        await fetchConversions();
    } finally {
      setSubmitLoading(false);
    }
  };
  
  
  

  const fetchConversions = async () => {

    setSubmitLoading(true); // Set loading to true before the API call
    try {
    const { data, error } = await supabase.from('Conversion').select('*').eq('user', (await supabase.auth.getUser()).data.user.id);
    if (error) {
      console.log('Error fetching data: ', error.message);
      return;
    }
    const updatedData = data.map((record) => ({
      key: record.id,
      rate: record.rate,
      currency: record.from,
      convertedCurrency: record.to,
    }));
    // get name of the currency and converted currency from supabase
    const currencyName = await Promise.all(updatedData.map(async (record) => {
      const { data, error } = await supabase.from('Currency').select('*').eq('id', record.currency);
      if (error) {
        console.log('Error fetching data: ', error.message);
        return;
      }
      return data[0].name;
    }));
    const convertedCurrencyName = await Promise.all(updatedData.map(async (record) => {
      const { data, error } = await supabase.from('Currency').select('*').eq('id', record.convertedCurrency);
      if (error) {
        console.log('Error fetching data: ', error.message);
        return;
      }
      return data[0].name;
    }));
    // Update the currency and converted currency name in the state
    const updatedDataWithNames = updatedData.map((record, index) => ({
      ...record,
      currency: currencyName[index],
      convertedCurrency: convertedCurrencyName[index],
    }));
    setData(updatedDataWithNames);
  }
  finally {
    setSubmitLoading(false); // Set loading to false after the API call
  }

  };
    

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedName, setEditedName] = useState('');

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    setEditedName(record.rate); // Set the initial editedName
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    const updatedData = data.filter((record) => record.key !== recordToDelete.key);
    await supabase.from('Conversion').delete().eq('id', recordToDelete.key);
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = async () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...record, rate: editedName } : record
    );
    console.log(updatedData);
    await supabase.from('Conversion').update([{ rate: editedName }]).eq('id', recordToEdit.key);
    setEditModalVisible(false);
    setRecordToEdit(null);
    setData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = (record) => {
    setEditedName(''); // Reset the editedName
    setEditModalVisible(false);
    setRecordToEdit(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const columns = [
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width: '30%',
      ...getColumnSearchProps('rate'),
    },
    {
      title: 'CurrencyName',
      dataIndex: 'currency',
      key: 'currency',
      width: '30%',
      ...getColumnSearchProps('currency'),
    },
    {
      title: 'ConvertedCurrencyName',
      dataIndex: 'convertedCurrency',
      key: 'convertedCurrency',
      width: '30%',
      ...getColumnSearchProps('convertedCurrency'),
    },

    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          {/* <EyeOutlined
            onClick={() => handleView(record)}
            style={{ color: 'blue', marginRight: 8 }}
          /> */}
          <EditOutlined
            onClick={() => handleEdit(record)}
            style={{ color: 'blue', marginRight: 8 }}
          />
          <DeleteOutlined
            onClick={() => handleDelete(record)}
            style={{ color: 'blue' }}
          />
        </>
      ),
    },
  ];

  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');

    html2pdf(table);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };

  const [name, setname] = useState('');
  const [convertedCurrency, setConvertedCurrency] = useState('');
  const [rate, setRate] = useState('');
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    // Process the form submission here
    console.log('Form submitted:', values);
    
    // Extract values from the form
    const { currency, convertedCurrency, rate } = values;

    // Add your logic to handle form data
    handleAddData(currency, convertedCurrency, rate);

    // Reset form fields after submission
    form.resetFields();
  };

  return (
    <Spin spinning={submitLoading}>
    <>
    <div>
        <Form form={form} onFinish={handleSubmit} style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
          <Form.Item name="currency" rules={[{ required: true, message: 'Please enter a currency' }]} style={{ marginRight: '8px' }}>
            <Select
              placeholder="Select Currency"
              size="large" // Set size to 'middle'
              onChange={(value) => setname(value)}
            >
              {/* Add options from the currency table */}
              {currency.map((currency) => (
                <Option value={currency.name}>{currency.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="convertedCurrency" rules={[{ required: true, message: 'Please enter a converted currency' }]} style={{ marginRight: '8px' }}>
            <Select
              placeholder="Select Converted Currency"
              size="large" // Set size to 'middle'
              onChange={(value) => setConvertedCurrency(value)}
            >
              {/* Add options from the currency table */}
              {currency.map((currency) => (
                <Option value={currency.name}>{currency.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="rate" rules={[{ required: true, message: 'Please enter a rate' }]} style={{ marginRight: '8px' }}>
            <Input
              placeholder="Rate"
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              size="middle" // Set size to 'middle'
            />
          </Form.Item>

          <Button
  type="primary"
  htmlType="submit"
  onClick={handleAddData}
  style={{ background: 'white', color: 'blue' }}
  submitLoading={setSubmitLoading}
>
  <CheckOutlined /> Submit
</Button>
        </Form>

        <div>
          <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
            <FilePdfOutlined /> Export to PDF
          </Button>
          <Button type="primary" onClick={handleExportToExcel} style={{ background: 'white', color: 'blue' }}>
            <FileExcelOutlined /> Export to Excel
          </Button>
        </div>
      </div>
      <br></br>
      <div style={{ overflowX: 'auto' }}>
      <Table id='table-to-export' columns={columns} dataSource={data} />
      <Modal
        title={`Delete Record - ${recordToDelete ? recordToDelete.name : ''}`}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={`Edit Record - ${recordToEdit ? recordToEdit.city : ''}`}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
        <Input
          value={editedName}
          onChange={(e) => setEditedName(e.target.value)}
        />
      </Modal>
      <Modal
        title={`View Record - ${recordToView ? recordToView.name : ''}`}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >

      </Modal>
    </div>
    </>
    </Spin>
  );
};

export default App;
