import React, { useState,useRef,useEffect } from 'react';
import { Form, Input, Button,Select,Row,Col,DatePicker ,Switch,Spin} from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { message } from 'antd';
const { Option } = Select;

const App = () => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [submitLoading, setLoading] = useState(false);
  const [check , setcheck] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();

      const userId = user.id;
      // Extract form values
      const {
        
        code,
        unit,
        quantity,
        remarks,
        supplier,
        date,

      } = values;
    
   


      const selectedProduct = productOptions.find(product => product.code === code);
  
      // Create a new row object
      const newRow = {
        
        code: values.code,
        name: selectedProduct ? selectedProduct.english_name : '',
        unit ,
        quantity,
        remarks,
        date: date ? date.format('YYYY-MM-DD') : '',
        supplier,user_id: userId,
      }; 
  
      console.log('New Row:', newRow);
      console.log('Form Values:', values);
      console.log('New Row:', newRow);
  
      // Store data in Supabase
      const { data: createdData, error } = await supabase
        .from('stocks_receive')
        .insert([newRow],{ skipRole: true });

        console.log('Created Data:', createdData);
          console.log('Error:', error);
  
      if (error) {
        console.error('Error storing data in Supabase:', error.message);
      } else {
        console.log('Data stored in Supabase:', createdData);
        setcheck(!check);
        form.resetFields();
        // Optionally, you can show a success message or perform additional actions.
      }

      const { data: fetchData, error: fetchError } = await supabase.from('stocks_receive').select('*');
    if (fetchError) {
      console.error('Error fetching data from Supabase:', fetchError.message);
    } else {
      setData(fetchData || []);
    }

  } catch (error) {
    setLoading(false);
    console.error('Error in onFinish:', error.message);
  }
  

  };
  
  
  
  
  
  

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);
  const [customeroption, setCustomeroption] = useState([]);

  const [customerOptions, setCustomerOptions] = useState([]);
const [unitOptions, setUnitOptions] = useState([]);
const [productOptions, setProductOptions] = useState([]);

const [masterOptions, setmasterOptions] = useState([]);
const [vatOptions, setVatOptions] = useState([]);
const [vatTypeOptions, setVatTypeOptions] = useState([]);
const [salesEmployeeOptions, setSalesEmployeeOptions] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();

    const userId = user.id;
     

      const { data: masterData } = await supabase
        .from("master") // Replace with the actual unit table name
        .select("id,name");
     setmasterOptions(masterData || []);

     const { data: unitData } = await supabase
     .from("Unit") // Replace with the actual unit table name
     .select("id,unit_name").eq('user', userId);

   setUnitOptions(unitData || []);


   //fetch data from product
   const { data: productData } = await supabase
   .from("Product") // Replace with the actual unit table name
   .select("*").eq('user', userId);

 setProductOptions(productData || []);

      // Fetch data for vat
      const { data: vatData } = await supabase
        .from("VAT") // Replace with the actual vat table name
        .select("id,value").eq('user', userId);

      setVatOptions(vatData || []);

      // Fetch data for vat type
      const { data: vatTypeData } = await supabase
        .from("VAT") // Replace with the actual vat type table name
        .select("id,name").eq('user', userId);

      setVatTypeOptions(vatTypeData || []);

      // Fetch data for sales employee
      const { data: salesEmployeeData } = await supabase
        .from("Employee") // Replace with the actual sales employee table name
        .select("id,name").eq('user', userId);

        const { data: fetchcustomerData, error: customerError } = await supabase
        .from('customer')
        .select('*').eq('user', userId);
        setCustomeroption(fetchcustomerData || []);

      setSalesEmployeeOptions(salesEmployeeData || []);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Handle error, show a message, etc.
    }
    setLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();

    const userId = user.id;
      const { data: fetchData, error } = await supabase.from('stocks_receive').select('*').eq('user_id', userId);
      if (error) {
        console.error('Error fetching data from Supabase:', error.message);
      } else {
        setData(fetchData || []);
      }
    } catch (error) {
      console.error('Error in fetchData:', error.message);
    }
    setLoading(false);
    

  
  
};

  // Fetch data when the component mounts
  fetchData();
}, [check,setcheck]); // Empty dependency array means this effect runs once when the component mounts

  

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

 

 

 


  

 
  const calculateTotals = () => {
    let totalQuantity = 0, totalUnitPrice = 0, totalGross = 0, totalDiscount = 0, totalTaxable = 0, totalRoundVat = 0;
    data.forEach(item => {
      totalQuantity += Number(item.quantity);
      totalUnitPrice += Number(item.unitprice);
      totalGross += Number(item.gross);
      totalDiscount += Number(item.dicount);
     
      totalRoundVat += Number(item.roundvat);
    });
    return {
        key: 'total',
        code: 'Total',
        name: '',
        descrip: '',
        unit: '',
        vat: '',
        quantity: totalQuantity,
        unitprice: totalUnitPrice,
        gross: totalGross,
        dicount: totalDiscount,
   
        roundvat: totalRoundVat,
      };
    };

 
  
  const dataSourceWithTotal = [...data, calculateTotals()];

  const columns = [

    {
      title: 'Product Code',
      dataIndex: 'code',
      key: 'code',
      width: '30%',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '20%',
        ...getColumnSearchProps('date'),

      },
      {
        title: 'Supplier',
        dataIndex: 'supplier',
        key: 'supplier',
        width: '20%',
        ...getColumnSearchProps('supplier'),

      },
      
      {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        width: '20%',
        ...getColumnSearchProps('remarks'),

      },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: '30%',
      ...getColumnSearchProps('unit'),

    },
   
   
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '30%',
      ...getColumnSearchProps('qunatity'),
    },
   
      
   
  
  ];
  

  return (
    <Spin spinning={submitLoading}>
    <>
    <Form form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <div style={{ width: 600 }}>
              <Form.Item name="date"  >
                <DatePicker placeholder="Date" style={{ width: '30%' }} />
              </Form.Item>
              <div style={{ display: 'flex' }}>
              
               <Form.Item name="supplier" style={{ width: '30%' }} rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <Select placeholder="Supplier" style={{ width: '100%' }}>
                {customeroption.map((customer) => (
      <Option key={customer.id} value={customer.id}>
        {customer.name}
      </Option>
    ))}
                </Select>
              </Form.Item>
              
              </div>
              <div style={{ display: 'flex' }}>
              
              <Form.Item name="code" style={{ width: '30%',marginLeft:'0px' }}>
                 <Select placeholder="Product" style={{ width: '100%' }}>
                 {productOptions.map((Product) => (
                   <Option key={Product.id} value={Product.code}>
                     {Product.english_name}
                   </Option>
                 ))}
               </Select>
             </Form.Item>
              </div>
              <div style={{ display: 'flex' }}>
              <Form.Item name="unit" style={{ width: '30%' }}>
  <Select placeholder="Unit" style={{ width: '100%' }}>
    {unitOptions.map((Unit) => (
      <Option key={Unit.id} value={Unit.id}>
        {Unit.unit_name}
      </Option>
    ))}
  </Select>
</Form.Item>

              <Form.Item name="quantity" rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <Input placeholder="Quantity" type="number" style={{ width: '95%',marginLeft:'10px' }} />
              </Form.Item>

              
              </div>
      
              <Form.Item name="remarks" rules={[
                  {
                    required: true,
                    message: 'Please fill the field',
                  },
                ]}>
                <Input placeholder="Remarks" style={{ width: '80%' }} />
              </Form.Item>
            </div>
          </Col>
        
        </Row>

        <Space>
          <Button type="primary" htmlType="submit" style={{ background: 'white', color: 'blue' ,marginLeft:'400px'}}>
          <CheckOutlined /> Submit
          </Button>
        </Space>
      </Form>
      
      <br></br>
    <div style={{ overflowX: 'auto' }}>
      <Table columns={columns} dataSource={dataSourceWithTotal} />
      {/* ... [existing modals] */}
    </div>
    </>
    </Spin>
  );


};

export default App;