// UserProfilePage.js

import React, { useState, useEffect } from 'react';

import { Card, Form, Input, Button, message, Avatar, Row, Col, Checkbox, Divider, Table, Typography, Select} from 'antd';
import { UserOutlined, PhoneOutlined, MobileOutlined, MailOutlined, LogoutOutlined } from '@ant-design/icons';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../css/UserProfilePage.css';
import '../css/YourStylesheet.css';
import '../css/YourComponent.css';
import Sidenav from '../components/layout/Sidenav';


import supabase from "../helper/supabaseClient.js";
const { Title, Text } = Typography;
const UserProfilePage = () => {
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState(null);
  const [activeTab, setActiveTab] = useState('general');
  const [data, setData] = useState([]);
  const [selectedColor, setSelectedColor] = useState('#156FB5');

  const handleTabClick = (tab) => {
    setActiveTab(tab.toLowerCase());
  };
  const fetchUserProfileData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;

      // Fetch the user profile data with the highest ID for the specific user
      const { data: userProfileData, error: userProfileError } = await supabase
        .from('UserProfile')
        .select('*')
        .eq('user_id', userId)
        .order('id', { ascending: false })
        .limit(1);

      if (userProfileError) {
        console.error('Error fetching User Profile data:', userProfileError.message);
      } else {
        // Update the form fields with user profile data
        form.setFieldsValue({
          username: userProfileData[0]?.UserName,
          email: userProfileData[0]?.Email,
          phone: userProfileData[0]?.PhoneNo,
          mobile: userProfileData[0]?.Mobile,
          address: userProfileData[0]?.Address,
        });
      }
    } catch (error) {
      console.error('Error in fetchUserProfileData:', error.message);
    }
  };

  useEffect(() => {
    // Fetch User Profile data when the component mounts
    fetchUserProfileData();
  }, []); // Empty dependency array means this effect will run only once when the component mounts


  useEffect(() => {
    // Fetch User Profile data when the component mounts
    fetchUserProfileData();
  }, []); // Empty dependency array means this effect will run only once when the component mounts

  const onFinish = async (values) => {

    try {
      console.log('Form Values:', values);
      const { data: { user } } = await supabase.auth.getUser();

      const userId = user.id;

      // Destructure form values
      const { username, email, phone, mobile, address } = values;

      // Prepare the new data for local state
      const newData = {
        UserName: username,
        Email: email,
        PhoneNo: phone,
        Mobile: mobile,
        Address: address,
        user_id: userId,
      };

      console.log('New Row:', newData);

      // Insert the data into the Supabase table with upsert and onConflict: replace
      const { data: createdData, error } = await supabase
        .from('UserProfile')
        .upsert([newData], { skipRole: true });

      console.log('Created Data:', createdData);
      console.log('Error:', error);

      if (error) {
        console.error('Error updating data in Supabase:', error.message);
      } else {
        console.log('Data stored in Supabase:', createdData);

        // form.setFieldsValue({
        //   username: createdData[0].UserName,
        //   email: createdData[0].Email,
        //   phone: createdData[0].PhoneNo,
        //   mobile: createdData[0].Mobile,
        //   address: createdData[0].Address,
        // });

        // // Optionally, you can reset the form fields after successful submission
        // form.resetFields();
      }

      // Update local state with new data
      setData((prevData) => [...prevData, newData]);
    } catch (error) {
      console.error('Error in onFinish:', error.message);
    }
  };


  const fetchTwilioConfigData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;

      // Fetch the Twilio configuration data with the highest ID for the specific user
      const { data: twilioData, error: twilioError } = await supabase
        .from('Twilio')
        .select('*')
        .eq('user_id', userId)
        .order('id', { ascending: false })
        .limit(1);

      if (twilioError) {
        console.error('Error fetching Twilio data:', twilioError.message);
      } else {
        // Update the form fields with Twilio configuration data
        form.setFieldsValue({
          accountUid: twilioData[0]?.Account_UID,
          accountSid: twilioData[0]?.Account_SID,
          phoneNumber: twilioData[0]?.PhoneNo,
          // Add more fields as needed
        });
      }
    } catch (error) {
      console.error('Error in fetchTwilioConfigData:', error.message);
    }
  };

  useEffect(() => {
    // Fetch Twilio Configuration data when the component mounts
    fetchTwilioConfigData();
  }, []);


  const onFinishTwilio = async (values) => {
    try {

      const { data: { user } } = await supabase.auth.getUser();

      const userId = user.id;
      const { accountUid, accountSid, phoneNumber } = values;

      // Prepare the new data for local state
      const newData = {
        Account_SID: accountSid,
        Account_UID: accountUid,
        PhoneNo: phoneNumber,
        user_id: userId,


      };

      console.log('New Row (Twilio):', newData);

      // Insert the data into the Supabase table
      console.log('Inserting data (Twilio):', newData);

      const { data: createdData, error } = await supabase
        .from('Twilio')
        .insert([newData], { skipRole: true });

      console.log('Created Data (Twilio):', createdData);
      console.log('Error (Twilio):', error);

      if (error) {
        console.error('Error inserting data into Twilio: ', error.message);
      } else {
        console.log('Data stored in Twilio:', createdData);

        // form.setFieldsValue({
        //   accountSid: createdData[0].Account_SID,
        //   accountUid: createdData[0].Account_UID,
        //   phoneNumber: createdData[0].PhoneNo,
        // });
        // // Optionally, you can reset the form fields after successful submission
        // form.resetFields();
      }
    } catch (error) {
      console.error('Error in onFinishTwilio:', error.message);
    }
  };


  const fetchSmtpConfigData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;

      // Fetch the SMTP configuration data with the highest ID for the specific user
      const { data: smtpData, error: smtpError } = await supabase
        .from('SMTP')
        .select('*')
        .eq('user_id', userId)
        .order('id', { ascending: false })
        .limit(1);

      if (smtpError) {
        console.error('Error fetching SMTP data:', smtpError.message);
      } else {
        // Update the form fields with SMTP configuration data
        form.setFieldsValue({
          smtpHost: smtpData[0]?.SMTP_Host,
          smtpPort: smtpData[0]?.SMTP_Port,
          smtpUsername: smtpData[0]?.SMTP_UserName,
          smtpPassword: smtpData[0]?.SMTP_Pass,
          // imapHost: smtpData[0]?.IMAP_Host,
          // imapPort: smtpData[0]?.IMAP_Port,
          // imapUsername: smtpData[0]?.IMAP_UserName,
          // imapPassword: smtpData[0]?.IMAP_Pass,
          // Add more fields as needed
        });
      }
    } catch (error) {
      console.error('Error in fetchSmtpConfigData:', error.message);
    }
  };

  useEffect(() => {
    // Fetch SMTP Configuration data when the component mounts
    fetchSmtpConfigData();
  }, []);

  const onFinishSMTP = async (values) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;
      const { smtpHost, smtpPort, smtpUsername, smtpPassword, imapHost, imapPort, imapUsername, imapPassword } = values;

      // Prepare the new data for local state
      const newData = {
        SMTP_Host: smtpHost,
        SMTP_Port: smtpPort,
        SMTP_UserName: smtpUsername,
        SMTP_Pass: smtpPassword,
        // IMAP_Host: imapHost,
        // IMAP_Port: imapPort,
        // IMAP_UserName: imapUsername,
        // IMAP_Pass: imapPassword,
        user_id: userId,

      };

      console.log('New Row (SMTP):', newData);

      // Insert the data into the Supabase table
      console.log('Inserting data (SMTP):', newData);

      const { data: createdData, error } = await supabase
        .from('SMTP')
        .insert([newData], { skipRole: true });

      console.log('Created Data (SMTP):', createdData);
      console.log('Error (SMTP):', error);

      if (error) {
        console.error('Error inserting data into SMTP: ', error.message);
      } else {
        console.log('Data stored in SMTP:', createdData);

        // form.setFieldsValue({
        //   smtpHost: createdData[0].SMTP_Host,
        //   smtpPort: createdData[0].SMTP_Port,
        //   smtpUsername: createdData[0].SMTP_UserName,
        //   smtpPassword: createdData[0].SMTP_Pass,
        //   imapHost: createdData[0].IMAP_Host,
        //   imapPort: createdData[0].IMAP_Port,
        //   imapUsername: createdData[0].IMAP_UserName,
        //   imapPassword: createdData[0].IMAP_Pass,
        // });
        // // Optionally, you can reset the form fields after successful submission
        // form.resetFields();
      }
    } catch (error) {
      console.error('Error in onFinishSMTP:', error.message);
    }
  };




  const [emailTemplateForm] = Form.useForm();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const handleEmailTemplateSubmit = async (values) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;
  
      // Extract values from the form
      const { emailSubject, emailBody } = values;
  
      // Get the raw content state of the email body editor
      const contentState = editorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
  
      // Prepare the new data for the Email_Template table
      const newData = {
        user_id: userId,
        subject: emailSubject,
        email_template: rawContentState,
      };
  
      console.log('New Row (Email_Template):', newData);
  
      // Insert the data into the Supabase table
      console.log('Inserting data (Email_Template):', newData);
  
      const { data: createdData, error } = await supabase
        .from('Email_Template')
        .insert([newData], { skipRole: true });
  
      console.log('Created Data (Email_Template):', createdData);
      console.log('Error (Email_Template):', error);
  
      if (error) {
        console.error('Error inserting data into Email_Template: ', error.message);
      } else {
        console.log('Data stored in Email_Template:', createdData);
  
        // Optionally, you can reset the form fields after successful submission
        emailTemplateForm.resetFields();
      }
    } catch (error) {
      console.error('Error in handleEmailTemplateSubmit:', error.message);
    }
  };
  

  const [phoneNumberForm] = Form.useForm();
  const [phoneNumberEditorState, setPhoneNumberEditorState] = useState(() => EditorState.createEmpty());

  const handlePhoneNumberSubmit = (values) => {
    const contentState = phoneNumberEditorState.getCurrentContent();
    console.log('Phone Number Template Values:', {
      ...values,
      phoneNumberBody: convertToRaw(contentState),
    });
    // Handle the submission of the phone number template here
  };

  const [supportForm] = Form.useForm();
  const [supportEditorState, setSupportEditorState] = useState(() => EditorState.createEmpty());
  const handleSupportSubmit = (values) => {
    const contentState = supportEditorState.getCurrentContent();
    console.log('Support Template Values:', {
      ...values,
      supportBody: convertToRaw(contentState),
    });
    // Handle the submission of the support template here
  };

  const [supportTickets, setSupportTickets] = useState([]);
  const fetchSupportData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;

      // Fetch the support data with the highest ID for the specific user
      const { data: supportData, error: supportError } = await supabase
        .from('SupportTicket')
        .select('*')
        .eq('user_id', userId)
        .order('id', { ascending: false })
        .limit(1);

      if (supportError) {
        console.error('Error fetching Support data:', supportError.message);
      } else {
        // Update the form fields with SMTP configuration data
        supportForm.setFieldsValue({
          email: supportData[0]?.Email,
          description: supportData[0]?.Description,
        });
      }
    } catch (error) {
      console.error('Error in fetchSupportData:', error.message);
    }
  };

  useEffect(() => {
    // Fetch Support data when the component mounts
    fetchSupportData();
  }, []);


  const handleSupportReportSubmit = async (values) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;
      // Destructure values
      const { email, description } = values;

      // Prepare the new data for the Supabase table
      const newTicket = {
        Email: email,
        Description: description,
        user_id: userId,
      };

      console.log('New Support Ticket:', newTicket);

      // Insert the data into the Supabase table
      const { data: createdData, error } = await supabase
        .from('SupportTicket')
        .insert([newTicket], { skipRole: true });

      console.log('Created Support Ticket Data:', createdData);
      console.log('Support Ticket Error:', error);

      if (error) {
        console.error('Error inserting Support Ticket data into Supabase: ', error.message);
      } else {
        console.log('Support Ticket Data stored in Supabase:', createdData);

        // Optionally, update local state with new ticket data
        //setSupportTickets((prevTickets) => [...prevTickets, newTicket]);

        // Optionally, reset the form fields after successful submission
        // form.setFieldsValue({
        //   email: '',
        //   description: '',
        // });

        // // Fetch updated Support Tickets
        await fetchSupportData();
      }
    } catch (error) {
      console.error('Error in handleSupportReportSubmit:', error.message);
    }
  };

  const [siteSettingsForm] = Form.useForm();

  const handleSiteSettingsSubmit = async (values) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;

      const { hostedUrl, invoiceHostedUrl } = values;

      // Prepare the new data for local state
      const newData = {
        Hosted_URL: hostedUrl,
        Invoice_Hosted_URL: invoiceHostedUrl,
        user_id: userId,
      };

      console.log('New Row (Site Settings):', newData);

      // Insert the data into the Supabase table
      console.log('Inserting data (Site Settings):', newData);

      const { data: createdData, error } = await supabase
        .from('Site_Settings')
        .insert([newData], { skipRole: true });

      console.log('Created Data (Site Settings):', createdData);
      console.log('Error (Site Settings):', error);

      if (error) {
        console.error('Error inserting data into Site Settings: ', error.message);
      } else {
        console.log('Data stored in Site Settings:', createdData);
      }
      const newColor = values.sidenavColor;
      setSelectedColor(newColor);
    } catch (error) {
      console.error('Error in handleSiteSettingsSubmit:', error.message);
    }
  };



  const handleAvatarClick = () => {
    document.getElementById('avatarInput').click();
  };

  const onAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      message.success('${file.name} file uploaded successfully');
      const imageUrl = URL.createObjectURL(file);
      setAvatar(imageUrl);
      form.setFieldsValue({ avatar: file });
    }
  };

  const handleLogout = () => {
    console.log('User logged out');
  };
  const { Column } = Table;


  const [mastersConfig, setMastersConfig] = useState({
    Country: false,
    City: false,
    Unit: false,
    Vat: false,
    Currency: false,
    CurrencyConversion: false,
    VoucherDocs: false,
    ProductColor: false,
    ProductMaster: false,
  });

  const [sidenavVisibility, setSidenavVisibility] = useState({ ...mastersConfig });

  const fetchMastersConfigData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;

      // Fetch the Masters configuration data for the specific user, order by id in descending order and limit to 1 row
      const { data: mastersData, error: mastersError } = await supabase
        .from('Masters')
        .select('*')
        .eq('user_id', userId)
        .order('id', { ascending: false })
        .limit(1)
        .single();

      if (mastersError) {
        console.error('Error fetching Masters data:', mastersError.message);
      } else {
        // Update the local state with Masters configuration data
        setMastersConfig({
          Country: mastersData?.Country || false,
          City: mastersData?.City || false,
          Unit: mastersData?.Unit || false,
          Vat: mastersData?.Vat || false,
          Currency: mastersData?.Currency || false,
          CurrencyConversion: mastersData?.CurrencyConversion || false,
          VoucherDocs: mastersData?.VoucherDocs || false,
          ProductColor: mastersData?.ProductColor || false,
          ProductMaster: mastersData?.ProductMaster || false,
        });

        // Set the initial values for the form fields
        form.setFieldsValue({
          Country: mastersData?.Country || false,
          City: mastersData?.City || false,
          Unit: mastersData?.Unit || false,
          Vat: mastersData?.Vat || false,
          Currency: mastersData?.Currency || false,
          CurrencyConversion: mastersData?.CurrencyConversion || false,
          VoucherDocs: mastersData?.VoucherDocs || false,
          ProductColor: mastersData?.ProductColor || false,
          ProductMaster: mastersData?.ProductMaster || false,
        });



      }
    } catch (error) {
      console.error('Error in fetchMastersConfigData:', error.message);
    }
  };



  const handleCheckboxChange = (page) => {
    setMastersConfig((prevConfig) => ({
      ...prevConfig,
      [page]: !prevConfig[page],
    }));


    // Update visibility in the sidebar immediately
    setSidenavVisibility((prevVisibility) => ({
      ...prevVisibility,
      [page]: !prevVisibility[page],
    }));
  };

  useEffect(() => {
    // Fetch Masters Configuration data when the component mounts
    fetchMastersConfigData();
  }, []);


  const onFinishMasters = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user.id;

      // Prepare the new data for local state
      const newData = {
        user_id: userId,
        ...mastersConfig,
      };

      // Update the data in the Supabase table
      console.log('Updating data (Masters):', newData);

      const { data: updatedData, error } = await supabase
        .from('Masters')
        .upsert([newData]);

      console.log('Updated Data (Masters):', updatedData);
      console.log('Error (Masters):', error);

      if (error) {
        console.error('Error updating data in Masters: ', error.message);
      } else {
        console.log('Data updated in Masters:', updatedData);

        // Optionally, you can reset the form fields after successful submission
        //form.resetFields();
        //await fetchMastersConfigData();
      }
    } catch (error) {
      console.error('Error in onFinishMasters:', error.message);
    }
  };
  return (
    <>
      <div className="user-profile-container">

        <div className="accordion">
          <div className={`tab ${activeTab === 'general' ? 'active' : ''}`} onClick={() => handleTabClick('general')}>
            General
          </div>
          <div className={`tab ${activeTab === 'twilio' ? 'active' : ''}`} onClick={() => handleTabClick('twilio')}>
            Twilio
          </div>
          <div className={`tab ${activeTab === 'smtp' ? 'active' : ''}`} onClick={() => handleTabClick('smtp')}>
            SMTP
          </div>
          {/* <div className={`tab ${activeTab === 'support' ? 'active' : ''}`} onClick={() => handleTabClick('support')}>
            Support
          </div> */}
          <div className={`tab ${activeTab === 'billing' ? 'active' : ''}`} onClick={() => handleTabClick('billing')}>
            Billing
          </div>
          <div className={`tab ${activeTab === 'masters' ? 'active' : ''}`} onClick={() => handleTabClick('masters')}>
            Masters
          </div>
          <div className={`tab ${activeTab === 'site-settings' ? 'active' : ''}`} onClick={() => handleTabClick('site-settings')}>
            Site Settings
          </div>
        </div>

        <div className="content-container">
          {activeTab === 'general' && (
            <Card title="User Profile" className="user-profile-card">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Avatar
                    size={120}
                    icon={<UserOutlined />}
                    src={avatar}
                    style={{ cursor: 'pointer' }}
                    onClick={handleAvatarClick}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    id="avatarInput"
                    style={{ display: 'none' }}
                    onChange={onAvatarChange}
                  />
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                  <Form form={form} onFinish={onFinish} layout="vertical">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="username"
                          rules={[{ required: true, message: 'Please input your username!' }]}
                          size="small"
                          dataindex="UserName"
                        >
                          <Input prefix={<UserOutlined />} placeholder="Username" size="small" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="email"
                          rules={[{ required: true, message: 'Please input your email!' }]}
                          size="small"
                          dataindex="Email"
                        >
                          <Input prefix={<MailOutlined />} type="email" placeholder="Email" size="small" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item

                          name="phone"
                          rules={[{ required: true, message: 'Please input your phone number!' }]}
                          size="small"
                          dataindex="PhoneNo"
                        >
                          <Input prefix={<PhoneOutlined />} placeholder="Phone Number" size="small" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="mobile"
                          rules={[{ required: true, message: 'Please input your mobile number!' }]}
                          size="small"
                          dataindex="Mobile"
                        >
                          <Input prefix={<MobileOutlined />} placeholder="Mobile" size="small" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name="address"
                      rules={[{ required: true, message: 'Please input your address!' }]}
                      size="small"
                      dataindex="Address"
                    >
                      <Input placeholder="Address" size="small" />
                    </Form.Item>
                    <Form.Item name="avatar" hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" size="small">
                        Update Profile
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          )}

          {activeTab === 'twilio' && (
            <Card title="Twilio Configuration" className="user-profile-card">
              <Form form={form} onFinish={onFinishTwilio} layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    {/* Add Twilio specific fields without labels */}
                    <Form.Item name="accountUid" rules={[{ required: true, message: 'Please input your Account UID!' }]}>
                      <Input placeholder="Account UID" />
                    </Form.Item>
                    <Form.Item name="accountSid" rules={[{ required: true, message: 'Please input your Account SID!' }]}>
                      <Input placeholder="Account SID" />
                    </Form.Item>
                    <Form.Item name="phoneNumber" rules={[{ required: true, message: 'Please input your Phone number!' }]}>
                      <Input placeholder="Phone Number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>

              <Form form={phoneNumberForm} onFinish={handlePhoneNumberSubmit} layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <h1>Invoice Sms Template</h1>
                    <Form.Item name="phoneNumberSubject" rules={[{ required: true, message: 'Please input the phone number template subject!' }]}>
                      <Input placeholder="Phone Number Subject" />
                    </Form.Item>
                    <Form.Item name="phoneNumberBody" rules={[{ required: true, message: 'Please input the phone number template body!' }]}>
                      <Editor
                        editorState={phoneNumberEditorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={setPhoneNumberEditorState}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save Phone Number Template
                  </Button>
                  <Button type="default" style={{ marginLeft: 8 }}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>

            </Card>
          )}


          {activeTab === 'smtp' && (
            <Card title="SMTP Configuration" className="user-profile-card">
              <Form form={form} onFinish={onFinishSMTP} layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="smtpHost" rules={[{ required: true, message: 'Please input SMTP host!' }]}>
                      <Input placeholder="SMTP Host" />
                    </Form.Item>
                    <Form.Item name="smtpPort" rules={[{ required: true, message: 'Please input SMTP port!' }]}>
                      <Input placeholder="SMTP Port" />
                    </Form.Item>
                    <Form.Item name="smtpUsername" rules={[{ required: true, message: 'Please input your SMTP username!' }]}>
                      <Input placeholder="SMTP Username" />
                    </Form.Item>
                    <Form.Item name="smtpPassword" rules={[{ required: true, message: 'Please input your SMTP password!' }]}>
                      <Input.Password placeholder="SMTP Password" />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="imapHost" rules={[{ required: true, message: 'Please input IMAP host!' }]}>
                      <Input placeholder="IMAP Host" />
                    </Form.Item>
                    <Form.Item name="imapPort" rules={[{ required: true, message: 'Please input IMAP port!' }]}>
                      <Input placeholder="IMAP Port" />
                    </Form.Item>
                    <Form.Item name="imapUsername" rules={[{ required: true, message: 'Please input your IMAP username!' }]}>
                      <Input placeholder="IMAP Username" />
                    </Form.Item>
                    <Form.Item name="imapPassword" rules={[{ required: true, message: 'Please input your IMAP password!' }]}>
                      <Input.Password placeholder="IMAP Password" />
                    </Form.Item>
                  </Col> */}
                </Row>
                <Form.Item name="terms" valuePropName="checked" rules={[{ required: true, message: 'Please accept the terms and conditions!' }]}>
                  <Checkbox>
                    I have read and accept the terms and conditions.
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save Configuration
                  </Button>
                  <Button type="default" style={{ marginLeft: 8 }}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>

              <Form form={emailTemplateForm} onFinish={handleEmailTemplateSubmit} layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <h1>Invoice Email Template</h1>
                    <Form.Item name="emailSubject" rules={[{ required: true, message: 'Please input the email subject!' }]}>
                      <Input placeholder="Email Subject" />
                    </Form.Item>
                    <Form.Item name="emailBody" rules={[{ required: true, message: 'Please input the email body!' }]}>
                      <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={setEditorState}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save Email Template
                  </Button>
                  <Button type="default" style={{ marginLeft: 8 }}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </Card>

          )}

          {activeTab === 'support' && (
            <Card title="Support" className="user-profile-card">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  {/* Support Report Form */}
                  <Form form={supportForm} onFinish={handleSupportReportSubmit} layout="vertical">
                    <h3>Submit Support Report</h3>
                    <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                      <Input placeholder="Your Email" />
                    </Form.Item>
                    <Form.Item name="description" rules={[{ required: true, message: 'Please describe the issue!' }]}>
                      <Input.TextArea placeholder="Issue Description" />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit Report
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>

              {/* Support Tickets Table */}
              <Divider />

              <h3>Support Tickets</h3>
              <Table dataSource={supportTickets} pagination={{ pageSize: 5 }}>
                <Column title="Ticket ID" dataIndex="key" key="key" />
                <Column title="Email" dataIndex="email" key="email" />
                <Column title="Description" dataIndex="description" key="description" />
                {/* Add more columns as needed */}
              </Table>
            </Card>
          )}
          {activeTab === 'billing' && (
            <Card title="Billing" className="user-profile-card">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  {/* Subscription Plan Section */}
                  <div>
                    <h3>Subscription Plan</h3>
                    <p style={{ marginBottom: '8px' }}>You are currently on Premium Plan.</p>
                    {/* <h4 style={{ marginBottom: '20px' }}>For Beginners</h4> */}
                    <Button type="primary" onClick={() => {
                      alert("Your plan is activated")
                    }}>Activated</Button>
                  </div>
                </Col>
              </Row>
            </Card>

          )}

          {activeTab === 'masters' && (
            <>
              <Card title="Masters Configuration" className="user-profile-card">
                <Form form={form} onFinish={onFinishMasters} layout="vertical">
                  <Row gutter={16}>
                    <Col span={8}>
                      <strong>Master Pages</strong>
                    </Col>
                    <Col span={8}>
                      <strong>Status</strong>
                    </Col>
                  </Row>

                  {Object.entries(mastersConfig).map(([page, status]) => (
                    <Row key={page} gutter={16}>
                      <Col span={8}>
                        {page}
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          checked={status}
                          onChange={() => handleCheckboxChange(page)}
                        >
                          Active
                        </Checkbox>
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save Masters Configuration
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
              {/* Render the Sidenav */}

            </>
          )}
          {activeTab === 'site-settings' && (
            <Card title="Site Settings" className="user-profile-card">
              <Form form={siteSettingsForm} onFinish={handleSiteSettingsSubmit} layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <h1>URL For Logo Image</h1>
                    <Form.Item name="hostedUrl" rules={[{ required: true, message: 'Please input the Hosted URL!' }]}>
                      <Input placeholder="Hosted URL" />
                    </Form.Item>
                    <h1>URL For Invoice Image</h1>
                    <Form.Item name="invoiceHostedUrl" rules={[{ required: true, message: 'Please input the Invoice Hosted URL!' }]}>
                      <Input placeholder="Invoice Hosted URL" />
                    </Form.Item>
                  </Col>
                </Row>
                
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save Site Settings
                  </Button>
                  <Button type="default" style={{ marginLeft: 8 }}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          )}


        </div>


      </div>


    </>
  );
};

export default UserProfilePage;