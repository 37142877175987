import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Divider, Spin } from 'antd';
import Loader from "../Loader/loader.js";
// Screens
import Landing from "./screens/Landing.jsx";

const Homess = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Simulate an async operation (e.g., API call)
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Hide the loader after the operation is complete
      setLoading(false);
    };

    fetchData();
  }, []);

  return (

    <div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Landing />
        </>
      )
      }
    </div>

  );
}

export default Homess;

