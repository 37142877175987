// actions.js
export const setAuthenticationStatus = (status) => {
    // Save to local storage
    localStorage.setItem('isAuthenticated', JSON.stringify(status));
  
    return {
      type: 'SET_AUTHENTICATION_STATUS',
      payload: status,
    };
  };
  
  export const resetAuthenticationStatus = () => {
    // Remove from local storage
    // localStorage.removeItem('isAuthenticated');
  
    return {
      type: 'RESET_AUTHENTICATION_STATUS',
    };
  };
  