/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import supabase from "../../helper/supabaseClient";


function Footer() {
  const { Footer: AntFooter } = Layout;

  const [footerTextShow, setFooterTextShow] = useState(null)

const fetchConfigData = async () => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    const userId = user.id;

    // check if user exists or not
    const { data: existingData, error: selectError } = await supabase
    .from('logo_images')
    .select('footer_logo')
    .eq('user_id', userId);

    if(selectError){
      console.error('Error fetching Site Settings data:', selectError.message);
    }else if (existingData && existingData.length > 0){
      setFooterTextShow(existingData[0].footer_logo)
    }

  } catch (error) {
    console.error('Error in fetchTwilioConfigData:', error.message);
  }
};

    useEffect(async () => {
      // Fetch Site Settings data when the component mounts
    await fetchConfigData();
    }, []);

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            All Copyrights reserved by 
            {<HeartFilled />} by
            <a href="#pablo" className="font-weight-bold" target="_blank">
             {footerTextShow ?? 'Easy Book'}
            </a>
            
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              <li className="nav-item">
                <a
                  href="#pablo"
                  className="nav-link text-muted"
                  target="_blank"
                >
                 
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#pablo"
                  className="nav-link text-muted"
                  target="_blank"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#pablo"
                  className="nav-link text-muted"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#pablo"
                  className="nav-link pe-0 text-muted"
                  target="_blank"
                >
                  License
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
