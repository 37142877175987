import React, { useState,useRef } from 'react';
import { Form, Input, Button ,Spin } from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { useEffect } from 'react';
import { message } from 'antd';


const App = () => {
    const [name, setname] = useState('');
    
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    setname(values.countryName);
    // Your other logic for submitting the form
  };
  const [data, setData] = useState([
    ]);
    const handleAddData = async () => {
      // Check if the unit name is non-empty
      if (!name.trim()) {
        message.error('Unit name cannot be empty.');
        return;
      }
    
      // Check for duplicate entry
      const isDuplicate = data.some((record) => record.name.toLowerCase() === name.toLowerCase());
      if (isDuplicate) {
        message.error('Duplicate entry: This unit name already exists.');
        return;
      }
    
      // Validate unit name (alphabetic characters only)
      if (!/^[a-zA-Z]+$/.test(name)) {
        // Show error message for non-alphabetic unit name
        message.error('Invalid unit name: Unit name should contain only alphabetical characters.');
        return;
      }
      setLoading(true);
      try {
        // Set submitLoading to true before the API call
       
    
        // Make the API call to insert the unit
        await supabase
          .from('Unit')
          .insert([{ unit_name: name }])
          .then((response) => {
            console.log(response);
            setname('');
            form.resetFields();
            fetchUnit();
          })
          .catch((error) => {
            console.log(error);
          });
      } finally {
        // Set submitLoading to false after the API call
        setLoading(false);
      }
    };
    
  const fetchUnit = async () => {
    setLoading(true); // Set loading to true before the API call
    try {
    const { data, error } = await supabase.from('Unit').select('*').eq('user', (await supabase.auth.getUser()).data.user.id);
    if (error) console.log('error', error);
    else {
      // id, name format
      const formattedData = data.map((record) => ({
        key: record.id,
        name: record.unit_name,
      }));
      setData(formattedData);
    }
  }
  finally {
    setLoading(false); // Set loading to false after the API call
  }
  };

  useEffect(() => {
    fetchUnit();
  }
  , []);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [submitLoading, setLoading] = useState(false);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    setEditedName(record.name); // Set the initial editedName
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    const updatedData = data.filter((record) => record.key !== recordToDelete.key);
    await supabase.from('Unit').delete().match({ id: recordToDelete.key });
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = async () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...record, name: editedName } : record
    );
    await supabase.from('Unit').update({ unit_name: editedName }).match({ id: recordToEdit.key });
    setEditModalVisible(false);
    setRecordToEdit(null);
    setData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const columns = [
    {
      title: 'Unit Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },
    {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            {/* <EyeOutlined
              onClick={() => handleView(record)}
              style={{ color: 'blue', marginRight: 8 }}
            /> */}
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },
  
  ];

  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };


  return (
    <Spin spinning={submitLoading}>
    <>
    <div>
      <Form form={form} onFinish={handleSubmit} style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
        <Form.Item name="unitName" rules={[{ required: true, message: 'Please enter a country name' }]} style={{ marginRight: '8px' }}>
          <Input
            placeholder="Input Unit Name"
            value={name}
            onChange={(e) => {
              setname(e.target.value);
            }}
          />
        </Form.Item>

        <Button
  type="primary"
  htmlType="submit"
  onClick={handleAddData}
  style={{ background: 'white', color: 'blue' }}
  loading={submitLoading}
>
  <CheckOutlined /> Submit
</Button>
      </Form>

      <div>
        <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <Button type="primary" onClick={handleExportToExcel} style={{ background: 'white', color: 'blue' }}>
          <FileExcelOutlined /> Export to Excel
        </Button>
        
      </div>
    </div>
    <br>
    </br>
    <div style={{ overflowX: 'auto' }}>
      <Table id='table-to-export' columns={columns} dataSource={data} />
      <Modal
        title={`Delete Record - ${recordToDelete ? recordToDelete.name : ''}`}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={`Edit Record - ${recordToEdit ? recordToEdit.name : ''}`}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
        <Input
          value={editedName}
          onChange={(e) => setEditedName(e.target.value)}
        />
      </Modal>
      <Modal
        title={`View Record - ${recordToView ? recordToView.name : ''}`}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >

      </Modal>
    </div>
    </>
    </Spin>
  );
};

export default App;
