import React from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_test_51ODucNSBUBnZdF2vvqkzN3NNnk6fVKEwo9wMv2Sf0MBzJh3Pyq962aLfPM1AG0xVP7RyooZ4V7cIvD7XY2GZXuiO00gm3QoSIW");

const Subscription = () => {
  const handleClick = async (event) => {
    const stripe = await stripePromise;

    const response = await axios.post('https://alpha-payment-backend.vercel.app/api/stripe', { priceId: 'price_1OEeXsSBUBnZdF2vyNLNUh9T' });

    const session = response.data;

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
    console.log(result.aud)
    alert(result.aud)
    }
    else{
        alert(result.aud)
    }
  };

  return (
    <button role="link" onClick={handleClick}>
      Checkout
    </button>
  );
};

export default Subscription;