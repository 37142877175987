// Subtree.js
import React, { useState } from "react";
import { Modal, Input } from "antd";
import line from "../../../assets/images/subtree/red.png";
import first from "../../../assets/images/subtree/first.png";
import Subsubtree from "../Subsubtree/Subsubtree";
import "./Subtree.css";

const Subtree = (props) => {
  const [subsubtreeModalVisible, setSubsubtreeModalVisible] = useState(false);
  const [subsubtreeInputValue, setSubsubtreeInputValue] = useState("");
  const [subsubtreeArray, setSubsubtreeArray] = useState([]);
  const [showSubsubtrees, setShowSubsubtrees] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editInputValue, setEditInputValue] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  const handleAddSubsubtree = () => {
    setSubsubtreeModalVisible(true);
  };

  const handleOkSubsubtree = () => {
    setSubsubtreeArray((prevSubsubtreeArray) => [
      ...prevSubsubtreeArray,
      subsubtreeInputValue,
    ]);
    setSubsubtreeModalVisible(false);
    setSubsubtreeInputValue("");
  };

  const handleCancelSubsubtree = () => {
    setSubsubtreeModalVisible(false);
    setSubsubtreeInputValue("");
  };

  const handleInputChangeSubsubtree = (e) => {
    setSubsubtreeInputValue(e.target.value);
  };

  const toggleSubsubtrees = () => {
    setShowSubsubtrees(!showSubsubtrees);
  };

  const handleDeleteSubsubtree = (index) => {
    const updatedSubsubtreeArray = [...subsubtreeArray];
    updatedSubsubtreeArray.splice(index, 1);
    setSubsubtreeArray(updatedSubsubtreeArray);
  };

  const handleEditSubsubtree = (index) => {
    setEditModalVisible(true);
    setEditIndex(index);
    setEditInputValue(subsubtreeArray[index]);
  };

  const handleEditOkSubsubtree = () => {
    const updatedSubsubtreeArray = [...subsubtreeArray];
    updatedSubsubtreeArray[editIndex] = editInputValue;
    setSubsubtreeArray(updatedSubsubtreeArray);
    setEditModalVisible(false);
    setEditInputValue("");
  };

  const handleEditCancelSubsubtree = () => {
    setEditModalVisible(false);
    setEditInputValue("");
  };

  return (
    <div>
      <div className="Addaccountcontainer">
        <div>
          <img src={line} alt="" />
        </div>
        <div>
          <img src={first} alt="" />
        </div>
        <div>
          <span>{props.name}</span>
        </div>
        <div>
          <img src={line} alt="" />
        </div>
        <div>
          <button onClick={handleAddSubsubtree}>Add</button>
        </div>
        <div>
          <button onClick={toggleSubsubtrees}>
            {showSubsubtrees ? "Hide Subsubtrees" : "Show Subsubtrees"}
          </button>
        </div>

        {/* Subsubtree Modal */}
        <Modal
          title="Add Subsubtree"
          visible={subsubtreeModalVisible}
          onOk={handleOkSubsubtree}
          onCancel={handleCancelSubsubtree}
        >
          <Input
            placeholder="Enter subsubtree name"
            value={subsubtreeInputValue}
            onChange={handleInputChangeSubsubtree}
          />
        </Modal>
      </div>

      <div>
        {subsubtreeArray.length > 0 && showSubsubtrees && (
          <div>
            {subsubtreeArray.map((subsubtree, index) => (
              <Subsubtree
                key={index}
                name={subsubtree}
                index={index}
                onDelete={() => handleDeleteSubsubtree(index)}
                onEdit={() => handleEditSubsubtree(index)}
              />
            ))}
          </div>
        )}
      </div>

      {/* Edit Subsubtree Modal */}
      <Modal
        title="Edit Subsubtree"
        visible={editModalVisible}
        onOk={handleEditOkSubsubtree}
        onCancel={handleEditCancelSubsubtree}
      >
        <Input
          placeholder="Enter edited subsubtree name"
          value={editInputValue}
          onChange={(e) => setEditInputValue(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Subtree;
