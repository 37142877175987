/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
  } from "antd";
  
  import { ToTopOutlined } from "@ant-design/icons";
  import { Link } from "react-router-dom";
  import Costcenters from "../components/layout/Costcenters/cost";
  import '../css/CostCenters.css'
  
  
  function Cost() {
    return (
      <div className="container">
        <Costcenters id="company" name="(1) Company" />
        <Costcenters id="c" name="(2).." />
        <Costcenters id="co" name="(3)..." />
       
      </div>
    );
  }
  export default Cost;
  