import React, { useState, useRef } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import {
  FilePdfOutlined,
  FileExcelOutlined,
  CheckOutlined,
  SearchOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Space, Table, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { message } from 'antd';

const App = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleAddData = async (newName, newValue) => {
    setSubmitLoading(true);

    try {
      await supabase
        .from('VAT')
        .insert([
          {
            name: newName,
            value: newValue,
          }
        ])
        .then((response) => {
          console.log(response);
          fetchVAT();
        })
        .catch((error) => {
          console.log(error);
        });
    } finally {
      setSubmitLoading(false);
    }
  };

  React.useEffect(() => {
    fetchVAT();
  }, []);

  const fetchVAT = async () => {
    setSubmitLoading(true); // Set loading to true before the API call
    try {
      const { data, error } = await supabase.from('VAT').select('*').eq('user', (await supabase.auth.getUser()).data.user.id);
      if (error) {
        throw error;
      }
    const newData = data.map((item) => ({
      key: item.id,
      name: item.name,
      value: item.value,
    }));
    setData(newData);
  }
    finally {
      setSubmitLoading(false); // Set loading to false after the API call
    }
  };


  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedName, setEditedName] = useState('');

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    setEditedName(record.value); // Set the initial editedName
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    const updatedData = data.filter((record) => record.key !== recordToDelete.key);
    await supabase.from('VAT').delete().match({ id: recordToDelete.key });
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    setData(updatedData);
  };

  const handleEditConfirm = async () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.key ? { ...record, value: editedName } : record
    );
    await supabase.from('VAT').update({ value: editedName }).match({ id: recordToEdit.key });
    setEditModalVisible(false);
    setRecordToEdit(null);
    setData(updatedData);
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: '30%',
        ...getColumnSearchProps('value'),
      },

      {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            {/* <EyeOutlined
              onClick={() => handleView(record)}
              style={{ color: 'blue', marginRight: 8 }}
            /> */}
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },
  
  ];
  
  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };
  const [name, setname] = useState('');
  const [cityname, setcityname] = useState('');
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    // Process the form submission here
    console.log('Form submitted:', values);
    // Add your logic to handle form data, e.g., handleAddData(values.countryName, values.cityyName);
    handleAddData(values.vatName, values.vatvalue);
    form.resetFields(); // Reset form fields after submission
  };



  return (
    <Spin spinning={submitLoading}>
      <>
        <div>
          <Form form={form} onFinish={handleSubmit} style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
            <Form.Item name="vatName" rules={[{ required: true, message: 'Please enter a name' }]} style={{ marginRight: '8px' }}>
              <Input
                placeholder="Input Name"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item name="vatvalue" rules={[{ required: true, message: 'Please enter a value' }]} style={{ marginRight: '8px' }}>
              <Input
                placeholder="Input Value"
                value={cityname}
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              style={{ background: 'white', color: 'blue' }}
              loading={submitLoading}
            >
              <CheckOutlined /> Submit
            </Button>
          </Form>

          <div>
            <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
              <FilePdfOutlined /> Export to PDF
            </Button>
            <Button type="primary" onClick={handleExportToExcel} style={{ background: 'white', color: 'blue' }}>
              <FileExcelOutlined /> Export to Excel
            </Button>
          </div>
        </div>
        <br></br>
        <div style={{ overflowX: 'auto' }}>
          <Table id='table-to-export' columns={columns} dataSource={data} />
          <Modal
            title={`Delete Record - ${recordToDelete ? recordToDelete.value : ''}`}
            visible={deleteModalVisible}
            onOk={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
          >
            <p>Are you sure you want to delete this record?</p>
          </Modal>
          <Modal
            title={`Edit Record - ${recordToEdit ? recordToEdit.value : ''}`}
            visible={editModalVisible}
            onOk={handleEditConfirm}
            onCancel={handleEditCancel}
          >
            <Input
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
            />
          </Modal>
          <Modal
            title={`View Record - ${recordToView ? recordToView.name : ''}`}
            visible={viewModalVisible}
            onOk={handleViewConfirm}
            onCancel={handleViewCancel}
          >

          </Modal>
        </div>
      </>
    </Spin>
  );
};

export default App;