import React, { useState,useRef } from 'react';
import { Form, Input, Button,Row,Col , Spin } from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import supabase from "../helper/supabaseClient.js";
import { useEffect } from 'react';

const App = () => {
  const [data, setData] = useState([
    
  ]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitLoadingvou, setSubmitLoadingvou] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [check , setcheck] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedRecord, setEditedRecord] = useState(null);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    expiry: '',
    file: '',
    path: '',
  });
  const searchInput = useRef(null);

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
  };

  const handleAddData = async () => {

    setSubmitLoading(true);
     try {
       const respnse =  await supabase
      .from("Docs")
      .insert([
        {
          expiry: formData.expiry,
          file: formData.file,
          path: formData.path,
        },
      ])
      .then(() => {
        
      })
      .catch((err) => {
        console.log(err);
      });
      await fetchDocs();
    } finally {
      setSubmitLoading(false);
    }
  };

  const fetchDocs = async () => {
    
    setSubmitLoading(true);
     try {
      const { data: { user } } = await supabase.auth.getUser();

      const userId = user.id;
    const { data, error } = await supabase.from("Docs").select('*').eq('user', userId);
    if (error) console.log("error", error);
    else setData(data);
     }
    finally {
      setSubmitLoading(false);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      await  fetchDocs();
      setSubmitLoadingvou(false); // Assuming setLoadingCurr is the correct state variable
      
    };
  
    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };



  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  // const handleEdit = (record) => {
  //   // Set the initial editedRecord
  //   setEditedRecord({ ...record });
  //   setEditingRecord(record);
  //   setEditModalVisible(true);
  //   setRecordToEdit(record);
  // };
  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditingRecord(record);
  
    if (editedRecord === null) {
      setEditedRecord({ ...record });
    }
  
    setEditModalVisible(true);
    setRecordToEdit(record);
  };
  
  // const handleDeleteConfirm = async () => {
  //   try {
  //     // Delete data from Supabase
  //     const { data: deletedData, error } = await supabase
  //       .from('Docs')
  //       .delete()
  //       .eq('id', recordToDelete.id);
  
  //     if (error) {
  //       console.error('Error deleting data in Supabase:', error.message);
  //     } else {
  //       console.log('Data deleted in Supabase:', deletedData);
  //       setcheck(!check);
  
  //       // Fetch data after successful deletion
  //       const { data: fetchData, error: fetchError } = await supabase.from('Docs').select('*');
  //       if (fetchError) {
  //         console.error('Error fetching data from Supabase:', fetchError.message);
  //       } else {
  //         setData(fetchData || []);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error in handleDeleteConfirm:', error.message);
  //   } finally {
  //     setDeleteModalVisible(false);
  //     setRecordToDelete(null);
  //   }
  // };
  

  // const handleEditConfirm = async () => {
  //   try {
  //     // Update data in Supabase
  //     const { data: updatedData, error } = await supabase
  //     .from('Docs')
  //     .update([{ ...editedRecord }], { skipRole: true, returning: 'minimal' }) // Add returning: 'minimal'
  //     .eq('id', editedRecord.id);
  
  //     if (error) {
  //       console.error('Error updating data in Supabase:', error.message);
  //     } else {
  //       console.log('Data updated in Supabase:', updatedData);
  //       setcheck(!check);
  //       form.resetFields();
  
  //       // Fetch data after successful update
  //       const { data: fetchData, error: fetchError } = await supabase.from('Docs').select('*');
  //       if (fetchError) {
  //         console.error('Error fetching data from Supabase:', fetchError.message);
  //       } else {
  //         setData(fetchData || []);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error in handleEditConfirm:', error.message);
  //   } finally {
  //     setEditModalVisible(false);
  //     setEditingRecord(null);
  //     setRecordToEdit(null);
  //     setEditedRecord(null);
  //   }
  // };

  const handleDeleteConfirm = async () => {
    try {
      // Delete data from Supabase
      const { data: deletedData, error } = await supabase
        .from('Docs')
        .delete()
        .eq('id', recordToDelete.id);
  
      if (error) {
        console.error('Error deleting data in Supabase:', error.message);
      } else {
        console.log('Data deleted in Supabase:', deletedData);
        setcheck(!check);
  
        // Fetch data after successful deletion
        const { data: { user } } = await supabase.auth.getUser();

        const userId = user.id;
        const { data: fetchData, error: fetchError } = await supabase.from('Docs').select('*').eq('user',userId);
        if (fetchError) {
          console.error('Error fetching data from Supabase:', fetchError.message);
        } else {
          setData(fetchData || []);
        }
      }
    } catch (error) {
      console.error('Error in handleDeleteConfirm:', error.message);
    } finally {
      setDeleteModalVisible(false);
      setRecordToDelete(null);
    }
  };
  
  const handleEditConfirm = async () => {
    try {
      // Update data in Supabase
      const { data: updatedData, error } = await supabase
        .from('Docs')
        .update([editedRecord], { skipRole: true, returning: 'minimal' }) // Use [editedRecord] directly
        .eq('id', editedRecord.id);
  
      if (error) {
        console.error('Error updating data in Supabase:', error.message);
      } else {
        console.log('Data updated in Supabase:', updatedData);
        setcheck(!check);
        form.resetFields();
        const { data: { user } } = await supabase.auth.getUser();

        const userId = user.id;
  
        // Fetch data after successful update
        const { data: fetchData, error: fetchError } = await supabase.from('Docs').select('*').eq('user',userId);
        if (fetchError) {
          console.error('Error fetching data from Supabase:', fetchError.message);
        } else {
          setData(fetchData || []);
        }
      }
    } catch (error) {
      console.error('Error in handleEditConfirm:', error.message);
    } finally {
      setEditModalVisible(false);
      setEditingRecord(null);
      setRecordToEdit(null);
      setEditedRecord(null);
    }
  };
  


  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };
  const renderEditForm = () => {
    return (
      <div>
      <p>Edit Record</p>
      <div>
        <label>Expiry Date: </label>
        <Input
          value={editedRecord?.expiry}
          onChange={(e) => handleEditInputChange('expiry', e.target.value)}
        />
      </div>
      <div>
        <label>File name: </label>
        <Input
          value={editedRecord?.file}
          onChange={(e) => handleEditInputChange('file', e.target.value)}
        />
      </div>
      <div>
        <label>Path: </label>
        <Input
          value={editedRecord?.path}
          onChange={(e) => handleEditInputChange('path', e.target.value)}
        />
      </div>
       
        {/* ... Add more fields as needed */}
      </div>
    );
  };
  

  const columns = [
    {
      title: 'Expiry Date',
      dataIndex: 'expiry',
      key: 'expiry',
      width: '30%',
      ...getColumnSearchProps('expiry'),
    },
    {
        title: 'File name',
        dataIndex: 'file',
        key: 'file',
        width: '30%',
        ...getColumnSearchProps('file'),
      },
      {
        title: 'File Path',
        dataIndex: 'path',
           key: 'path',
        width: '30%',
        ...getColumnSearchProps('path'),
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },

    
     
  
  ];
  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };

  return (
    <Spin spinning={submitLoading}>
    <>
     <Row gutter={16}>
        <Col span={6}>
          <Input
            placeholder="Expiry Date"
            value={formData.expiry}
            onChange={(e) => handleInputChange('expiry', e.target.value)}
            type="date"
          />
        </Col>
        <Col span={6}>
          <Input
            placeholder="File name"
            value={formData.file}
            onChange={(e) => handleInputChange('file', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <Input
            placeholder="File Path"
            value={formData.path}
            onChange={(e) => handleInputChange('path', e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
        <Button type="primary" htmlType="submit" onClick={handleAddData} style={{ background: 'white', color: 'blue',marginTop:'10px' }}>
          <CheckOutlined /> Submit
        </Button>
          
        </Col>
      </Row>
    <div>
        <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px',marginTop:'10px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <Button type="primary" onClick={handleExportToExcel} style={{ background: 'white', color: 'blue' }}>
          <FileExcelOutlined /> Export to Excel
        </Button>
        
      </div>
      <br></br>
    <div style={{ overflowX: 'auto' }}>
      <Table id='table-to-export'columns={columns} dataSource={data} />
      <Modal
        title={`Delete Record - ${recordToDelete ? recordToDelete.name : ''}`}
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title={`Edit Record - ${recordToEdit ? recordToEdit.city : ''}`}
        visible={editModalVisible}
        onOk={handleEditConfirm}
        onCancel={handleEditCancel}
      >
         {renderEditForm()}
    
      </Modal>
      <Modal
        title={`View Record - ${recordToView ? recordToView.name : ''}`}
        visible={viewModalVisible}
        onOk={handleViewConfirm}
        onCancel={handleViewCancel}
      >

      </Modal>
    </div>
    </>
    </Spin>
  );
};

export default App;