// Subsubtree.js
import React from "react";
import line from "../../../assets/images/subtree/orange.png";
import first from "../../../assets/images/subtree/first.png";
import "./Subsubtree.css";
import del from "../../../assets/images/accounttype/del.png";
import last from "../../../assets/images/subtree/last.png";

const Subsubtree = (props) => {
  const handleDelete = () => {
    props.onDelete(props.index);
  };

  const handleEdit = () => {
    props.onEdit(props.index);
  };

  return (
    <div className="Addaccount">
      <div>
        <img src={line} alt="" />
      </div>
      <div>
        <img src={first} alt="" />
      </div>
      <div>
        <span>{props.name}</span>
      </div>
      <div>
        <img src={last} onClick={handleEdit} alt="" />
      </div>
      <div>
        <img src={del} alt="" onClick={handleDelete} />
      </div>
    </div>
  );
};

export default Subsubtree;
