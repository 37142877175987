import React, { useState,useRef } from 'react';
import { Form, Input, Button ,Row,Col,Upload,Select, message, DatePicker} from 'antd';
import { FilePdfOutlined, FileExcelOutlined, LinkOutlined, CheckOutlined } from '@ant-design/icons';
import {  Space, Table, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined,EyeOutlined,InboxOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import logo from '../assets/images/logo.png';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { Spin } from 'antd';
import supabase from "../helper/supabaseClient.js";
import { useEffect } from 'react';
const { Dragger } = Upload;
const { Option } = Select;

const YourFormComponent= ({ onProductSubmit }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [submitting, setSubmitting] = useState(false); // Added state for submission loading
   const [data, setData] = useState([
      
  ]);

  const onFinish = async (values) => {
    // Set submitting to true when the form is being submitted
    setSubmitting(true);
  
    try {
      // Handle form submission
      console.log('Form values:', values);
      if (!values.code || !values.englishname || !values.arabicname  || !values.vat  || !values.unit ) {
        message.error('Please fill mandatory fields.');
        return;
      }
      const fileData =
        values.photo && values.photo.length > 0
          ? await getBase64(values.photo[0].originFileObj)
          : null;
  
      await supabase.from('Asset').insert([
        {
          code: values.code,
          english_name: values.englishname,
          arabic_name: values.arabicname,
          barcode: values.barcode,
          selling_price: values.sellingprice,
          purchase_price: values.purchaseprice, 
          vat: values.vat,
          sales_account: values.salesaccount,
          purchase_account: values.purchaseaccount,
          unit: values.unit,
          specification: values.specification,
          model: values.model,
          amc_company: values.amcname,
          supplier_name: values.supplier,
          depreciation_year: values.depriciation,
          description: values.description,
          warranty_date: values.warranty,
          country: values.country,
        }
      ])
        .then(() => {
          message.success('Asset Added Successfully');
        })
        .catch((err) => {
          message.error('Error while adding Asset');
          console.log(err);
        });
        onProductSubmit(values.englishname);


  
      // Reset the form
      form.resetFields();
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      // Set submitting to false when the submission is complete
      fetchAsset();
      setSubmitting(false);
    }
  };

  const fetchAsset = async () => {
    await supabase.from('Asset').select('*').eq('user', (await supabase.auth.getUser()).data.user.id)
      .then(({ data, error }) => {
      const updatedData = data.map((record) => ({
          ...record,
        key: record.id,
        englishname: record.english_name,
        arabicname: record.arabic_name,
        sellingprice: record.selling_price,
        purchaseprice: record.purchase_price,
        salesaccount: record.sales_account,
        purchaseaccount: record.purchase_account,
        amcname: record.amc_company,
        supplier: record.supplier_name,
        depriciation: record.depreciation_year,
        descrip: record.description,
        warranty: record.warranty_date,
        image: logo,
      }));
      
      

        setData(updatedData);
    });
  };
  
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

    const [vats, setVats] = useState([]);
  const [units, setUnits] = useState([]);
  const [countries, setCountries] = useState([]);

  // load data from supabase
  useEffect(() => {
    fetchVats();
    fetchUnits();
    fetchCountries();
    fetchAsset();
  }, []);

  const fetchVats = async () => {
    const { data, error } = await supabase.from('VAT').select().eq('user', (await supabase.auth.getUser()).data.user.id);
    if (error) console.log('error', error);
    else setVats(data);
  };

  const fetchUnits = async () => {
    const { data, error } = await supabase.from('Unit').select().eq('user', (await supabase.auth.getUser()).data.user.id);
    if (error) console.log('error', error);
    else setUnits(data);
  };

  const fetchCountries = async () => {
    const { data, error } = await supabase.from('Country').select().eq('user', (await supabase.auth.getUser()).data.user.id);
    if (error) console.log('error', error);
    else setCountries(data);
  };
  
 

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToView, setRecordToView] = useState(null);
  const [editedRecord, setEditedRecord] = useState(null);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    setDeleteModalVisible(true);
    setRecordToDelete(record);
  };

  const handleEdit = (record) => {
    // Set the initial editedRecord
    setEditedRecord({ ...record });
    setEditModalVisible(true);
    setRecordToEdit(record);
  };

  const handleView = (record) => {
    setViewModalVisible(true);
    setRecordToView(record);
  };

  const handleDeleteConfirm = async () => {
    const updatedData = data.filter(
      (record) => record.key !== recordToDelete.key
    );
    await supabase.from('Asset').delete().match({ id: recordToDelete.id });
    setDeleteModalVisible(false);
    setRecordToDelete(null);
    fetchAsset();
  };

  const handleEditConfirm = async () => {
    const updatedData = data.map((record) =>
      record.key === recordToEdit.id ? { ...editedRecord } : record
    );

    await supabase.from('Asset').update([
      {
        code: editedRecord.code,
        english_name: editedRecord.englishname,
        arabic_name: editedRecord.arabicname,
        barcode: editedRecord.barcode,
        selling_price: editedRecord.sellingprice,
        purchase_price: editedRecord.purchaseprice,
        sales_account: editedRecord.salesaccount,
        purchase_account: editedRecord.purchaseaccount,
        specification: editedRecord.specification,
        model: editedRecord.model,
        supplier_name: editedRecord.supplier,
        description: editedRecord.descrip,
      },
    ]).match({ id: recordToEdit.id })
      .then(() => {
        message.success('Asset Updated Successfully');
      })
      .catch((err) => {
        message.error('Error while updating Asset');
        console.log(err);
      });
    

    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
    fetchAsset();
  };

  const handleViewConfirm = () => {
    setViewModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setRecordToDelete(null);
  };

  const handleEditCancel = () => {
    setEditModalVisible(false);
    setRecordToEdit(null);
    setEditedRecord(null);
  };

  const handleViewCancel = () => {
    setViewModalVisible(false);
    setRecordToView(null);
  };

  const handleEditInputChange = (field, value) => {
    // Update the corresponding field in the editedRecord
    setEditedRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const renderEditForm = () => {
    return (
      <div>
        <p>Edit Record</p>
        <div>
          <label>Code: </label>
          <Input
            value={editedRecord?.code}
            onChange={(e) => handleEditInputChange('code', e.target.value)}
          />
        </div>
        <div>
          <label> English Name: </label>
          <Input
            value={editedRecord?.englishname}
            onChange={(e) => handleEditInputChange('englishname', e.target.value)}
          />
        </div>
        <div>
          <label>Arabic Name: </label>
          <Input
            value={editedRecord?.arabicname}
            onChange={(e) => handleEditInputChange('arabicname', e.target.value)}
          />
        </div>
        <div>
          <label>Barcode: </label>
          <Input
            value={editedRecord?.barcode}
            onChange={(e) => handleEditInputChange('barcode', e.target.value)}
          />
        </div>
        <div>
          <label>Selling Price: </label>
          <Input
            value={editedRecord?.sellingprice}
            onChange={(e) => handleEditInputChange('sellingprice', e.target.value)}
          />
        </div>
        <div>
          <label>Purchase Price: </label>
          <Input
            value={editedRecord?.purchaseprice}
            onChange={(e) => handleEditInputChange('purchaseprice', e.target.value)}
          />
        </div>
        <div>
          <label> Sales Account: </label>
          <Input
            value={editedRecord?.salesaccount}
            onChange={(e) => handleEditInputChange('salesaccount', e.target.value)}
          />
        </div>
        <div>
          <label>Purchase Account: </label>
          <Input
            value={editedRecord?.purchaseaccount}
            onChange={(e) => handleEditInputChange('purchaseaccount', e.target.value)}
          />
        </div>
        <div>
          <label>Specification: </label>
          <Input
            value={editedRecord?.specification}
            onChange={(e) => handleEditInputChange('specification', e.target.value)}
          />
        </div>
        <div>
          <label>Model: </label>
          <Input
            value={editedRecord?.model}
            onChange={(e) => handleEditInputChange('model', e.target.value)}
          />
        </div>
        <div>
          <label>Supplier: </label>
          <Input
            value={editedRecord?.supplier}
            onChange={(e) => handleEditInputChange('supplier', e.target.value)}
          />
        </div>
        <div>
          <label>Description: </label>
          <Input
            value={editedRecord?.descrip}
            onChange={(e) => handleEditInputChange('descrip', e.target.value)}
          />
        </div>
      </div>
    );
  };
  const [InvoiceHostedUrl, setHostedUrl] = useState('');
  console.log("hostes is "+InvoiceHostedUrl);

const fetchTwilioConfigData = async () => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    const userId = user.id;

    const { data: siteSettingsData, error: siteSettingsError } = await supabase
      .from('Site_Settings')
      .select('Invoice_Hosted_URL')
      .eq('user_id', userId)
      .order('id', { ascending: false })
      .limit(1);

    if (siteSettingsError) {
      console.error('Error fetching Site Settings data:', siteSettingsError.message);
    } else {
      // Update the form fields with Site Settings data
      const InvoiceHostedUrl = siteSettingsData[0]?.Invoice_Hosted_URL || '';
      console.log('bsdk',InvoiceHostedUrl);
      setHostedUrl(InvoiceHostedUrl);
    }
  } catch (error) {
    console.error('Error in fetchTwilioConfigData:', error.message);
  }
};

useEffect(async () => {
  // Fetch Site Settings data when the component mounts
 await fetchTwilioConfigData();
}, []);
  const columns = [
    {
       
      title: 'Image',
      key: 'image',
      render: (record) => (
        InvoiceHostedUrl ? (
          <img
            src={InvoiceHostedUrl}
            alt=""
            style={{ width: '25px', height: '25px', marginBottom: '0px' }}
          />
        ) : (
          <img
            src={logo}
            alt=""
            style={{ width: '25px', height: '25px', marginBottom: '0px' }}
          />
        )
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: '30%',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'English Name',
      dataIndex: 'englishname',
      key: 'englishname',
      width: '30%',
      ...getColumnSearchProps('englishname'),
    },
    {
      title: 'Arabic Name',
      dataIndex: 'arabicname',
      key: 'arabicname',
      width: '30%',
      ...getColumnSearchProps('arabicname'),
    },
    {
      title: 'Bar code',
      dataIndex: 'barcode',
      key: 'barcode',
      width: '30%',
      ...getColumnSearchProps('barcode'),
    },
    {
      title: 'Selling Price',
      dataIndex: 'sellingprice',
      key: 'sellingprice',
      width: '30%',
      ...getColumnSearchProps('sellingprice'),
    },
    {
      title: 'Purchase Price',
      dataIndex: 'purchaseprice',
      key: 'purchaseprice',
      width: '30%',
      ...getColumnSearchProps('purchaseprice'),
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      key: 'vat',
      width: '30%',
      ...getColumnSearchProps('vat'),
    },
    {
        title: 'Sales Account',
        dataIndex: 'salesaccount',
        key: 'salesaccount',
        width: '30%',
        ...getColumnSearchProps('salesaccount'),
      },
      {
        title: 'Purchase Account',
        dataIndex: 'purchaseaccount',
        key: 'purchaseaccount',
        width: '30%',
        ...getColumnSearchProps('purchaseaccount'),
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        key: 'unit',
        width: '30%',
        ...getColumnSearchProps('unit'),
      },

      {
        title: 'Specification',
        dataIndex: 'specification',
        key: 'specification',
        width: '30%',
        ...getColumnSearchProps('specification'),
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        width: '30%',
        ...getColumnSearchProps('model'),
      },
      {
        title: 'AMC Company',
        dataIndex: 'amcname',
        key: 'amcname',
        width: '30%',
        ...getColumnSearchProps('amcname'),
      },
      {
        title: 'Supplier Name',
        dataIndex: 'supplier',
        key: 'supplier',
        width: '30%',
        ...getColumnSearchProps('supplier'),
      },
      {
        title: 'Depriciation Year',
        dataIndex: 'depriciation',
        key: 'depriciation',
        width: '30%',
        ...getColumnSearchProps('depriciation'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '30%',
        ...getColumnSearchProps('description'),
      },
      {
        title: 'Waranty Date',
        dataIndex: 'warranty',
        key: 'warranty',
        width: '30%',
        ...getColumnSearchProps('warranty'),
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        width: '30%',
        ...getColumnSearchProps('country'),
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: (_, record) => (
          <>
            {/* <EyeOutlined
              onClick={() => handleView(record)}
              style={{ color: 'blue', marginRight: 8 }}
            /> */}
            <EditOutlined
              onClick={() => handleEdit(record)}
              style={{ color: 'blue', marginRight: 8 }}
            />
            <DeleteOutlined
              onClick={() => handleDelete(record)}
              style={{ color: 'blue' }}
            />
          </>
        ),
      },
   
  
  ];

  const handleExportToPdf = () => {
    const table = document.getElementById('table-to-export');
    
    html2pdf(table);
  };

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  };

  return (
    <Spin spinning={submitting}>
    <>
        <Form name="your_form" form={form} onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="code">
            <Input placeholder="Code" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="englishname">
            <Input placeholder="English Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="arabicname">
            <Input placeholder="Arabic Name" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="barcode">
            <Input placeholder="Barcode" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="sellingprice">
            <Input placeholder="Selling Price" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="purchaseprice">
            <Input placeholder="Purchase Price" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="vat">
            <Select placeholder="VAT">
                  {
                    vats.map((vat) => (
                      <Option value={vat.id}>{vat.name}</Option>
                    ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="salesaccount">
            <Input placeholder="Sales Account" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="purchaseaccount">
            <Input placeholder="Purchase Account" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="unit">
            <Select placeholder="Unit">
                  {
                    units.map((unit) => (
                      <Option value={unit.id}>{unit.unit_name}</Option>
                    ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="specification">
            <Input placeholder="Specification" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="model">
            <Input placeholder="Model" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
      <Col span={8}>
          <Form.Item name="amcname">
            <Input placeholder="AMC Company" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="supplier">
            <Input placeholder="Supplier Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="depriciation">
            <Input placeholder="Depreciation Year" />
          </Form.Item>
        </Col>
        </Row>
        <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="description">
            <Input placeholder="Description" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="warranty">
            <DatePicker placeholder="Warranty Date" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="country">
            <Select placeholder="Country">
                  {
                    countries.map((country) => (
                      <Option value={country.id}>{country.country_name}</Option>
                    ))
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
        <Form.Item
  name="photo"
  valuePropName="fileList"
  getValueFromEvent={(e) =>
    e && e.fileList ? e.fileList : []
  }
>
  <Dragger
    name="photo"
    multiple={false}
    style={{ width: '30%' }}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Choose File </p>
  </Dragger>
</Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
        <Button
  type="primary"
  htmlType="submit"
  style={{ background: 'white', color: 'blue' }}
  icon={submitting ? <Spin /> : undefined}
>
  Submit
</Button>
        </Col>
      </Row>

    </Form>
    <br></br>
    <div>
        <Button type="primary" onClick={handleExportToPdf} style={{ background: 'white', color: 'blue', marginRight: '8px' }}>
          <FilePdfOutlined /> Export to PDF
        </Button>
        <Button type="primary" onClick={handleExportToExcel} style={{ background: 'white', color: 'blue' }}>
          <FileExcelOutlined /> Export to Excel
        </Button>
        
      </div>
    <br></br>
    <div style={{ overflowX: 'auto' }} className="table-container">
    <Table id='table-to-export' columns={columns} dataSource={data} />
    <Modal
      title={`Delete Record - ${recordToDelete ? recordToDelete.code : ''}`}
      visible={deleteModalVisible}
      onOk={handleDeleteConfirm}
      onCancel={handleDeleteCancel}
    >
      <p>Are you sure you want to delete this record?</p>
    </Modal>
    <Modal
      title={`Edit Record - ${recordToEdit ? recordToEdit.code : ''}`}
      visible={editModalVisible}
      onOk={handleEditConfirm}
      onCancel={handleEditCancel}
    >
      {renderEditForm()}
    </Modal>
    <Modal
      title={`View Record - ${recordToView ? recordToView.code : ''}`}
      visible={viewModalVisible}
      onOk={handleViewConfirm}
      onCancel={handleViewCancel}
    >
      {/* ... (existing code) */}
    </Modal>
    
  </div>
  </>
  </Spin>
  );
};

export default YourFormComponent;
